import React, { useCallback, useEffect, useMemo, useState } from "react";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import ProTextInput from "../../../Components/Inputs/TextField";
import { useForm } from "react-hook-form";
import ProSelectField from "../../../Components/Inputs/SelectField";
import MobileField from "../../../Components/Inputs/MobileField";
import EmailField from "../../../Components/Inputs/EmailField";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import ProGrid from "../../../Components/ProGrid/v2";
import { deleteApi, postApi } from "../../../../utils/services";
import {
  ADD_PARTNERS_CONTACT,
  DELETE_PARTNERS_CONTACT,
  DYNAMIC_DROPDOWN,
  GET_ALL_CONTACT_TYPES,
  GET_ALL_PARTNERS_CONTACTS,
  GET_CLIENT_SUBSOURCE,
  GET_PARTNER_CONTACT,
  UPDATE_PARTNERS_CONTACTS
} from "../../../../utils/services/apiPath";
import { useParams } from "react-router";
import { changeMode, fieldTruePermission, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { defaultDropdownConfig } from "../../../../utils/dropdownHelper";
import { partnersContactPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import usePageState from "../../../../utils/customHooks/usePageState";
import usePermission from "../../../../utils/customHooks/usePermission";
import useColumn from "../../../../utils/customHooks/useColumns";
import { STAUTES } from "../../../../utils/constant";
import FormSectionWrapper from "../../../Components/ProForms/FormSectionWrapper";
import PartnerContactDocument from "../../../Components/Partner/PartnerContactDocument";
import { Grid } from "@mui/material";
import PartnerContactNotes from "../../../Components/Partner/PartnerContactNotes";
import { partnerContactFormConfing } from "../PartnerData";

const requiredFormNames = ["Add Contact", "Contact List", "Partner Contact Documents", "Partner Contact Notes"];

export default function PartnerContacts() {
  let { id } = useParams();
  const [showGrid, setShowGrid] = useState(true);
  const [actionData, setActionData] = useState({});
  const [contactTypesData, setContactTypesData] = useState([]);
  const [sourceTypeData, setSourceTypeData] = useState([]);
  const [subSourceTypeData, setSubSourceTypeData] = useState([]);
  const [partnerContactData, setPartnerContactData] = useState({});
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(partnerContactFormConfing));
  const [globalEditMode, setGlobalEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const {
    currentPage,
    pageSize,
    data: contactsData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const {
    "Add Contact": addContactPermission,
    "Contact List": contactListPermission,
    "Partner Contact Documents": partnerContactDocumentPermission,
    "Partner Contact Notes": partnerContactNotesPermission
  } = usePermission(partnersContactPermissionSelector, requiredFormNames);

  const columns = useColumn(
    [
      {
        field: "fullName",
        headerName: "Name",
        renderCell: params => <NameCell params={params} onClick={onContactClick} />
      },
      {
        field: "jobTitle",
        headerName: "Job Title"
      },

      {
        field: "contactType",
        headerName: "Contact Type"
      },

      {
        field: "cellPhone",
        headerName: "Cell Phone"
      },
      {
        field: "officePhone",
        headerName: "Office Phone"
      },
      {
        field: "email",
        headerName: "Email"
      },
      {
        field: "clientContactId",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell title={"Delete Partners Contacts"} params={params} onDelete={onDelete} />
      }
    ],
    contactListPermission?.canDelete
  );
  const { handleSubmit, control, setValue, watch } = useForm({
    defaultValues: prepareDefaultValues(partnerContactFormConfing)
  });
  const { clientContactSourceTypeId } = watch();
  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);

  const handleReadValueclick = name => {
    setFormConfig(prev => ({ ...prev, [name]: { ...prev[name], mode: "edit" } }));
  };

  const defaultFormProps = { control, formValues: partnerContactData, handleReadValueclick };

  const onEdit = () => {
    setGlobalEditMode(true);
    setFormConfig(changeMode("edit"));
  };

  const resetForm = () => {
    setGlobalEditMode(false);
    setActionData({});
    setShowGrid(true);
    setFormConfig(changeMode("read"));
  };

  const onCancel = () => {
    if (actionData?.clientContactId) {
      setFormConfig(changeMode("read"));
      setShowGrid(false);
    } else {
      resetForm();
      setShowGrid(true);
    }
  };

  const addButtonAction = () => {
    Object.keys(formConfig).forEach(field => setValue(field, null));
    onEdit();
    setShowGrid(false);
  };

  const onContactClick = params => {
    setShowGrid(false);
    setActionData(params.row);
    getPartnerContactData(params.row);
  };

  const onSave = async data => {
    if (isSaving) return;
    setIsSaving(true);
    let payload = {
      clientContactId: actionData.clientContactId ?? null,
      clientId: id,
      officePhone: data?.officeNumber,
      isOtherSubSource: clientContactSourceTypeId == "00000000-0000-0000-0000-000000000000",
      ...data
    };
    const { error, data: partnerContact } = await postApi(actionData.clientContactId ? UPDATE_PARTNERS_CONTACTS : ADD_PARTNERS_CONTACT, payload);
    setIsSaving(false);
    if (error) return errorToast(error);
    getAllContacts();
    getPartnerContactData();
    getPartnerContactById(partnerContact?.guidId || actionData?.clientContactId);
    successToast(`Partners Contacts  ${actionData.clientContactId ? "updated" : "added"} successfully.`);
  };

  const getPartnerContactById = async id => {
    setFormConfig(changeMode("read"));
    const payload = {
      clientContactId: id
    };
    const { error, data: clientContactId } = await postApi(GET_PARTNER_CONTACT, payload);
    if (error) return errorToast(error);
    const data = {
      contactTypeId: clientContactId?.contactTypeId,
      firstName: clientContactId?.firstName,
      middleName: clientContactId?.middleName,
      lastName: clientContactId?.lastName,
      email: clientContactId?.email,
      cellphone: clientContactId?.cellPhone,
      officeNumber: clientContactId?.officePhone,
      jobTitle: clientContactId?.jobTitle,
      clientContactId: clientContactId?.clientContactId,
      clientContactSourceTypeId: clientContactId?.clientContactSourceTypeId,
      clientContactSubSourceTypeId: clientContactId?.clientContactSubSourceTypeId,
      otherSubSource: clientContactId?.otherSubSource
    };
    setActionData(clientContactId);
    setPartnerContactData(data);
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  };

  const onDelete = async params => {
    const { error } = await deleteApi(DELETE_PARTNERS_CONTACT, { data: [{ clientContactId: params }] });
    if (error) return errorToast(error);
    getAllContacts();
    successToast("Partners Contacts deleted successfully.");
  };

  const getPartnerContactData = async updateData => {
    const data = {
      contactTypeId: updateData?.contactTypeId,
      firstName: updateData?.firstName,
      middleName: updateData?.middleName,
      lastName: updateData?.lastName,
      email: updateData?.email,
      cellphone: updateData?.cellPhone,
      officeNumber: updateData?.officePhone,
      jobTitle: updateData?.jobTitle,
      clientContactId: updateData?.clientContactId,
      clientContactSourceTypeId: updateData?.clientContactSourceTypeId,
      clientContactSubSourceTypeId: updateData?.clientContactSubSourceTypeId,
      otherSubSource: updateData?.otherSubSource
    };
    setPartnerContactData(data);
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  };

  const getAllContacts = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "fullName",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_PARTNERS_CONTACTS, payload, { params: { clientId: id } });
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const getAllContactType = async () => {
    const { data } = await postApi(GET_ALL_CONTACT_TYPES, defaultDropdownConfig);
    setContactTypesData(data?.map(item => ({ label: item.contactType, value: item.contactTypeId })));
  };

  const getAllSourceType = async () => {
    const payload = ["CLIENTSOURCETYPE"];
    const data = await postApi(DYNAMIC_DROPDOWN, payload);
    setSourceTypeData(data[0]?.typeDropDowns?.map(item => ({ label: item.label, value: item.value })));
  };

  const getAllSubSourceType = async clientContactSourceTypeId => {
    const res = await postApi(`${GET_CLIENT_SUBSOURCE}?sourceId=${clientContactSourceTypeId}`);
    setSubSourceTypeData(res?.map(item => ({ label: item.label, value: item.value })));
  };

  useEffect(() => {
    if (clientContactSourceTypeId) getAllSubSourceType(clientContactSourceTypeId);
  }, [clientContactSourceTypeId]);

  useEffect(() => {
    getAllContacts();
    getAllContactType();
    getAllSourceType();
  }, [getAllContacts]);

  return (
    <TabGridAndFormLayout
      showGrid={showGrid}
      title={`${actionData.clientContactId ? "Edit" : "Add"} Partner Contact`}
      backLabel="Go back to Partner Contacts List"
      backAction={() => resetForm()}
      beingEdited={isEditModeOn}
      onEdit={onEdit}
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      hideEdit={!addContactPermission?.canEdit}
      canView={contactListPermission?.canView}
      isSaving={isSaving}
    >
      {showGrid && (
        <ProGrid
          title=" Partner Contacts"
          columns={columns}
          loading={status === STAUTES.LOADING}
          rows={contactsData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
          addButtonLabel="+ Add Partner Contact"
          addButtonAction={addButtonAction}
          hideAddButton={!contactListPermission?.canAdd}
          hideExport={!contactListPermission?.canExport}
          options={{
            getRowId: row => row.clientContactId,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
        />
      )}
      {!showGrid && (
        <>
          <ProTextInput {...defaultFormProps} {...formConfig.firstName} permission={addContactPermission.fieldPerObj[formConfig.firstName.perKey]} />
          <ProTextInput
            {...defaultFormProps}
            {...formConfig.middleName}
            permission={addContactPermission.fieldPerObj[formConfig.middleName.perKey]}
          />
          <ProTextInput {...defaultFormProps} {...formConfig.lastName} permission={addContactPermission.fieldPerObj[formConfig.lastName.perKey]} />
          <MobileField
            {...defaultFormProps}
            {...formConfig.officeNumber}
            permission={addContactPermission.fieldPerObj[formConfig.officeNumber.perKey]}
          />
          <MobileField {...defaultFormProps} {...formConfig.cellphone} permission={addContactPermission.fieldPerObj[formConfig.cellphone.perKey]} />
          <EmailField {...defaultFormProps} {...formConfig.email} permission={addContactPermission.fieldPerObj[formConfig.email.perKey]} />
          <ProSelectField
            {...defaultFormProps}
            {...formConfig.contactTypeId}
            options={contactTypesData}
            permission={addContactPermission.fieldPerObj[formConfig.contactTypeId.perKey]}
          />
          <ProTextInput {...defaultFormProps} {...formConfig.jobTitle} permission={addContactPermission.fieldPerObj[formConfig.jobTitle.perKey]} />
          <ProSelectField {...defaultFormProps} {...formConfig.clientContactSourceTypeId} options={sourceTypeData} permission={fieldTruePermission} />
          {clientContactSourceTypeId == "00000000-0000-0000-0000-000000000000" ? (
            <ProTextInput {...defaultFormProps} {...formConfig.otherSubSource} permission={fieldTruePermission} />
          ) : (
            <ProSelectField
              {...defaultFormProps}
              {...formConfig.clientContactSubSourceTypeId}
              options={subSourceTypeData}
              permission={fieldTruePermission}
            />
          )}

          {!!actionData.clientContactId && (
            <>
              {partnerContactDocumentPermission.canView && (
                <Grid mt="1.8rem" xs={12} item>
                  <FormSectionWrapper collapsible defaultClose title={"Partner Contact: Documents"}>
                    <PartnerContactDocument globalEditMode={globalEditMode} clientContactId={actionData.clientContactId} clientId={id} />
                  </FormSectionWrapper>
                </Grid>
              )}
              {partnerContactNotesPermission.canView && (
                <Grid xs={12} item>
                  <FormSectionWrapper collapsible defaultClose title={"Partner Contact: Notes"}>
                    <PartnerContactNotes globalEditMode={globalEditMode} clientContactId={actionData.clientContactId} clientId={id} />
                  </FormSectionWrapper>
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </TabGridAndFormLayout>
  );
}
