import React from "react";
import InputWrapper from "./InputWrapper";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ReadOnlyInput from "./ReadOnlyInput";
import { Controller } from "react-hook-form";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DEFAULT_SIZE } from "../../../utils/constant";
import PropTypes from "prop-types";

export default function TimeField({ size = DEFAULT_SIZE, mode, isAddMode, control, name, label, rules, fieldProps = {}, ...rest }) {
  const { canView = false, canAdd = false, canEdit = false } = rest.permission || {};
  if (!canView || (isAddMode && !canAdd)) return null;

  const editModeAllowed = isAddMode ? canAdd : canEdit;
  if (mode === "read" || (mode === "edit" && !editModeAllowed)) return <ReadOnlyInput size={size} label={label} name={name} type="time" {...rest} />;

  return (
    <InputWrapper size={size}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field: { onChange, onBlur, value } }) => (
            <TimePicker
              slotProps={{
                textField: {
                  InputLabelProps: { shrink: true },
                  fullWidth: true
                }
              }}
              label={label}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              minutesStep={1}
              {...fieldProps}
            />
          )}
        />
      </LocalizationProvider>
    </InputWrapper>
  );
}

TimeField.propTypes = {
  name: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["read", "edit"]).isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  isAddMode: PropTypes.bool,
  fieldProps: PropTypes.object,
  permission: PropTypes.shape({
    canView: PropTypes.bool,
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool
  }),
  size: PropTypes.string
};
