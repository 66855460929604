import React, { useCallback, useEffect, useMemo, useState } from "react";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import ProGrid from "../../../Components/ProGrid/v2";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import { useForm } from "react-hook-form";
import ProTextInput from "../../../Components/Inputs/TextField";
import { deleteApi, postApi } from "../../../../utils/services";
import {
  ADD_PARTNER_LOCATION,
  DELETE_PARTNER_LOCATION,
  GET_ALL_PARTNER_LOCATION_LIST,
  GET_PARTNER_LOCATION,
  UPDATE_PARTNER_LOCATION
} from "../../../../utils/services/apiPath";
import { useParams } from "react-router-dom";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { changeMode, changeModeForField, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import ProMobileField from "../../../Components/Inputs/MobileField";
import ProEmailField from "../../../Components/Inputs/EmailField";
import { partnersLocationPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import usePageState from "../../../../utils/customHooks/usePageState";
import usePartnersPermission from "../../../../utils/customHooks/usePartnersPermission";
import { STAUTES } from "../../../../utils/constant";
import useColumn from "../../../../utils/customHooks/useColumns";
import ZipField from "../../../Components/Inputs/ZipField";
import { PartnerLocationFormConfing } from "../PartnerData";

const requiredFormNames = ["Add Location", "Location List"];

export default function PartnerLocations() {
  const { id } = useParams();
  const [showGrid, setShowGrid] = useState(true);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(PartnerLocationFormConfing));
  const [partnerLocationData, setPartnerLocationData] = useState({});
  const [actionData, setActionData] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const {
    currentPage,
    pageSize,
    data: locationData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const { "Add Location": addLocationPermission, "Location List": locationListPermission } = usePartnersPermission(
    partnersLocationPermissionSelector,
    requiredFormNames
  );

  const columns = useColumn(
    [
      {
        field: "title",
        headerName: "Location Name",
        renderCell: params => <NameCell params={params} onClick={onLocationClick} />
      },
      {
        field: "email",
        headerName: "Email"
      },
      {
        field: "contactNumber",
        headerName: "Contact Number"
      },
      {
        field: "address1",
        headerName: "Address1"
      },
      {
        field: "city",
        headerName: "City"
      },
      {
        field: "clientLocationId",
        headerName: "Actions",
        width: 80,
        isDeleteColumn: true,
        disableExport: true,
        renderCell: params => <DeleteCell title={"Delete Partner Location"} params={params} onDelete={onDelete} />
      }
    ],
    locationListPermission?.canDelete
  );

  const { handleSubmit, control, setValue, clearErrors } = useForm({ defaultValues: prepareDefaultValues(PartnerLocationFormConfing) });
  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);

  const onEdit = () => {
    setFormConfig(changeMode("edit"));
  };

  const handleReadValueclick = name => setFormConfig(changeModeForField(name, "edit"));

  const defaultFormProps = { control, formValues: partnerLocationData, handleReadValueclick };

  const resetForm = () => {
    setActionData({});
    setShowGrid(true);
    setFormConfig(changeMode("read"));
    clearErrors();
  };

  const onCancel = () => {
    if (actionData?.clientLocationId) {
      setFormConfig(changeMode("read"));
    } else {
      resetForm();
    }
  };

  const onLocationClick = params => {
    setShowGrid(false);
    setActionData(params.row);
    getPartnerLocationData(params.row);
  };

  const addButtonAction = () => {
    Object.keys(formConfig).forEach(field => setValue(field, null));
    onEdit();
    setShowGrid(false);
  };

  const getPartnerLocationData = async updateData => {
    const data = {
      ...updateData,
      locationName: updateData?.title
    };
    setPartnerLocationData(data);
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  };

  const onSave = async data => {
    if (isSaving) return;
    setIsSaving(true);
    let payload = {
      clientId: id,
      title: data?.locationName,
      ...data,
      clientLocationId: actionData?.clientLocationId ?? null
    };
    const { error, data: partnerLocation } = await postApi(actionData?.clientLocationId ? UPDATE_PARTNER_LOCATION : ADD_PARTNER_LOCATION, payload);
    setIsSaving(false);
    if (error) return errorToast(error);
    getPartnerLocationList();
    getPartnerLocationById(partnerLocation?.guidId || actionData?.clientLocationId);
    setShowGrid(false);
    successToast(`Partner Location ${actionData?.clientLocationId ? "updated" : "added"} successfully.`);
  };

  const getPartnerLocationById = async id => {
    setFormConfig(changeMode("read"));
    const payload = {
      clientLocationId: id
    };
    const { error, data: clientLocationId } = await postApi(GET_PARTNER_LOCATION, payload);
    if (error) return errorToast(error);
    const data = {
      ...clientLocationId,
      locationName: clientLocationId?.title
    };
    setActionData(clientLocationId);
    setPartnerLocationData(data);
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  };

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_PARTNER_LOCATION, { data: [{ clientLocationId: id }] });
    if (error) return errorToast(error);
    getPartnerLocationList();
    setShowGrid(true);
    successToast("Partner Location deleted successfully.");
  };

  const getPartnerLocationList = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "title",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_PARTNER_LOCATION_LIST, payload, { params: { clientId: id } });
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    getPartnerLocationList();
  }, [getPartnerLocationList]);

  return (
    <TabGridAndFormLayout
      showGrid={showGrid}
      title={`${actionData?.clientLocationId ? "Edit" : "Add"} Partner Location`}
      backLabel="Go back to Partner Locations List"
      backAction={() => resetForm()}
      beingEdited={isEditModeOn}
      onEdit={onEdit}
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      hideEdit={!addLocationPermission?.canEdit}
      canView={locationListPermission?.canView}
      isSaving={isSaving}
    >
      {showGrid && (
        <ProGrid
          title="Partner Locations"
          columns={columns}
          loading={status === STAUTES.LOADING}
          rows={locationData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
          addButtonLabel="+ Add Partner Location"
          addButtonAction={addButtonAction}
          hideAddButton={!locationListPermission?.canAdd}
          hideExport={!locationListPermission?.canExport}
          options={{
            getRowId: row => row?.clientLocationId,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
        />
      )}
      {!showGrid && (
        <>
          <ProTextInput
            {...defaultFormProps}
            {...formConfig.locationName}
            rules={{ required: "Location Name Type is required!" }}
            permission={addLocationPermission.fieldPerObj[formConfig.locationName.perKey]}
          />
          <ProEmailField
            {...defaultFormProps}
            {...formConfig.email}
            rules={{ required: "Email is required!" }}
            permission={addLocationPermission.fieldPerObj[formConfig.email.perKey]}
          />
          <ProMobileField
            {...defaultFormProps}
            {...formConfig.contactNumber}
            rules={{
              required: "Contact Number is required!"
            }}
            permission={addLocationPermission.fieldPerObj[formConfig.contactNumber.perKey]}
          />
          <ProTextInput
            {...defaultFormProps}
            {...formConfig.address1}
            rules={{ required: "Address1 is required!" }}
            permission={addLocationPermission.fieldPerObj[formConfig.address1.perKey]}
          />

          <ProTextInput {...defaultFormProps} {...formConfig.address2} permisssion={addLocationPermission.fieldPerObj[formConfig.address2.perKey]} />
          <ProTextInput
            {...defaultFormProps}
            {...formConfig.city}
            rules={{ required: "City is required!" }}
            permission={addLocationPermission.fieldPerObj[formConfig.city.perKey]}
          />
          <ProTextInput
            {...defaultFormProps}
            {...formConfig.state}
            rules={{ required: "State is required!" }}
            permission={addLocationPermission.fieldPerObj[formConfig.state.perKey]}
          />
          <ZipField {...defaultFormProps} {...formConfig.zip} permission={addLocationPermission.fieldPerObj[formConfig.zip.perKey]} />
        </>
      )}
    </TabGridAndFormLayout>
  );
}
