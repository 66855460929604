import React from "react";
import PropTypes from "prop-types";

const ProjectsIcon = ({ color = "none" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5997 1.1C8.5381 1.1 9.29975 1.86165 9.29975 2.80005V7.5997C9.29975 8.5381 8.5381 9.29975 7.5997 9.29975H2.80005C1.86165 9.29975 1.1 8.5381 1.1 7.5997V2.80005C1.1 1.86165 1.86165 1.1 2.80005 1.1H7.5997ZM8.3002 7.5997V2.80005C8.3002 2.41361 7.98614 2.09955 7.5997 2.09955H2.80005C2.41361 2.09955 2.09955 2.41361 2.09955 2.80005V7.5997C2.09955 7.98614 2.41361 8.3002 2.80005 8.3002H7.5997C7.98614 8.3002 8.3002 7.98614 8.3002 7.5997Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
      <path
        d="M7.5997 10.7006C8.5381 10.7006 9.29975 11.4622 9.29975 12.4006V17.2003C9.29975 18.1387 8.5381 18.9003 7.5997 18.9003H2.80005C1.86165 18.9003 1.1 18.1387 1.1 17.2003V12.4006C1.1 11.4622 1.86165 10.7006 2.80005 10.7006H7.5997ZM8.3002 17.2003V12.4006C8.3002 12.0142 7.98614 11.7001 7.5997 11.7001H2.80005C2.41361 11.7001 2.09955 12.0142 2.09955 12.4006V17.2003C2.09955 17.5867 2.41361 17.9008 2.80005 17.9008H7.5997C7.98614 17.9008 8.3002 17.5867 8.3002 17.2003Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
      <path
        d="M17.1998 1.1C18.1382 1.1 18.8998 1.86165 18.8998 2.80005V7.5997C18.8998 8.5381 18.1382 9.29975 17.1998 9.29975H12.4001C11.4617 9.29975 10.7001 8.5381 10.7001 7.5997V2.80005C10.7001 1.86165 11.4617 1.1 12.4001 1.1H17.1998ZM17.9003 7.5997V2.80005C17.9003 2.41361 17.5862 2.09955 17.1998 2.09955H12.4001C12.0137 2.09955 11.6997 2.41361 11.6997 2.80005V7.5997C11.6997 7.98614 12.0137 8.3002 12.4001 8.3002H17.1998C17.5862 8.3002 17.9003 7.98614 17.9003 7.5997Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
      <path
        d="M17.1998 10.7006C18.1382 10.7006 18.8998 11.4622 18.8998 12.4006V17.2003C18.8998 18.1387 18.1382 18.9003 17.1998 18.9003H12.4001C11.4617 18.9003 10.7001 18.1387 10.7001 17.2003V12.4006C10.7001 11.4622 11.4617 10.7006 12.4001 10.7006H17.1998ZM17.9003 17.2003V12.4006C17.9003 12.0142 17.5862 11.7001 17.1998 11.7001H12.4001C12.0137 11.7001 11.6997 12.0142 11.6997 12.4006V17.2003C11.6997 17.5867 12.0137 17.9008 12.4001 17.9008H17.1998C17.5862 17.9008 17.9003 17.5867 17.9003 17.2003Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
    </svg>
  );
};

ProjectsIcon.propTypes = {
  color: PropTypes.string,
};

export default ProjectsIcon;
