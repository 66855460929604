import React from "react";
import PropTypes from "prop-types";

const DashboardIcon = ({ color = "#00050B" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="8" width="4" height="10" rx="2" fill={color} />
      <rect x="8" y="5" width="4" height="13" rx="2" fill={color} />
      <rect x="14" y="2" width="4" height="16" rx="2" fill={color} />
    </svg>
  );
};

DashboardIcon.propTypes = {
  color: PropTypes.string,
};

export default DashboardIcon;
