import React, { useCallback, useEffect, useMemo, useState } from "react";
import Layout from "../../../Components/Layout";
import { useNavigate, useParams } from "react-router-dom";
import ProTextInput from "../../../Components/Inputs/TextField";
import { useForm } from "react-hook-form";
import { Grid, Typography } from "@mui/material";
import SettingFormsLayout from "../../../Components/ProForms/ProFormsLayout/SettingFormsLayout";
import {
  addUserFormName,
  changeMode,
  changeModeForField,
  checkEditEnabled,
  convertFileToBase64,
  prepareDefaultValues,
  prepareInitialConfig
} from "../../../../utils/formHelper";
import { ADD_USER, GET_USER, GET_USER_ROLE_DROPDOWN, UPDATE_USER } from "../../../../utils/services/apiPath";
import { getApi, postApi } from "../../../../utils/services";
import { STAUTES } from "../../../../utils/constant";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import EmailField from "../../../Components/Inputs/EmailField";
import MobileField from "../../../Components/Inputs/MobileField";
import ProSelectField from "../../../Components/Inputs/SelectField";
import { R_SETTINGS, R_USERS } from "../../../../utils/permissionReferenceConfig";
import { subModPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import usePermission from "../../../../utils/customHooks/usePermission";
import FileUploadField from "../../../Components/Inputs/FileUploadField";
import { useSelector } from "react-redux";
import { userSelector } from "../../../store/features/auth/authSlice";

export const userFormConfig = {
  firstName: {
    label: "First Name",
    rules: { required: "First Name is required!" },
    perKey: "First Name"
  },
  lastName: {
    label: "Last Name",
    rules: { required: "Last Name is required!" },
    perKey: "Last Name"
  },
  userName: {
    label: "User Name",
    rules: { required: "User Name is required!" },
    perKey: "User Name"
  },
  phone: {
    label: "Phone Number",
    rules: { required: "Phone Number is required!" },
    perKey: "Phone Number"
  },
  email: {
    label: "Email",
    rules: { required: "Email is required!" },
    perKey: "Email"
  },
  roleTypeGuid: {
    label: "User Role",
    rules: { required: "User Role is required!" },
    perKey: "Select Role"
  },
  profilePictureFile: {
    label: "Profile Picture",
    perKey: "Profile Picture",
    defaultValue: null,
    readKey: "profilePicture"
  }
};

export default function UsersForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const isAddMode = useMemo(() => id === "add", [id]);
  const { clientGuid } = useSelector(userSelector);
  const { [addUserFormName]: formPermission } = usePermission(subModPermissionSelector(R_SETTINGS, R_USERS), addUserFormName);
  const { canView, canEdit, canAdd, fieldPerObj } = formPermission;
  const [status, setStatus] = useState(STAUTES.IDLE);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(userFormConfig, isAddMode ? "edit" : "read"));
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(userFormConfig) });
  const [userData, setUserData] = useState({});
  const [userRoles, setUserRoles] = useState([]);
  const [isVerified, setIsVerified] = useState();

  const isEditModeOn = useMemo(() => checkEditEnabled(isAddMode, formConfig), [isAddMode, formConfig]);

  const backAction = () => navigate("/settings/users");

  const getDetailById = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const { data, error } = await getApi(GET_USER, { params: { clientUserGuid: id } });
    setIsVerified(data?.isVerified);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    const { value: roleTypeGuid, label: roleTypeName } = data.roleTypeDropDownModel || {};
    const userInfo = { ...data, roleTypeGuid, roleTypeName };
    setUserData(userInfo);
    Object.keys(userFormConfig).forEach(field => setValue(field, userInfo[field]));
  }, [setValue, id]);

  const onEdit = () => {
    setFormConfig(changeMode("edit"));
  };

  const onCancel = () => {
    if (isAddMode) backAction();
    else setFormConfig(changeMode());
  };

  const handleReadValueclick = name => setFormConfig(changeModeForField(name, "edit"));

  const onSubmit = async formInfo => {
    try {
      const base64ProfilePicture = await convertFileToBase64(formInfo.profilePictureFile);
      const payload = {
        ...formInfo,
        profilePicture: base64ProfilePicture,
        fileName: formInfo.profilePictureFile?.name || null,
        clientGuid,
        id: isAddMode ? undefined : id
      };
      setStatus(STAUTES.LOADING);
      const { error } = await postApi(isAddMode ? ADD_USER : UPDATE_USER, payload);
      setStatus(STAUTES.IDLE);
      if (error) return errorToast(error);
      successToast(`User ${isAddMode ? "added" : "updated"} successfully.`);
      backAction();
    } catch (error) {
      errorToast(error);
    }
  };

  const getUserRole = async () => {
    const { data, error } = await getApi(GET_USER_ROLE_DROPDOWN);
    if (error) return errorToast(error);
    setUserRoles(data);
  };

  useEffect(() => {
    if (!isAddMode) getDetailById();
  }, [isAddMode, getDetailById]);

  useEffect(() => {
    getUserRole();
  }, []);

  const defaultFormProps = { control, formValues: userData, isAddMode, handleReadValueclick };

  const filterRoles = useMemo(
    () => userRoles.map(role => ({ ...role, disabled: !(role.isPermissionAssigned || role.value === userData.roleTypeGuid) })),
    [userRoles, userData]
  );

  return (
    <Layout status={status} canView={canView && !(isAddMode && !canAdd)}>
      <SettingFormsLayout
        backAction={backAction}
        title={`${isAddMode ? "Add" : "Edit"} User`}
        backLabel="Go Back to All User"
        beingEdited={isEditModeOn}
        hideEdit={!canEdit}
        onEdit={onEdit}
        onCancel={onCancel}
        onSave={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <ProTextInput {...defaultFormProps} {...formConfig.firstName} permission={fieldPerObj[userFormConfig.firstName.perKey]} />
          <ProTextInput {...defaultFormProps} {...formConfig.lastName} permission={fieldPerObj[userFormConfig.lastName.perKey]} />
          <ProTextInput {...defaultFormProps} {...formConfig.userName} permission={fieldPerObj[userFormConfig.userName.perKey]} />
          <MobileField {...defaultFormProps} {...formConfig.phone} permission={fieldPerObj[userFormConfig.phone.perKey]} />
          <EmailField {...defaultFormProps} {...formConfig.email} permission={fieldPerObj[userFormConfig.email.perKey]} />
          <ProSelectField
            {...defaultFormProps}
            {...formConfig.roleTypeGuid}
            options={filterRoles}
            permission={fieldPerObj[userFormConfig.roleTypeGuid.perKey]}
          />
          <FileUploadField
            {...defaultFormProps}
            {...formConfig.profilePictureFile}
            inputProps={{ accept: "image/*" }}
            permission={fieldPerObj[userFormConfig.profilePictureFile.perKey]}
          />
          {!isAddMode && (
            <Typography className="pro-verification-test" mt={2} ml={2}>
              {isVerified ? "Email Verified" : "Email Verification Pending"}
            </Typography>
          )}
        </Grid>
      </SettingFormsLayout>
    </Layout>
  );
}
