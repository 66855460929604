import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import ProGrid from "../../../Components/ProGrid/v2";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import ProSelectField from "../../../Components/Inputs/SelectField";
import { useForm } from "react-hook-form";
import { STAUTES } from "../../../../utils/constant";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import usePageState from "../../../../utils/customHooks/usePageState";
import ProTextInput from "../../../Components/Inputs/TextField";
import { prepareDefaultValues, prepareInitialConfig, employeeSocialMediaFormName, changeMode } from "../../../../utils/formHelper";
import {
  GET_ALL_SOCIALMEDIA,
  GET_ALL_SOCIAL_MEDIA_TYPES,
  ADD_EMPLOYEE_SOCIALMEDIA,
  UPDATE_EMPLOYEE_SOCIALMEDIA,
  DELETE_EMPLOYEE_SOCIALMEDIA,
  GET_EMPLOYEE_SOCIALMEDIA
} from "../../../../utils/services/apiPath";
import { deleteApi, postApi } from "../../../../utils/services";
import { employeesSocialMediaPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import usePermission from "../../../../utils/customHooks/usePermission";
import useColumn from "../../../../utils/customHooks/useColumns";
import { EmployeeSocialMediaFormConfing } from "../EmployeeData";

export default function EmployeeSocialMedia() {
  let { id } = useParams();
  const [showGrid, setShowGrid] = useState(true);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(EmployeeSocialMediaFormConfing));
  const [employeeSocialMediaData, setEmployeeSocialMediaData] = useState({});
  const [actionData, setActionData] = useState({});
  const [socialMediaTypes, setSocialMediaTypes] = useState([]);
  const [isAddMode, setIsAddMode] = useState(false);
  const {
    currentPage,
    pageSize,
    data: socialMediaData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(EmployeeSocialMediaFormConfing) });
  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);
  const { "Add Social Media": addSocialMediaPermission, "Social Media List": socialMediaListPermission } = usePermission(
    employeesSocialMediaPermissionSelector,
    employeeSocialMediaFormName
  );
  const [isSaving, setIsSaving] = useState(false);

  const columns = useColumn(
    [
      {
        field: "socialMediaName",
        headerName: "Social Media Type",
        renderCell: params => <NameCell params={params} onClick={onSocialMediaClick} />
      },
      {
        field: "handle",
        headerName: "Handle"
      },
      {
        field: "proScoreEmployeeSocialMediaId",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell params={params} onDelete={onDelete} />
      }
    ],
    socialMediaListPermission.canDelete
  );

  const onEdit = () => {
    setFormConfig(prev => Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: { ...prev[key], mode: "edit" } }), {}));
  };
  const handleReadValueclick = name => {
    setFormConfig(prev => ({ ...prev, [name]: { ...prev[name], mode: "edit" } }));
  };

  const resetForm = () => {
    setActionData({});
    setShowGrid(true);
    setFormConfig(changeMode("read"));
  };

  const onCancel = () => {
    if (actionData?.proScoreEmployeeSocialMediaId) {
      setFormConfig(changeMode("read"));
    } else {
      resetForm();
    }
  };

  const onSocialMediaClick = params => {
    setShowGrid(false);
    setActionData(params.row);
    getEmployeeSocialMediaData(params.row);
  };

  const addButtonAction = () => {
    Object.keys(formConfig).forEach(field => setValue(field, null));
    onEdit();
    setShowGrid(false);
    setIsAddMode(true);
  };

  const onSave = async data => {
    if (isSaving) return;
    setIsSaving(true);
    const payload = {
      socialMediaId: data.socialMediaType,
      handle: data.handle,
      employeeId: id,
      proScoreEmployeeSocialMediaId: actionData?.proScoreEmployeeSocialMediaId ? actionData?.proScoreEmployeeSocialMediaId : null
    };
    const { data: employeeSocailMedia, error } = await postApi(
      actionData?.proScoreEmployeeSocialMediaId ? UPDATE_EMPLOYEE_SOCIALMEDIA : ADD_EMPLOYEE_SOCIALMEDIA,
      payload
    );
    setIsSaving(false);
    if (error) return errorToast(error);
    getAllSocialMedia();
    getSocialMediaById(employeeSocailMedia?.guidId || actionData?.proScoreEmployeeSocialMediaId);
    setShowGrid(false);
    successToast(`Employee Social Media ${!actionData?.proScoreEmployeeSocialMediaId ? "Added" : "Updated"} Successfully`);
  };

  const getSocialMediaById = async id => {
    setFormConfig(changeMode("read"));
    const payload = {
      proScoreEmployeeSocialMediaId: id
    };
    const { error, data: employeeSocailMediaByID } = await postApi(GET_EMPLOYEE_SOCIALMEDIA, payload);
    if (error) return errorToast(error);
    const data = {
      socialMediaType: employeeSocailMediaByID.socialMediaId,
      handle: employeeSocailMediaByID.handle
    };
    setActionData(employeeSocailMediaByID);
    setEmployeeSocialMediaData(data);
    setIsAddMode(false);
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  };

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_EMPLOYEE_SOCIALMEDIA, { data: [{ proScoreEmployeeSocialMediaId: id }] });
    if (error) return errorToast(error);
    getAllSocialMedia();
    setShowGrid(true);
    successToast("Social Media deleted successfully.");
  };

  const getEmployeeSocialMediaData = async updateData => {
    const data = {
      socialMediaType: updateData.socialMediaId,
      handle: updateData.handle
    };
    setEmployeeSocialMediaData(data);
    setIsAddMode(false);
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  };

  const getAllSocialMedia = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "socialMediaName",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_SOCIALMEDIA, payload, { params: { employeeId: id } });
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, setPageState, setStatus]);

  const getAllSocialMediaTypes = async () => {
    const payload = {
      pageIndex: 1,
      pageSize: 100,
      orderByAscending: true,
      searchString: ""
    };
    const socialMediaTypes = await postApi(GET_ALL_SOCIAL_MEDIA_TYPES, payload);
    if (socialMediaTypes?.data) {
      setSocialMediaTypes(
        socialMediaTypes.data.map(item => ({
          label: item.name,
          value: item.socialMediaId
        }))
      );
    } else if (socialMediaTypes?.data === null) {
      setSocialMediaTypes([]);
    }
  };

  useEffect(() => {
    getAllSocialMedia();
    getAllSocialMediaTypes();
  }, [getAllSocialMedia]);

  const defaultFormProps = { control, formValues: employeeSocialMediaData, isAddMode, handleReadValueclick };

  return (
    <TabGridAndFormLayout
      showGrid={showGrid}
      title={"Social Media"}
      backLabel={"Go back to Social Media List"}
      backAction={() => resetForm()}
      beingEdited={isEditModeOn}
      onEdit={onEdit}
      hideEdit={!addSocialMediaPermission.canEdit}
      canView={socialMediaListPermission.canView}
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      isSaving={isSaving}
    >
      {showGrid && (
        <ProGrid
          title="Social Media"
          loading={status === STAUTES.LOADING}
          columns={columns}
          options={{
            getRowId: row => row.proScoreEmployeeSocialMediaId,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
          addButtonLabel="+ Add Social Media"
          hideAddButton={!addSocialMediaPermission.canAdd}
          hideExport={!addSocialMediaPermission.canExport}
          addButtonAction={addButtonAction}
          rows={socialMediaData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
        />
      )}
      {!showGrid && (
        <>
          <ProSelectField
            {...defaultFormProps}
            {...formConfig.socialMediaType}
            options={socialMediaTypes}
            permission={addSocialMediaPermission.fieldPerObj[formConfig.socialMediaType.perKey]}
            rules={{ required: "Social Media Type is required!" }}
          />
          <ProTextInput
            {...defaultFormProps}
            {...formConfig.handle}
            permission={addSocialMediaPermission.fieldPerObj[formConfig.handle.perKey]}
            rules={{ required: "Handle is required!" }}
          />
        </>
      )}
    </TabGridAndFormLayout>
  );
}
