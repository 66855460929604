import React, { useState, useEffect, useCallback } from "react";
import "./ProjectCertifiedPayroll.scss";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Box, FormControl, FormControlLabel, FormLabel, Grid, Pagination, Radio, RadioGroup, TableSortLabel } from "@mui/material";
import DateField from "../../../Components/Inputs/DateField";
import ProFileUploadField from "../../../Components/Inputs/FileUploadField";
import { errorToast } from "../../../../utils/toastHelper";
import { STAUTES } from "../../../../utils/constant";
import usePageState from "../../../../utils/customHooks/usePageState";
import { UPLOAD_EMPLOYEE_PAYROLLREPORT, GET_CERTIFIED_PAYROLL_DETAILS, GET_PROJECT_INFO } from "../../../../utils/services/apiPath";
import { postApi } from "../../../../utils/services";
import { changeMode, convertFileToBase64, fieldTruePermission, formatPayloadDate, prepareInitialConfig } from "../../../../utils/formHelper.js";
import ProButton from "../../../Components/ProButton/index.jsx";
import ReportsLayout from "../../../Components/ProForms/ProFormsLayout/ReportsFormsLayout";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTheme } from "../../../ContextStore/ThemeContext.js";
import DialogModal from "../../../Components/DialogModel/index.js";
import { jwtDecode } from "jwt-decode";
import { subModPermissionSelector } from "../../../store/features/permissions/permissionsSlice.js";
import { R_PROJECTS, R_PROJECTS_CERTIFIED_PAYROLL } from "../../../../utils/permissionReferenceConfig.js";
import usePermission from "../../../../utils/customHooks/usePermission.js";
import ProjectCertifiedPayrollTable from "./ProjectCertifiedPayrollTable.jsx";
import ProjectCertifiedPayrollSpreadsheet from "./ProjectCertifiedPayrollSpreadsheet.jsx";

const CertifiedPayrollConfig = {
  startDate: {
    label: "Week Ending Date",
    perKey: "WeekEndingDate",
    rules: { required: "WeekEndingDate is required!" }
  },
  uploadPDF: {
    label: "Upload PDF",
    perKey: "WeekEndingDate",
    type: "file",
    readKey: "pdfFile",
    rules: { required: "File is required!" }
  }
};

export const ProjectCertifiedPayroll = () => {
  const { id } = useParams();
  const { theme } = useTheme();
  const { handleSubmit, control, setValue, reset } = useForm({
    defaultValues: {
      startDate: null
    }
  });
  const [selectedValue, setSelectedValue] = useState("");
  const [timeSheetValue, setTimeSheetValue] = useState("");
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(CertifiedPayrollConfig));
  const [projectPayrollReportData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [weeklyReportData, setWeeklyReportData] = useState(null);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [pageSize1] = useState(10);
  const [confirmation, setConfirmation] = useState(false);
  const [uploadData, setUploadData] = useState();
  const [certifiedPayrollModal, setCertifiedPayrollModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [apiMessage, setApiMessage] = useState("");
  const [projectInfoData, setProjectInfoData] = useState();
  const [tableSortModel, setTableSortModel] = useState({ orderCol: "", orderDirection: "asc", bool: false });
  const token = localStorage.getItem("accessToken");
  const tokenData = jwtDecode(token);
  const { "Certified Payroll List": projectCertifiedPayrollPermission } = usePermission(
    subModPermissionSelector(R_PROJECTS, R_PROJECTS_CERTIFIED_PAYROLL),
    ["Certified Payroll List"]
  );

  const projectCertifiedPayrollOptions = [
    { label: "TimeSheet", value: 1, permission: projectCertifiedPayrollPermission?.fieldPerObj?.TimeSheet?.canImport },
    { label: "Payroll Summary", value: 2, permission: projectCertifiedPayrollPermission?.fieldPerObj?.Payroll?.canImport },
    { label: "Certified Payroll", value: 3, permission: projectCertifiedPayrollPermission?.fieldPerObj?.CertifiedPayroll?.canImport }
  ];

  const handleRadioChange = value => {
    setSelectedValue(value);
  };

  const handleFileUpload = e => {
    if (e) {
      setSelectedFile(e);
    }
  };

  const defaultFormProps = { control, formValues: projectPayrollReportData };

  useEffect(() => {
    setFormConfig(changeMode("edit"));
  }, []);

  const resetForm = () => {
    setFormConfig(changeMode("edit"));
    reset();
  };

  const handleSingleRowTimesheet = () => {
    setTimeSheetValue("1");
    setIsOpen(true);
  };

  const handleOpenDialogpopup = () => {
    setTimeSheetValue("2");
    setIsOpen(true);
  };

  const handleMultiRowTimesheet = () => {
    setTimeSheetValue("3");
    setIsOpen(true);
  };

  const {
    currentPage,
    pageSize,
    data: dailyTimesheet,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const getProjectProfileData = async () => {
    const payload = {
      projectId: id
    };
    const { error, data: project } = await postApi(GET_PROJECT_INFO, payload);
    if (error) errorToast(error);
    if (!project) return;

    setProjectInfoData(project);
  };

  useEffect(() => {
    getProjectProfileData();
  }, []);

  const onSave = async data => {
    setUploadData(data);
    setConfirmation(true);
  };

  const handleCertifiedPayrollConfirmModal = async () => {
    setConfirmation(false);
    try {
      setStatus(STAUTES.LOADING);
      const base64File = await convertFileToBase64(uploadData?.uploadPDF);
      const payload = {
        file: base64File,
        fileName: selectedFile?.name,
        projectId: id,
        weekEndDate: formatPayloadDate(uploadData?.startDate)
      };
      const res = await postApi(UPLOAD_EMPLOYEE_PAYROLLREPORT, payload);
      if (res?.statusCode === 200) {
        handlePayrollTimeSheet();
        resetForm();
        setValue("startDate", null);
        setApiMessage(res.message);
      } else {
        setApiMessage(res?.error);
        handlePayrollTimeSheet();
      }
    } catch (err) {
      handlePayrollTimeSheet();
      setApiMessage("Payroll Excel Data Failed to Upload");
    } finally {
      setCertifiedPayrollModal(true);
      setStatus(STAUTES.IDLE);
    }
  };

  const handleCancelModal = () => {
    setConfirmation(false);
  };

  const createSortHandler = property => () => {
    if (tableSortModel?.orderCol !== property) {
      setTableSortModel({
        orderCol: property || "",
        orderDirection: "asc",
        bool: true
      });
    } else if (tableSortModel?.orderCol === property) {
      setTableSortModel({
        orderCol: property || "",
        orderDirection: tableSortModel?.bool ? "desc" : "asc",
        bool: !tableSortModel?.bool
      });
    }
  };

  const handlePayrollTimeSheet = useCallback(async () => {
    if (id) {
      setLoading(true);
      setStatus(STAUTES.LOADING);
      const payload = {
        pageIndex: searchString ? 1 : currentPage1,
        pageSize: pageSize1,
        searchString: searchString,
        orderByAscending: tableSortModel.bool || false,
        orderCol: tableSortModel.orderCol || "weekEndingDate",
        ...sortModel
      };
      let { data, totalRecords, error } = await postApi(`${GET_CERTIFIED_PAYROLL_DETAILS}?projectId=${id}`, payload);

      setTotalRecords(totalRecords);
      data = data === null ? [] : data;
      const formattedData = data?.map((item, index) => ({ ...item, id: index + 1 }));
      setWeeklyReportData(formattedData);
      setLoading(false);
      setStatus(STAUTES.IDLE);
      if (error) return errorToast(error);
      setLoading(false);
    }
  }, [currentPage1, tableSortModel]);

  useEffect(() => {
    handlePayrollTimeSheet();
  }, [handlePayrollTimeSheet]);

  const handleSingleRowTimesheetDownload = () => {
    const filePath = "/static/Images/Excel/TimesheetImportTemplate-SingleRow.xlsx";
    window.open(filePath, "_blank");
  };

  const handleMultiRowTimesheetDownload = () => {
    setTimeSheetValue("2");
    const filePath = "/static/Images/Excel/TimesheetImportTemplate-MultiRow.xlsx";
    window.open(filePath, "_blank");
  };

  const handleDownloadPayroll = () => {
    const filePath = "/static/Images/Excel/PayrollSummaryTemplate.xlsx";
    window.open(filePath, "_blank");
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage1(newPage);
  };

  return (
    <ReportsLayout>
      <Box sx={{ pb: 2, maxWidth: "1440px", margin: "1rem auto" }}>
        <Box sx={{ pb: 2 }}>
          <FormControl component="fieldset" className="proCheckbox">
            <FormLabel component="legend" sx={{ color: theme === "dark" ? "#ffffff" : "rgba(0, 0, 0, 0.6)" }}>
              Select the option to import
            </FormLabel>
            <RadioGroup value={selectedValue} onChange={e => handleRadioChange(e.target.value)}>
              {projectCertifiedPayrollOptions
                ?.filter(option => option.permission)
                ?.map(option => (
                  <FormControlLabel
                    sx={{ color: theme === "dark" ? "#ffffff" : "#000000" }}
                    key={option.value}
                    value={String(option.value)}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
            </RadioGroup>
          </FormControl>

          <ProjectCertifiedPayrollSpreadsheet
            id={id}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            timeSheetValue={timeSheetValue}
            setTimeSheetValue={setTimeSheetValue}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
            certifiedPayrollModal={certifiedPayrollModal}
            setCertifiedPayrollModal={setCertifiedPayrollModal}
            handlePayrollTimeSheet={handlePayrollTimeSheet}
          />

          {selectedValue === "1" && (
            <div style={{ display: "flex", gap: "1rem" }}>
              {projectInfoData?.uploadDailyTimeSheet === 2 ? (
                <>
                  <ProButton className="primary pro_button" onClick={handleMultiRowTimesheet}>
                    Weekly Timesheet Summary
                  </ProButton>
                  <ProButton className="primary pro_button" onClick={handleMultiRowTimesheetDownload}>
                    Timesheet Import Template
                  </ProButton>
                </>
              ) : (
                <>
                  <ProButton className="primary pro_button1" onClick={handleSingleRowTimesheet}>
                    Import TimeSheet
                  </ProButton>
                  <ProButton className="primary pro_button" onClick={handleSingleRowTimesheetDownload}>
                    Timesheet Import Template
                  </ProButton>
                </>
              )}
            </div>
          )}
          {selectedValue === "2" && (
            <div style={{ display: "flex", gap: "1rem" }}>
              <ProButton className="primary pro_button1" onClick={handleOpenDialogpopup}>
                Import Payroll Summary
              </ProButton>
              <ProButton className="primary pro_button" onClick={handleDownloadPayroll}>
                Payroll Summary Template
              </ProButton>
            </div>
          )}
          {selectedValue === "3" && (
            <div>
              <Grid sx={{ pt: 1 }} container spacing={2}>
                <DateField {...defaultFormProps} {...formConfig.startDate} permission={fieldTruePermission} />

                <ProFileUploadField
                  {...defaultFormProps}
                  {...formConfig.uploadPDF}
                  inputProps={{ accept: ".pdf" }}
                  fieldProps={{
                    onChange: e => {
                      setValue("uploadPDF", e);
                      handleFileUpload(e);
                    }
                  }}
                  permission={fieldTruePermission}
                />
              </Grid>
              <Grid container mt="1rem">
                <ProButton onClick={handleSubmit(onSave)}>Save</ProButton>
              </Grid>
              <DialogModal
                show={confirmation}
                title="Confirm"
                message={`Please acknowledge that by uploading a Certified Payroll
report, you are acknowledging that ProScore did not generate the Certified Payroll Report for
this pay period and that ProScore is not responsible for the accuracy and completeness of the
contents of the uploaded Certified Payroll Report. As such, any audits, inquiries, or exception
filing for this Certified Payroll Report will be directed back to ${tokenData?.GlobalClientName}`}
                onConfirm={handleCertifiedPayrollConfirmModal}
                onCancel={handleCancelModal}
              />
            </div>
          )}
        </Box>
        {projectCertifiedPayrollPermission?.canView && (
          <div className="certified-payroll">
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead className="certified-payroll_table">
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        direction={tableSortModel.orderDirection}
                        active={tableSortModel?.orderCol === "weekEndingDate"}
                        onClick={createSortHandler("weekEndingDate")}
                      >
                        PPE Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        direction={tableSortModel.orderDirection}
                        active={tableSortModel?.orderCol === "clientName"}
                        onClick={createSortHandler("clientName")}
                      >
                        Partner Name
                      </TableSortLabel>
                    </TableCell>
                    {projectCertifiedPayrollPermission?.fieldPerObj?.TimeSheet?.canView && (
                      <TableCell align="left">
                        <TableSortLabel
                          direction={tableSortModel.orderDirection}
                          active={tableSortModel?.orderCol === "totalHours"}
                          onClick={createSortHandler("totalHours")}
                        >
                          Timesheet
                        </TableSortLabel>
                      </TableCell>
                    )}
                    {projectCertifiedPayrollPermission?.fieldPerObj?.Payroll?.canView && (
                      <TableCell align="left">
                        <TableSortLabel
                          direction={tableSortModel.orderDirection}
                          active={tableSortModel?.orderCol === "totalAmount"}
                          onClick={createSortHandler("totalAmount")}
                        >
                          Payroll Summary
                        </TableSortLabel>
                      </TableCell>
                    )}
                    {projectCertifiedPayrollPermission?.fieldPerObj?.CertifiedPayroll?.canView && (
                      <TableCell align="left">
                        <TableSortLabel
                          direction={tableSortModel.orderDirection}
                          active={tableSortModel?.orderCol === "certfiedPayroll"}
                          onClick={createSortHandler("certfiedPayroll")}
                        >
                          Timesheet Certfied Payroll (WH-347)
                        </TableSortLabel>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ProjectCertifiedPayrollTable
                    id={id}
                    loading={loading}
                    weeklyReportData={weeklyReportData}
                    projectCertifiedPayrollPermission={projectCertifiedPayrollPermission}
                    dailyTimesheet={dailyTimesheet}
                    status={status === STAUTES.LOADING}
                    setStatus={setStatus}
                    STAUTES={STAUTES}
                    pageSize={pageSize}
                    setPagination={setPagination}
                    searchString={searchString}
                    setSearchString={setSearchString}
                    sortModel={sortModel}
                    setSortModel={setSortModel}
                    setPageState={setPageState}
                    rowCount={rowCount}
                    currentPage={currentPage}
                    tokenData={tokenData}
                  />
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              <Pagination
                count={Math.ceil((totalRecords || 0) / pageSize1)}
                page={currentPage1}
                onChange={handleChangePage}
                color="primary"
                size="large"
              />
            </div>
          </div>
        )}
      </Box>
    </ReportsLayout>
  );
};
