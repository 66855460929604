import React from "react";
import PropTypes from "prop-types";
import "./ReportsFormsLayout.scss";

export default function ReportsLayout({ children }) {
  return (
    <div className="proForm-layout proReportForm">
      <div className="proReportForm-contents">{children}</div>
    </div>
  );
}

ReportsLayout.propTypes = {
  children: PropTypes.node.isRequired
};
