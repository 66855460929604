import React, { useCallback, useEffect, useState } from "react";
import { Dialog, Box, Paper, Grid, Typography,Tooltip,IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import ProGrid from "../../../Components/ProGrid/v2";
import { STAUTES } from "../../../../utils/constant";
import usePageState from "../../../../utils/customHooks/usePageState";
import { errorToast } from "../../../../utils/toastHelper";
import { GET_ALL_DOCUMENTS } from "../../../../utils/services/apiPath";
import { postApi } from "../../../../utils/services";
import { employeeDocumentsFormName } from "../../../../utils/formHelper";
import { employeesDocumentsPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import usePermission from "../../../../utils/customHooks/usePermission";
import useColumn from "../../../../utils/customHooks/useColumns";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export default function ESignature() {
  const [sessionId, setSessionId] = useState("");
  const [open, setOpen] = useState(false);
  let { id } = useParams();
  const {
    currentPage,
    pageSize,
    data: documentData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const { [employeeDocumentsFormName]: employees } = usePermission(employeesDocumentsPermissionSelector, employeeDocumentsFormName);

  const columns = useColumn([
    {
      field: "formName",
      headerName: "Form Name"
    },
    {
      field: "createdDate",
      headerName: "Created Date"
    },
    {
      field: "status",
      headerName: "ESigned Status"
    },
    {
      field: "rapidstatus",
      headerName: "Rapid Status"
    },
    {
      field: "firstSignerSharedLink",
      headerName: "Actions",
      renderCell: params => (
        <>
          {params.row.status === "Completed" ? (
            <Tooltip title={"Download"} arrow>
              <IconButton onClick={() => handleSignDocument(params.value)} color="primary">
                <FileDownloadIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : null}
        </>
      )
    }
  ]);
  
  const getAllDocuments = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "formName",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_DOCUMENTS, payload, { params: { employeeId: id } });
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const handleSignDocument = formLink => {
    setSessionId(formLink);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getAllDocuments();
  }, [getAllDocuments]);

  return (
    <TabGridAndFormLayout showGrid={true} canView={employees.canView}>
      <ProGrid
        title="Documents"
        loading={status === STAUTES.LOADING}
        options={{
          getRowId: row => row.documentId,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          sortingMode: "server",
          onSortModelChange: setSortModel
        }}
        columns={columns}
        hideExport={!employees.canExport}
        hideAddButton={true}
        rows={documentData}
        searchMode={!!searchString}
        searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
      />
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        keepMounted
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ py: 3, px: 2 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item alignItems="center">
                <Typography variant="h5" component="h5" gutterBottom textTransform="uppercase">
                  Sign Document
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <div>
            <iframe title="PDF Viewer" width="100%" height="600" src={sessionId}></iframe>
          </div>
        </Box>
      </Dialog>
    </TabGridAndFormLayout>
  );
}
