import React from "react";
import PropTypes from "prop-types";

const RapIcon = ({ color = "none" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.5"
        y="2.5"
        width="15"
        height="15"
        rx="1.68182"
        stroke={color}
      />
      <rect
        x="6.30005"
        y="9.27246"
        width="1"
        height="6.54545"
        rx="0.4"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4819 8.9459C10.4819 8.72498 10.3028 8.5459 10.0819 8.5459H9.88193C9.66102 8.5459 9.48193 8.72498 9.48193 8.9459V9.6459C9.48193 9.86681 9.66102 10.0459 9.88193 10.0459H10.0819C10.3028 10.0459 10.4819 9.86681 10.4819 9.6459V8.9459ZM10.4819 12.5823C10.4819 12.3613 10.3029 12.1823 10.0819 12.1823H9.88194C9.66102 12.1823 9.48194 12.3613 9.48194 12.5823V15.4186C9.48194 15.6395 9.66102 15.8186 9.88194 15.8186H10.0819C10.3029 15.8186 10.4819 15.6395 10.4819 15.4186V12.5823Z"
        fill={color}
      />
      <rect
        x="12.6636"
        y="4.90918"
        width="1"
        height="10.9091"
        rx="0.4"
        fill={color}
      />
    </svg>
  );
};

RapIcon.propTypes = {
  color: PropTypes.string,
};

export default RapIcon;
