import React, { useCallback, useEffect, useMemo } from "react";
import Layout from "../../../Components/Layout";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import ProGrid from "../../../Components/ProGrid/v2";
import { useNavigate, useParams } from "react-router-dom";
import { typeSettingConfig } from "./typeSettingConfig";
import usePageState from "../../../../utils/customHooks/usePageState";
import { deleteApi, postApi } from "../../../../utils/services";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { DETAULT_ROLE, EMPLOYEE_ROLE, STAUTES } from "../../../../utils/constant";
import usePermission from "../../../../utils/customHooks/usePermission";
import { subModPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import { R_SETTINGS } from "../../../../utils/permissionReferenceConfig";

export default function TypeSettingList() {
  const { settingType } = useParams();
  const navigate = useNavigate();

  const { typeKey, typeLabel, idKey, gridKey, getApiPath, deleteApiPath, subModuleRef, listFormName } =  typeSettingConfig[settingType] || {};

  const {
    currentPage,
    pageSize,
    data: typeData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const { [listFormName]: listPermission } = usePermission(subModPermissionSelector(R_SETTINGS, subModuleRef), listFormName);
  const { canView, canDelete, canAdd, canExport } = listPermission;

  const getRedirectUrl = useCallback(params => `/settings/${settingType}/${params.id}`, [settingType]);

  const addButtonAction = () => {
    navigate(`/settings/${settingType}/add`);
  };

  const getTypeData = useCallback(
    async typeKey => {
      setStatus(STAUTES.LOADING);
      setPageState(prevPageInfo => ({ ...prevPageInfo, data: [], rowCount: 0 }));
      const payload = {
        pageIndex: searchString ? 1 : currentPage + 1,
        pageSize: pageSize,
        searchString: searchString,
        orderByAscending: true,
        orderCol: typeKey,
        ...sortModel
      };
      const { data, totalRecords, error } = await postApi(getApiPath, payload);
      setStatus(STAUTES.IDLE);
      if (error) return errorToast(error);
      setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
    },
    [currentPage, pageSize, sortModel, searchString, setPageState, setStatus, getApiPath]
  );

  const onDelete = useCallback(
    async id => {
      const { error } = await deleteApi(deleteApiPath, { data: [{ [idKey]: id }] });
      if (error) return errorToast(error);
      getTypeData(typeKey);
      successToast(`${typeLabel} deleted successfully.`);
    },
    [typeLabel, idKey, deleteApiPath, getTypeData]
  );

  useEffect(() => {
    if (typeKey) getTypeData(typeKey);
    else navigate("/");
  }, [typeKey, getTypeData, navigate]);

  const columns = useMemo(
    () => [
      {
        field: typeKey,
        headerName: "Title",
        renderCell: params => <NameCell params={params} getRedirectUrl={getRedirectUrl} />
      },
      ...(canDelete
        ? [
            {
              field: "id",
              headerName: "Actions",
              isDeleteColumn: true,
              disableExport: true,
              width: 80,
              renderCell: params => {
                if (typeKey === "roleTypeName" && (params.row.roleTypeName === DETAULT_ROLE || params.row.roleTypeName === EMPLOYEE_ROLE))
                  return null;
                return <DeleteCell params={params} onDelete={onDelete} />;
              }
            }
          ]
        : [])
    ],
    [typeKey, getRedirectUrl, onDelete, canDelete]
  );

  return (
    <Layout canView={canView}>
      <ProGrid
        title={typeLabel}
        columns={columns}
        loading={status === STAUTES.LOADING}
        rows={typeData}
        searchMode={!!searchString}
        searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
        addButtonLabel={`+ Add ${typeLabel}`}
        addButtonAction={addButtonAction}
        hideAddButton={!canAdd}
        hideExport={!canExport}
        options={{
          getRowId: row => row[gridKey || idKey],
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          sortingMode: "server",
          onSortModelChange: setSortModel
        }}
      />
    </Layout>
  );
}
