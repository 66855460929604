import React from "react";
import ProTextInput from "./TextField";
import { InputAdornment } from "@mui/material";
import EmailIcon from "../../Assets/IconComponents/EmailIcon";
import { EMAIL_REG } from "../../../utils/validationExpression";
import ReadOnlyInput from "./ReadOnlyInput";
import { useTheme } from "../../ContextStore/ThemeContext";
import PropTypes from "prop-types";

export default function EmailField({ rules, mode, isAddMode, ...rest }) {
  const { theme } = useTheme();
  const { canView = false, canAdd = false, canEdit = false } = rest.permission || {};
  if (!canView || (isAddMode && !canAdd)) return null;
  const editModeAllowed = isAddMode ? canAdd : canEdit;
  if (mode === "read" || (mode === "edit" && !editModeAllowed)) return <ReadOnlyInput {...rest} />;
  return (
    <ProTextInput
      {...rest}
      rules={{
        ...(rules || {}),
        pattern: {
          value: EMAIL_REG,
          message: "Invalid email"
        }
      }}
      startAdornment={
        <InputAdornment position="start">
          <EmailIcon fill={theme === "dark" ? "#ffffff" : "#00050B"} />
        </InputAdornment>
      }
    />
  );
}

EmailField.propTypes = {
  mode: PropTypes.oneOf(["read", "edit"]).isRequired,
  rules: PropTypes.object.isRequired,
  isAddMode: PropTypes.bool
};
