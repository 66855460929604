import { useMemo, useState } from "react";

const useColumn = (initialColumns, canDelete = true) => {
  const [columns] = useState(initialColumns);

  return useMemo(() => {
    return columns.filter(col => (col.isDeleteColumn ? canDelete : true)).map(col => ({ ...col, minWidth: col.width || 150 }));
  }, [columns, canDelete]);
};

export default useColumn;
