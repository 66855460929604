import { Button, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import "./EmailCell.scss";
import ConfirmationModal from "../../ConfirmationModal";
import EmailIcon from "../../../Assets/IconComponents/EmailIcon";

export default function EmailCell({ params, onSend, disabled = false, fill, title }) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const openConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const onConfirm = () => {
    onSend(params.id);
    setShowConfirmationModal(false);
  };

  return (
    <>
      <Tooltip title={title} arrow>
        <Button className="proGrid-email" variant="outlined" size="small" onClick={openConfirmationModal} disabled={disabled}>
          <EmailIcon fontSize="small" fill={fill} />
        </Button>
      </Tooltip>

      {showConfirmationModal && (
        <ConfirmationModal
          show={showConfirmationModal}
          title={"Client User Verification"}
          message="Are you sure you want to send the Verify Link to the user ?"
          onConfirm={onConfirm}
          onCancel={() => setShowConfirmationModal(false)}
          confirmBtnText={"Send"}
        />
      )}
    </>
  );
}

EmailCell.propTypes = {
  params: PropTypes.object.isRequired,
  onSend: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fill: PropTypes.string,
  title: PropTypes.string
};
