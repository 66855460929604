import React from "react";
import { LineChart, lineElementClasses, markElementClasses } from "@mui/x-charts/LineChart";
import { renderFormattedDate } from "../../../../../utils/formHelper";
import { axisClasses } from "@mui/x-charts";
import PropTypes from "prop-types";

const LostOTJChart = ({ lostOtjData }) => {
  const { lostOtjHours } = lostOtjData || {};
  return (
    <LineChart
      height={400}
      grid={{ horizontal: true }}
      series={[
        {
          data: lostOtjHours?.map(item => item.otjHours),
          label: `OTJ Hours`,
          color: "#0CAFFF"
        },
        {
          data: lostOtjHours?.map(item => item.lostOTJHours),
          label: `Lost OTJ Hours`,
          color: "#FF033E"
        }
      ]}
      margin={{
        left: 60,
        bottom: 80
      }}
      xAxis={[
        {
          scaleType: "point",
          data: lostOtjHours?.map(item => renderFormattedDate(item.weekEndingDate)),
          labelStyle: {
            transform: "translateY(100px)"
          },
          tickLabelStyle: {
            angle: -45,
            textAnchor: "end",
            fontSize: 12
          }
        }
      ]}
      sx={{
        [`.${lineElementClasses.root}, .${markElementClasses.root}`]: { strokeWidth: 5 },
        [`.${axisClasses.left} .${axisClasses.label}`]: {
          transform: "translate(-20px, 0)"
        }
      }}
      yAxis={[{ label: "Hours" }]}
    />
  );
};

LostOTJChart.propTypes = {
  lostOtjData: PropTypes.shape({
    lostOtjHours: PropTypes.arrayOf(
      PropTypes.shape({
        otjHours: PropTypes.number,
        lostOTJHours: PropTypes.number,
        weekEndingDate: PropTypes.string
      })
    )
  })
};

export default LostOTJChart;
