import React from "react";
import Button from "@mui/material/Button";
import EditIcon from "../../Assets/IconComponents/EditIcon";
import PropTypes from "prop-types";

const EditButton = ({ onClick, beingEdited, hideEdit, isMobile }) => {
  if (beingEdited || hideEdit) return null;
  return (
    <Button variant="text" startIcon={<EditIcon withBg={true} />} onClick={onClick}>
      {isMobile ? "" : "Edit details"}
    </Button>
  );
};

export default EditButton;

EditButton.propTypes = {
  onClick: PropTypes.func,
  beingEdited: PropTypes.bool,
  hideEdit: PropTypes.bool,
  isMobile: PropTypes.bool
};
