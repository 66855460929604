import React, { useEffect } from "react";
import "./RapStatus.scss";
import { useTheme } from "../../../ContextStore/ThemeContext";
import CradsTopSection from "../../../Components/CradsTopSection/CradsTopSection";
import { GET_ALL_RAPSTATUS_LIST_SECTION } from "../../../../utils/services/apiPath";
import { useParams } from "react-router-dom";
import { getApi } from "../../../../utils/services";
import Loading from "../../../Components/Loading";
import { defaultDropdownConfig } from "../../../../utils/dropdownHelper";
import usePageState from "../../../../utils/customHooks/usePageState";
import { STAUTES } from "../../../../utils/constant";
import { errorToast } from "../../../../utils/toastHelper";
import { rapMgmtStatusPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import { useSelector } from "react-redux";
import { ErrorBox } from "../../../Components/Helpers/ErrorBox";
import ReportsLayout from "../../../Components/ProForms/ProFormsLayout/ReportsFormsLayout";

export default function RapStatus() {
  const { theme } = useTheme();
  const { id } = useParams();
  const { data: rapStatusData, status, setStatus, setPageState } = usePageState();
  const { canView } = useSelector(rapMgmtStatusPermissionSelector);

  useEffect(() => {
    const getRapStatusList = async () => {
      setStatus(STAUTES.LOADING);
      const { data, error } = await getApi(`${GET_ALL_RAPSTATUS_LIST_SECTION}?assignRAPId=${id} `, defaultDropdownConfig);
      setStatus(STAUTES.IDLE);
      if (error) return errorToast(error);
      setPageState({ data: data || [] });
    };
    getRapStatusList();
  }, []);

let content = null;

if (canView) {
  if (status === STAUTES.LOADING) {
    content = <Loading />;
  } else {
    content = (
      <ReportsLayout>
        <div className="rapstatus">
          <div className="rapstatus_container">
            <div className="rapstatus_section">
              <div className="rapstatus_section_top">
                {rapStatusData?.map((data) => (
                  <CradsTopSection
                    key={data?.heading}
                    icon={data?.icon}
                    title={data?.heading}
                    value={data?.value}
                    color={theme === "light" ? `${data?.lightColor}` : `${data?.darkColor}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </ReportsLayout>
    );
  }
} else {
  content = <ErrorBox message="You don't have permission to view this page." />;
}

  return (
    <>{content}</>
  );
}
