import React from "react";
import PropTypes from "prop-types";

const ReportsIcon = ({ color = "none" }) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3333 2.33301H12.9999C13.4419 2.33301 13.8659 2.5086 14.1784 2.82116C14.491 3.13372 14.6666 3.55765 14.6666 3.99967V15.6663C14.6666 16.1084 14.491 16.5323 14.1784 16.8449C13.8659 17.1574 13.4419 17.333 12.9999 17.333H2.99992C2.55789 17.333 2.13397 17.1574 1.82141 16.8449C1.50885 16.5323 1.33325 16.1084 1.33325 15.6663V3.99967C1.33325 3.55765 1.50885 3.13372 1.82141 2.82116C2.13397 2.5086 2.55789 2.33301 2.99992 2.33301H4.66659"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5001 0.666992H5.50008C5.03984 0.666992 4.66675 1.04009 4.66675 1.50033V3.16699C4.66675 3.62723 5.03984 4.00033 5.50008 4.00033H10.5001C10.9603 4.00033 11.3334 3.62723 11.3334 3.16699V1.50033C11.3334 1.04009 10.9603 0.666992 10.5001 0.666992Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ReportsIcon.propTypes = {
  color: PropTypes.string,
};

export default ReportsIcon;
