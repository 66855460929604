import { Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
export default function VideoModel({ videoUrl }) {
  return (
    <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
      <Box component="video" src={videoUrl} sx={{ width: "100%", height: "100%" }} controls className="test" />
    </Box>
  );
}

VideoModel.propTypes = {
  videoUrl: PropTypes.string.isRequired,
};