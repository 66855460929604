import React from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress } from "@mui/material";

export default function FormGridInsideSectionLayout({
  children,
  onCancel,
  canView = true,
  onSave,
  beingEdited,
  saveLabel = "Save",
  cancelLabel = "Cancel",
  isSaving
}) {
  if (!canView) return null;
  return (
    <div className="proForm-layout proFormGridInsideSectionLayout">
      <div className="proFormGridInsideSectionLayout-content">{children}</div>
      {beingEdited && (
        <div className="proForm-layout__footer">
          <div className="proForm-layout__footer__actions">
            <Button className="cancel-btn" variant="outlined" color="secondary" onClick={onCancel}>
              {cancelLabel}
            </Button>
            <Button variant="contained" onClick={onSave} disabled={isSaving}>
              {isSaving ? (
                <>
                  <CircularProgress size={24} />
                  <span style={{ marginLeft: 10 }}>{saveLabel}</span>
                </>
              ) : (
                saveLabel
              )}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

FormGridInsideSectionLayout.propTypes = {
  children: PropTypes.node,
  onCancel: PropTypes.func.isRequired,
  canView: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  beingEdited: PropTypes.bool,
  saveLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  isSaving: PropTypes.bool
};
