import React, { useCallback, useEffect } from "react";
import Layout from "../../../Components/Layout";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import ProGrid from "../../../Components/ProGrid/v2";
import { useNavigate } from "react-router-dom";
import usePageState from "../../../../utils/customHooks/usePageState";
import { deleteApi, postApi } from "../../../../utils/services";
import { DELETE_JOB_CATEGORY, GET_ALL_JOB_CATEGORY } from "../../../../utils/services/apiPath";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { STAUTES } from "../../../../utils/constant";
import usePermission from "../../../../utils/customHooks/usePermission";
import { subModPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import { listJobCategoryFormName } from "../../../../utils/formHelper";
import { R_JOB_CATEGORY, R_SETTINGS } from "../../../../utils/permissionReferenceConfig";
import useColumn from "../../../../utils/customHooks/useColumns";

const getRedirectUrl = params => {
  return `/settings/job-category/${params.id}`;
};

export default function JobCategoryList() {
  const navigation = useNavigate();
  const {
    currentPage,
    pageSize,
    data: jobCategoriesData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const { [listJobCategoryFormName]: listPermission } = usePermission(subModPermissionSelector(R_SETTINGS, R_JOB_CATEGORY), listJobCategoryFormName);
  const { canView, canDelete, canAdd, canExport } = listPermission;
  const columns = useColumn(
    [
      {
        field: "jobCategoryName",
        headerName: "Job Category Name",
        renderCell: params => <NameCell params={params} getRedirectUrl={getRedirectUrl} />
      },
      {
        field: "jobCategoryDescription",
        headerName: "Description"
      },
      {
        field: "id",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell params={params} fieldKey="jobCategoryId" onDelete={onDelete} />
      }
    ],
    canDelete
  );

  const addButtonAction = () => {
    navigation("/settings/job-category/add");
  };

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_JOB_CATEGORY, { data: [{ jobCategoryId: id }] });
    if (error) return errorToast(error);
    getJobCategories();
    successToast("Job Category deleted successfully.");
  };

  const getJobCategories = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "jobCategoryName",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_JOB_CATEGORY, payload);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    getJobCategories();
  }, [getJobCategories]);

  return (
    <Layout canView={canView}>
      <ProGrid
        title="Job Category"
        columns={columns}
        loading={status === STAUTES.LOADING}
        rows={jobCategoriesData}
        searchMode={!!searchString}
        searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
        addButtonLabel="+ Add Job Category"
        addButtonAction={addButtonAction}
        hideAddButton={!canAdd}
        hideExport={!canExport}
        options={{
          getRowId: row => row.jobCategoryId,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          sortingMode: "server",
          onSortModelChange: setSortModel
        }}
      />
    </Layout>
  );
}
