import React, { useCallback, useEffect } from "react";
import ProGrid from "../../Components/ProGrid/v2";
import NameCell from "../../Components/ProGrid/components/NameCell";
import WebLinkCell from "../../Components/ProGrid/components/WebLinkCell";
import DeleteCell from "../../Components/ProGrid/components/DeleteCell";
import Layout from "../../Components/Layout";
import { deleteApi, postApi } from "../../../utils/services";
import { DELETE_PARTNER_PROFILE, GET_ALL_PARTNERS } from "../../../utils/services/apiPath";
import { errorToast, successToast } from "../../../utils/toastHelper";
import { useNavigate } from "react-router-dom";
import { STAUTES } from "../../../utils/constant";
import usePageState from "../../../utils/customHooks/usePageState";
import { partnersListPermissionSelector } from "../../store/features/permissions/permissionsSlice";
import { useSelector } from "react-redux";
import useColumn from "../../../utils/customHooks/useColumns";

export default function PartnerList() {
  const {
    currentPage,
    pageSize,
    data: partners,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const navigate = useNavigate();
  const { canView, canDelete, canAdd, canExport } = useSelector(partnersListPermissionSelector);

  const columns = useColumn(
    [
      {
        field: "companyName",
        headerName: "Company Name",
        renderCell: params => <NameCell params={params} getRedirectUrl={params => params.row.clientId} />
      },
      {
        field: "legalBusinessName",
        headerName: "Legal Business Name"
      },
      {
        field: "website",
        headerName: "Website",
        renderCell: params => <WebLinkCell params={params} />
      },
      {
        field: "industrySector",
        headerName: "Industry Sector"
      },
      {
        field: "clientId",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell title={"Delete Partner Profile"} params={params} onDelete={onDelete} />
      }
    ],
    canDelete
  );

  const onDelete = async clientId => {
    const { error } = await deleteApi(DELETE_PARTNER_PROFILE, { data: [{ clientId: clientId }] });
    if (error) return errorToast(error);
    getPartnersList();
    successToast("Partner deleted successfully.");
  };

  const addButtonAction = () => {
    navigate("/partners/add/profile");
  };

  const getPartnersList = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "companyName",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_PARTNERS, payload);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    getPartnersList();
  }, [getPartnersList]);

  return (
    <Layout canView={canView}>
      <ProGrid
        title="Partners"
        columns={columns}
        loading={status === STAUTES.LOADING}
        rows={partners}
        searchMode={!!searchString}
        searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
        addButtonLabel="+ Add Partner"
        addButtonAction={addButtonAction}
        hideAddButton={!canAdd}
        hideExport={!canExport}
        options={{
          getRowId: row => row.clientId,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          sortingMode: "server",
          onSortModelChange: setSortModel
        }}
      />
    </Layout>
  );
}
