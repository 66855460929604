import React, { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Checkbox from "@mui/material/Checkbox";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Layout from "../../Components/Layout";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import { GET_ALL_PERMISSIONS_ADMIN, GET_ALL_ROLES, UPDATE_ALL_PERMISSIONS_ADMIN } from "../../../utils/services/apiPath";
import { getApi, postApi } from "../../../utils/services";
import { useDispatch } from "react-redux";
import { getAllPermissions } from "../../store/features/permissions/permissionsSlice";
import { errorToast } from "../../../utils/toastHelper";
import { DETAULT_ROLE } from "../../../utils/constant";
import SettingFormsLayout from "../../Components/ProForms/ProFormsLayout/SettingFormsLayout";
import ProSelectField from "../../Components/Inputs/SelectField";
import { fieldTruePermission } from "../../../utils/formHelper";
import "./UserPermissions.scss";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          backgroundColor: "#e9f9ff"
        },
        row: {
          "&:hover": {
            backgroundColor: "transparent"
          }
        }
      }
    }
  }
});

const groupingColDef = {
  headerName: "Name",
  field: "name",
  width: 300,
  minWidth: 300,
  hideDescendantCount: true,
  flex: 1
};

const UserPermissions = () => {
  const [treeData, setTreeData] = useState([]);
  const { control, setValue, watch } = useForm();
  const [loading, setLoading] = useState(true);
  const [rolesData, setRolesData] = useState([]);
  const roleId = watch("roleId");
  const dispatch = useDispatch();

  const getAllRoles = async () => {
    try {
      const { error, data } = await getApi(GET_ALL_ROLES);
      if (error) return errorToast(error);
      const rolesWithOutAdmin = data.filter(role => role.label !== DETAULT_ROLE);
      setRolesData(rolesWithOutAdmin);
      setValue("roleId", rolesWithOutAdmin[0]?.value || null);
    } catch (error) {
      console.log(error);
    }
  };
  const getRolePermission = async () => {
    if (!roleId) return;
    setLoading(true);
    const { data, error } = await postApi(`${GET_ALL_PERMISSIONS_ADMIN}?RoleTypeGuid=${roleId}`);
    setLoading(false);
    if (error) return errorToast(error);
    const formattedData = data.map((item, index) => ({ ...item, id: index + 1 }));
    setTreeData(formattedData);
  };

  const updateAllPemissionsAdmin = async () => {
    if (!roleId) return;
    setLoading(true);
    const payload = { permissions: treeData.map(per => (per.isMandatory ? { ...per, canAdd: true } : per)), roleTypeId: roleId };
    const { error } = await postApi(UPDATE_ALL_PERMISSIONS_ADMIN, payload);
    setLoading(false);
    if (error) return errorToast(error);
    dispatch(getAllPermissions(true));
    getRolePermission();
  };

  const handleCheckboxChange = (event, params) => {
    const { field, row } = params;
    const { path } = row;
    const lastPathSegment = path[path.length - 1];
    const value = event.target.checked;

    setTreeData(prevData => {
      return prevData.map(item => {
        return item?.path?.includes(lastPathSegment) ? updateRowAndDescendants(item, field, value, lastPathSegment) : item;
      });
    });
  };

  const updateRowAndDescendants = (row, field, value, targetPathSegment) => {
    const updatedRow = { ...row, [field]: value };
    if (row.children && row.children.length > 0) {
      updatedRow.children = row.children.map(child => updateRowAndDescendants(child, field, value, targetPathSegment));
    }
    return updatedRow;
  };

  const handleSelectAllChange = (event, field) => {
    const checked = event.target.checked;
    setTreeData(prevData =>
      prevData.map(row => ({
        ...row,
        [field]: checked
      }))
    );
  };

  useEffect(() => {
    if (!roleId) getAllRoles();
  }, []);

  useEffect(() => {
    getRolePermission();
  }, [roleId]);

  const renderSelectAllCheckbox = (field, name) => {
    return (
      <div style={{ display: "flex" }}>
        <Checkbox onChange={event => handleSelectAllChange(event, field)} />
        <span style={{ fontSize: "14px" }}>{name}</span>
      </div>
    );
  };

  const renderCheckbox = params => {
    const { field, row, value } = params;
    const isMandatory = "canAdd" === field && row.fieldId && row.isMandatory;
    return <Checkbox disabled={isMandatory} checked={isMandatory || value} onChange={event => handleCheckboxChange(event, params)} />;
  };

  const columns = [
    {
      field: "canView",
      minWidth: 150,
      headerName: renderSelectAllCheckbox("canView", "View"),
      renderCell: renderCheckbox,
      editable: false
    },
    {
      field: "canAdd",
      minWidth: 150,
      headerName: renderSelectAllCheckbox("canAdd", "Create"),
      renderCell: renderCheckbox,
      editable: false
    },
    {
      field: "canEdit",
      minWidth: 150,
      headerName: renderSelectAllCheckbox("canEdit", "Update"),
      renderCell: renderCheckbox,
      editable: false
    },
    {
      field: "canDelete",
      minWidth: 150,
      headerName: renderSelectAllCheckbox("canDelete", "Delete"),
      renderCell: renderCheckbox,
      editable: false
    },
    {
      field: "canImport",
      minWidth: 150,
      headerName: renderSelectAllCheckbox("canImport", "Import"),
      renderCell: renderCheckbox,
      editable: false
    },
    {
      field: "canExport",
      minWidth: 150,
      headerName: renderSelectAllCheckbox("canExport", "Export"),
      renderCell: renderCheckbox,
      editable: false
    }
  ];

  return (
    <Layout>
      <SettingFormsLayout
        title={`Manage User Permission`}
        backLabel="Go Back to All Job Title"
        beingEdited={true}
        hideCancel={true}
        hideBackbutton={true}
        onSave={updateAllPemissionsAdmin}
      >
        <Grid container mb={"10px"}>
          <ProSelectField control={control} label="Select User" name={"roleId"} mode={"edit"} options={rolesData} permission={fieldTruePermission} />
        </Grid>
        <div className="userPermissionWrapper">
          <ThemeProvider theme={theme}>
            <DataGridPro
              loading={loading}
              rows={treeData}
              columns={columns}
              checkboxSelection={false}
              treeData
              groupingColDef={groupingColDef}
              getTreeDataPath={row => row.path}
              defaultGroupingExpansionDepth={0}
              isRowSelectable={params => params.id !== undefined}
              hideFooterSelectedRowCount
              hideFooterRowCount
              hideFooter
            />
          </ThemeProvider>
        </div>
      </SettingFormsLayout>
    </Layout>
  );
};

export default UserPermissions;
