import React, { useState } from "react";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import Loading from "../../../Components/Loading";
import { formatKeys } from "../../../../utils/formHelper";
import { payrollFields, timeSheetMultiRowFields, timeSheetSingleRowFields } from "../projectData";
import { errorToast } from "../../../../utils/toastHelper";
import PropTypes from "prop-types";
import DialogModal from "../../../Components/DialogModel";
import {
  GET_PROJECT_SUBMITTIMESHEETEXCEL,
  GET_PROJECT_SUBMITWEEKLYTIMESHEETEXCEL,
  GET_PROJECTPAYROLL_SUBMITTIMESHEETEXCEL
} from "../../../../utils/services/apiPath";
import { postApi } from "../../../../utils/services";

const ProjectCertifiedPayrollSpreadsheet = ({
  id,
  isOpen,
  setIsOpen,
  timeSheetValue,
  selectedValue,
  apiMessage,
  setApiMessage,
  certifiedPayrollModal,
  setCertifiedPayrollModal,
  handlePayrollTimeSheet
}) => {
  const [uploadLoader, setUploadLoader] = useState(false);
  const [timeSheetModal, setTimeSheetModal] = useState(false);
  const [payrollSummaryModal, setPayrollSummaryModal] = useState(false);

  const handleCancelTimeSheet = () => {
    setTimeSheetModal(false);
  };

  const handleCancelPayrollSummary = () => {
    setPayrollSummaryModal(false);
  };

  const handleCertifiedPayroll = () => {
    setCertifiedPayrollModal(false);
  };

  const handleCloseDialogpopup = () => {
    setIsOpen(false);
  };

  const handleTimeSheetSubmit = async (data, selectedFile) => {
    const reader = new FileReader();
    setUploadLoader(true);
    reader.onload = async () => {
      const base64String = reader.result.split(",")[1];

      const payload = {
        projectId: id,
        fileModel: {
          base64String: base64String,
          fileName: selectedFile.name
        },
        excelData: formatKeys(data?.validData)
      };

      try {
        const res = await postApi(`${GET_PROJECT_SUBMITWEEKLYTIMESHEETEXCEL}`, payload);

        if (res.statusCode === 200) {
          setUploadLoader(false);
          setTimeSheetModal(true);
          setApiMessage(res.message);
          handlePayrollTimeSheet();
        } else {
          setUploadLoader(false);
          handlePayrollTimeSheet();
          setTimeSheetModal(true);
          setApiMessage(res.error);
        }
      } catch (err) {
        setUploadLoader(false);
        handlePayrollTimeSheet();
        setTimeSheetModal(true);
        setApiMessage(`Timesheet Excel Data Failed to Upload`);
      }
    };

    reader.onerror = error => {
      errorToast("Timesheet Excel Data Failed to Upoad");
      console.error(error);
    };

    reader.readAsDataURL(selectedFile);
  };

  const handleWeeklyTimeSheetSubmit = async (data, selectedFile) => {
    const reader = new FileReader();
    setUploadLoader(true);

    reader.onload = async () => {
      const base64String = reader.result.split(",")[1];

      const payload = {
        projectId: id,
        fileModel: {
          base64String: base64String,
          fileName: selectedFile.name
        },
        excelData: formatKeys(data?.validData)
      };

      try {
        const res = await postApi(`${GET_PROJECT_SUBMITTIMESHEETEXCEL}`, payload);

        if (res.statusCode === 200) {
          setUploadLoader(false);
          setTimeSheetModal(true);
          setApiMessage(res.message);
          handlePayrollTimeSheet();
        } else {
          setUploadLoader(false);
          handlePayrollTimeSheet();
          setTimeSheetModal(true);
          setApiMessage(res.error);
        }
      } catch (err) {
        setUploadLoader(false);
        handlePayrollTimeSheet();
        setTimeSheetModal(true);
        setApiMessage(`Timesheet Excel Data Failed to Upload`);
      }
    };

    reader.onerror = error => {
      errorToast("Timesheet Excel Data Failed to Upoad");
      console.error(error);
    };

    reader.readAsDataURL(selectedFile);
  };

  const handlePayrollSummarySubmit = async (data, selectedFile) => {
    const reader = new FileReader();

    reader.onload = async () => {
      const base64String = reader.result.split(",")[1];
      setUploadLoader(true);

      const payload = {
        fileModel: {
          base64String: base64String,
          fileName: selectedFile.name
        },
        excelData: formatKeys(data?.validData)
      };

      try {
        const res = await postApi(`${GET_PROJECTPAYROLL_SUBMITTIMESHEETEXCEL}?projectId=${id}`, payload);
        if (res.statusCode === 200) {
          setUploadLoader(false);
          setPayrollSummaryModal(true);
          setApiMessage(res.message);
          handlePayrollTimeSheet();
        } else {
          setUploadLoader(false);
          setPayrollSummaryModal(true);
          setApiMessage(res.error);
          handlePayrollTimeSheet();
        }
      } catch (err) {
        setUploadLoader(false);
        handlePayrollTimeSheet();
        errorToast("Payroll Excel Data Failed to Upload");
        setPayrollSummaryModal(true);
        setApiMessage("Payroll Excel Data Failed to Upload");
      }
    };

    reader.onerror = error => {
      errorToast("Payroll Excel Data Failed to Upoad");
    };

    reader.readAsDataURL(selectedFile);
  };

  const determineSubmitHandler = () => {
    if (selectedValue === "1") {
      return timeSheetValue === "3" ? handleWeeklyTimeSheetSubmit : handleTimeSheetSubmit;
    } else {
      return handlePayrollSummarySubmit;
    }
  };

  const timeSheetMultipleRows = () => {
    if (selectedValue === "1") {
      return timeSheetValue === "3" ? timeSheetMultiRowFields : timeSheetSingleRowFields;
    } else {
      return payrollFields;
    }
  };

  return (
    <>
      <ReactSpreadsheetImport
        isOpen={isOpen}
        onClose={handleCloseDialogpopup}
        onSubmit={determineSubmitHandler()}
        fields={timeSheetMultipleRows()}
        customTheme={{
          rsi: {
            overflow: "auto !important"
          }
        }}
      />
      {uploadLoader && <Loading />}
      <DialogModal show={timeSheetModal} title="Message" message={` ${apiMessage}`} onConfirm={handleCancelTimeSheet} showCancel={false} />

      <DialogModal show={payrollSummaryModal} title="Message" message={` ${apiMessage}`} onConfirm={handleCancelPayrollSummary} showCancel={false} />

      <DialogModal show={certifiedPayrollModal} title="Message" message={` ${apiMessage}`} onConfirm={handleCertifiedPayroll} showCancel={false} />
    </>
  );
};

ProjectCertifiedPayrollSpreadsheet.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  timeSheetValue: PropTypes.string.isRequired,
  selectedValue: PropTypes.string.isRequired,
  certifiedPayrollModal: PropTypes.bool.isRequired,
  setCertifiedPayrollModal: PropTypes.func.isRequired,
  apiMessage: PropTypes.string.isRequired,
  setApiMessage: PropTypes.func.isRequired,
  handlePayrollTimeSheet: PropTypes.func.isRequired
};

export default ProjectCertifiedPayrollSpreadsheet;
