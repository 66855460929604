import { createSlice } from "@reduxjs/toolkit";
import { STAUTES } from "../../../../utils/constant";
import { GET_ALL_EMPLOYEES } from "../../../../utils/services/apiPath";
import { postApi } from "../../../../utils/services";

const initialState = {
  data: [],
  status: STAUTES.IDLE
};

export const getAllEmployees = (payload) => {
    return async dispatch => {
      dispatch(SET_STATUS(STAUTES.LOADING));
      try {
        const response = await postApi(GET_ALL_EMPLOYEES, { payload });
        dispatch(EMPLOYEES(response));
        dispatch(SET_STATUS(STAUTES.IDLE));
      } catch (error) {
        dispatch(SET_STATUS(STAUTES.ERROR));
      }
    };
  };

export const employeesSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    EMPLOYEES: (state, action) => {
      state.data = action.payload;
    },
    SET_STATUS: (state, action) => {
      state.status = action.payload;
    }
  }
});

export const { EMPLOYEES, SET_STATUS } = employeesSlice.actions;

export const employeesSelector = state => state.employees;

export default employeesSlice.reducer;
