import { Link } from "@mui/material";
import React from "react";
import "./WebLinkCell.scss";
import PropTypes from "prop-types";

export default function WebLinkCell({ params }) {
  const { value } = params;
  return (
    <Link
      className="proGrid-link"
      target="_blank"
      href={value}
      underline="none"
      color
    >
      {value}
    </Link>
  );
}

WebLinkCell.propTypes = {
  params: PropTypes.object.isRequired,
};