import React, { useEffect, useState } from "react";
import DialogModal from "../../Components/DialogModel";
import { VERIFY_ACCOUNT } from "../../../utils/services/apiPath";
import { postApi } from "../../../utils/services";
import { useNavigate } from "react-router-dom";

const VerifyAccount = () => {
  const navigate = useNavigate();
  const [isModalPopup, setIsModalPopup] = useState(false);
  const [modalMessage, setModalMessage] = useState("");


  const verifyAccountUser = async () => {
    try {
      const urlSearchString = window.location.search;
      const params = new URLSearchParams(urlSearchString);
      const key = params.get("key");

      if (!key) {
        setModalMessage("Invalid verification Link.");
        setIsModalPopup(true);
        return;
      }

      const res = await postApi(`${VERIFY_ACCOUNT}?url=${key}`);

      if (res.success) {
        setModalMessage("Your account has been verified.");
        navigate("/resetpassword-otp", { state: { clientGuid: res.data } });
      } else {
        setIsModalPopup(true);
        setModalMessage(res.error || "This url has been expired. Verification failed.");
      }
    } catch (error) {
      setModalMessage(error.message || "An error occurred during verification.");
      setIsModalPopup(true);
    }
  };

  useEffect(() => {
    verifyAccountUser();
  }, []);

  const handlePopupClose = () => {
    setIsModalPopup(false);
    navigate("/");
  };

  return (
    <div>
      <DialogModal show={isModalPopup} title="Verification" message={modalMessage} onConfirm={handlePopupClose} showCancel={false} />
    </div>
  );
};

export default VerifyAccount;
