import React, { useCallback, useEffect } from "react";
import ProGrid from "../../../Components/ProGrid/v2";
import { useParams } from "react-router-dom";
import { postApi } from "../../../../utils/services";
import { GET_ALL_RAPAPPRENTICE_LIST } from "../../../../utils/services/apiPath";
import { Typography } from "@mui/material";
import { STAUTES } from "../../../../utils/constant";
import { errorToast } from "../../../../utils/toastHelper";
import usePageState from "../../../../utils/customHooks/usePageState";
import { useSelector } from "react-redux";
import { rapMgmtApprenticePermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import { ErrorBox } from "../../../Components/Helpers/ErrorBox";
import useColumn from "../../../../utils/customHooks/useColumns";
import { dateColumnDef } from "../../../Components/ProGrid/components/FormatCell";

export default function RAPApprentice() {
  const { id } = useParams();
  const {
    currentPage,
    pageSize,
    data: apprenticeData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const { canView, canExport } = useSelector(rapMgmtApprenticePermissionSelector);

  const columns = useColumn([
    {
      field: "firstName",
      headerName: "Employee Name",
      renderCell: params => (
        <Typography sx={{ fontSize: "14px" }}>
          {params.row.firstName} {params.row.lastName}
        </Typography>
      )
    },
    dateColumnDef({
      field: "dateApprenticeshipBegins",
      headerName: "Start Date"
    }),
    {
      field: "otjHours",
      headerName: "OTJ Hours"
    },
    {
      field: "rtiHours",
      headerName: "RTI Hours"
    }
  ]);

  const getRapApprenticeList = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "firstName",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_RAPAPPRENTICE_LIST, payload, { params: { assignRapInfoId: id } });
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    getRapApprenticeList();
  }, [getRapApprenticeList]);

  return (
    <>
      {canView ? (
        <ProGrid
          title="Apprentice"
          loading={status === STAUTES.LOADING}
          columns={columns}
          hideAddButton={true}
          rows={apprenticeData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
          hideExport={!canExport}
          options={{
            getRowId: row => row.employeeId,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
        />
      ) : (
        <ErrorBox message="You don't have permission to view this page." />
      )}
    </>
  );
}
