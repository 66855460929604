import * as React from "react";
import "./ReportsDetails.scss";
import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, CircularProgress, Box } from "@mui/material";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import { getApi } from "../../../../utils/services";
import { GET_ALL_WORKER_SUB_DETAIL } from "../../../../utils/services/apiPath";
import { useTheme } from "../../../ContextStore/ThemeContext";
import { fieldTruePermission } from "../../../../utils/formHelper";
import ProSelectField from "../../../Components/Inputs/SelectField";
import PropTypes from 'prop-types';
function Row(props) {
  const { row, selectedDate, projectId } = props;
  const [open, setOpen] = React.useState(false);
  const [workerSubDetails, setWorkerSubDetails] = useState({});
  const { theme } = useTheme();
  const cellStyles = theme === "dark" ? { color: "#ffffff" } : { color: "#00050B" };

  const getWorkerSubDetail = async employeeGuid => {
    setOpen(!open);
    if (open === false) {
      await getApi(`${GET_ALL_WORKER_SUB_DETAIL}?employeeGuid=${employeeGuid}&weekEndingDate=${selectedDate}&projectId=${projectId}`)
        .then(res => {
          setWorkerSubDetails(res.data);
        })
        .catch(e => console.log(e));
    }
  };
  return (
    <>
      <TableRow className="table-row" sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          {row.employeeType === "Apprentice" && (
            <IconButton aria-label="expand row" size="small" onClick={() => getWorkerSubDetail(row.employeeId)}>
              {open ? <KeyboardArrowUpIcon sx={cellStyles} /> : <KeyboardArrowDownIcon sx={cellStyles} />}
            </IconButton>
          )}
        </TableCell>
        <TableCell sx={cellStyles}>{row.firstName}</TableCell>
        <TableCell sx={cellStyles}>{row.lastName}</TableCell>
        <TableCell sx={cellStyles}>{row.employeeType}</TableCell>
        <TableCell sx={cellStyles}>{row.employeeStatusType}</TableCell>
        <TableCell sx={cellStyles}>{row.totalStandardHours}</TableCell>
      </TableRow>
      <TableRow style={{ borderRight: "1px solid #d6ddea", borderLeft: "1px solid #d6ddea" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Typography fontWeight={500} fontSize={14} sx={cellStyles}>
                  Total OTJ Hours <br /> {workerSubDetails.totalOTJHours}
                </Typography>
                <Typography fontWeight={500} fontSize={14} sx={cellStyles}>
                  Total Weekly OTJ Hours <br /> {workerSubDetails.totalWeeklyOTJHours}
                </Typography>
                <Typography fontWeight={500} fontSize={14} sx={cellStyles}>
                  RTI Hours <br /> {workerSubDetails.rtiHours}
                </Typography>
                <Typography fontWeight={500} fontSize={14} sx={cellStyles}>
                  RTI Time Logged for the Week <br /> {workerSubDetails.rtiHoursLoggedToDate}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const employeeTypeList = [
  {
    label: "All",
    value: "All"
  },
  {
    label: "Apprentice",
    value: "Apprentice"
  },
  {
    label: "Mentor",
    value: "Mentor"
  },
  {
    label: "Journey Worker",
    value: "Journey Worker"
  }
];

const WorkerDetailReport = ({ workerDetailReports, selectedDate, projectId, employeeType, defaultFormProps, formConfig, loading }) => {
  const { theme } = useTheme();
  const cellStyles = theme === "dark" ? { color: "#ffffff" } : { color: "#00050B" };

  return (
    <div className="report-details">
      {employeeType && (
        <Box sx={{ pb: 4 }}>
          <Stack p={1} direction="row" justifyContent="space-between" flexWrap="wrap" alignItems="flex-start">
            <Typography fontWeight="700" fontSize={18} sx={cellStyles}>
              Worker Details
            </Typography>
            <Box sx={{ width: 240 }}>
              <ProSelectField
                {...defaultFormProps}
                size={12}
                {...formConfig.employeeType}
                options={employeeTypeList}
                permission={fieldTruePermission}
              />
            </Box>
          </Stack>
          <TableContainer sx={{ maxHeight: "500px" }}>
            {workerDetailReports?.length === 0 ? (
              <>
              {loading ?
                <CircularProgress size="3rem" sx={{ display: "flex", margin: "0 auto", my: 5 }} />
              : <Typography align="center">No Data Found</Typography>}
              </>
            ) : (
              <Table sx={{ minWidth: "100%" }} aria-label="collapsible table">
                <TableHead>
                  <TableRow className="table-head-row">
                    <TableCell />
                    <TableCell sx={cellStyles}>First Name</TableCell>
                    <TableCell sx={cellStyles}>Last Name</TableCell>
                    <TableCell sx={cellStyles}>Employee Type</TableCell>
                    <TableCell sx={cellStyles}>Status</TableCell>
                    <TableCell sx={cellStyles}>Total Standard Hours</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {workerDetailReports?.map(row => (
                    <Row key={row.employeeId} row={row} selectedDate={selectedDate} projectId={projectId} />
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Box>
      )}
    </div>
  );
};
Row.propTypes = {
  row: PropTypes.object.isRequired,
  selectedDate: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
};

WorkerDetailReport.propTypes = {
  workerDetailReports: PropTypes.array.isRequired,
  selectedDate: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  employeeType: PropTypes.string.isRequired,
  defaultFormProps: PropTypes.object.isRequired,
  formConfig: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default WorkerDetailReport;
