import { Input } from "@mui/material";
import React, { useRef } from "react";
import PropTypes from "prop-types";

export default function DebounceInput({ handleDebounce = () => {}, debounceTimeout = 1000, ...rest }) {
  const timerRef = useRef();

  const handleChange = event => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      handleDebounce(event.target.value);
    }, debounceTimeout);
  };

  return <Input {...rest} onChange={handleChange} />;
}

DebounceInput.propTypes = {
  handleDebounce: PropTypes.func,
  debounceTimeout: PropTypes.number
};
