import React from "react";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";

export default function ProButton({ children, isSaving, ...props }) {
  return (
    <Button disabled={isSaving} variant="contained" color="primary" {...props}>
      {children}
    </Button>
  );
}

ProButton.propTypes = {
  children: PropTypes.node.isRequired,
  isSaving: PropTypes.bool
};
