import React, { forwardRef, useCallback, useState, useEffect } from "react";
import { Box, Button, Dialog, Slide, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ProGrid from "../../Components/ProGrid/v2";
import NameCell from "../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../Components/ProGrid/components/DeleteCell";
import Layout from "../../Components/Layout";
import { STAUTES } from "../../../utils/constant";
import { GET_ALL_EMPLOYEES, DELETE_EMPLOYEE, UPLOAD_EXCEL_EMPLOYEES } from "../../../utils/services/apiPath";
import { errorToast, successToast } from "../../../utils/toastHelper";
import usePageState from "../../../utils/customHooks/usePageState";
import { postApi, deleteApi } from "../../../utils/services";
import { R_EMPLOYEES, R_EMPLOYEES_LIST } from "../../../utils/permissionReferenceConfig";
import useColumn from "../../../utils/customHooks/useColumns";
import { subModPermissionSelector } from "../../store/features/permissions/permissionsSlice";
import { useSelector } from "react-redux";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { formatKeys, modifyEmployeeImportData } from "../../../utils/formHelper";

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const Employees = [
  "First Name",
  "Middle Name",
  "Last Name",
  "Job Title",
  "Cell Phone",
  "Office Phone",
  "Whatsapp Number",
  "Birth Date",
  "Hourly Rate Amount",
  "Fringe Rate Amount",
  "Email",
  "Address",
  "Address2",
  "City",
  "State",
  "Zip",
  "Last Four Digit SSN",
  "Internal EmployeeID"
];

const fields = Employees?.map(fieldName => ({
  label: fieldName,
  key: fieldName,
  fieldType: {
    type: "input"
  }
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function EmployeeList() {
  const {
    currentPage,
    pageSize,
    data: employeesData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const { canView, canAdd, canExport, canDelete, canImport } = useSelector(subModPermissionSelector(R_EMPLOYEES, R_EMPLOYEES_LIST));
  const [isOpen, setIsOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const navigation = useNavigate();
  const columns = useColumn(
    [
      {
        field: "firstName",
        headerName: "First Name",
        renderCell: params => <NameCell params={params} getRedirectUrl={params => params.row.employeeId} />
      },
      {
        field: "lastName",
        headerName: "Last Name"
      },
      {
        field: "jobTitle",
        headerName: "Job Title"
      },
      {
        field: "projectName",
        headerName: "Project Name"
      },
      {
        field: "employeeStatusTypeName",
        headerName: "Status"
      },
      {
        field: "isAppentice",
        headerName: "Is Apprentice",
        renderCell: params => <Typography>{params.row.isAppentice ? "Yes" : "No"}</Typography>
      },
      {
        field: "employeeId",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell params={params} onDelete={onDelete} />
      }
    ],
    canDelete
  );

  const handleDownload = () => {
    const filePath = "/static/Images/Excel/EmployeeImportTemplate.xlsx";
    window.open(filePath, "_blank");
  };

  const handleOpenDialogpopup = () => {
    setIsOpen(true);
  };

  const handleCloseDialogpopup = () => {
    setIsOpen(false);
  };

  const handleSubmit = async ({ validData }) => {
    const excelData = formatKeys(validData);
    const { error } = await postApi(UPLOAD_EXCEL_EMPLOYEES, modifyEmployeeImportData(excelData));
    if (error) return errorToast(error);
    handleOpenDialog();
    setMessage("Added Excel Data Successfully.");
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    getEmployeeList();
  };

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_EMPLOYEE, { data: [{ employeeId: id }] });
    if (error) return errorToast(error);
    successToast("Employee deleted successfully.");
    getEmployeeList();
  };

  const addButtonAction = () => {
    navigation("/employees/add");
  };

  const getEmployeeList = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "firstName",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_EMPLOYEES, payload);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    getEmployeeList();
  }, [getEmployeeList]);

  return (
    <Layout canView={canView}>
      <ProGrid
        title="Employees"
        loading={status === STAUTES.LOADING}
        options={{
          getRowId: row => row.employeeId,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          sortingMode: "server",
          onSortModelChange: setSortModel
        }}
        columns={columns}
        hideAddButton={!canAdd}
        hideExport={!canExport}
        rows={employeesData}
        searchMode={!!searchString}
        searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
        addButtonLabel="+ Add Employee"
        addButtonAction={addButtonAction}
        showImportExcel={canImport}
        showDownloadSampleExcel={canImport}
        importExcelAction={handleOpenDialogpopup}
        downloadSampleExcelAction={handleDownload}
        importExcelLabel={"Import Excel"}
        downloadSampleExcelLabel={"Employee Import Template"}
      />
      <ReactSpreadsheetImport isOpen={isOpen} onClose={handleCloseDialogpopup} onSubmit={handleSubmit} fields={fields} />
      <DialogWrapper open={openDialog} maxWidth="sm" fullWidth TransitionComponent={Transition} keepMounted onClose={handleCloseDialog}>
        <Box sx={{ padding: "20px" }}>
          <Typography fontSize={15} fontWeight={600}>
            {message}
          </Typography>
          <Button fullWidth size="large" variant="contained" sx={{ mt: 2 }} onClick={handleCloseDialog}>
            Close
          </Button>
        </Box>
      </DialogWrapper>
    </Layout>
  );
}
