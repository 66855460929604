import { createSlice } from "@reduxjs/toolkit";
import { STAUTES } from "../../../../utils/constant";
import { getApi } from "../../../../utils/services";
import { GET_ALL_PERMISSIONS } from "../../../../utils/services/apiPath";
import { addReferenceToPermission, getPermissionFromLocalStorage, setPermissionToLocalStorage } from "../../DataTransformer/permissionTransformer";
import {
  R_DASHBOARD,
  R_PARTNERS,
  R_PROJECTS,
  R_EMPLOYEES,
  R_RAP_MGMT,
  R_REPORTS,
  R_SETTINGS,
  R_NOTES,
  R_DOCUMENTS,
  R_PROFILE,
  R_SOCIAL_MEDIA,
  R_PARTNERS_PROFILE,
  R_PARTNERS_NOTES,
  R_PARTNERS_SOCIALMEDIA,
  R_PARTNERS_LOCATION,
  R_PARTNERS_LIST,
  R_PARTNERS_CONTACTS,
  R_PARTNERS_DOCUMENTS,
  R_EMPLOYEES_LIST,
  R_TIME_SHEET,
  R_RAP_MGMT_LIST,
  R_RAP_MGMT_APPRENTICE,
  R_RAP_MGMT_STATUS,
  R_RAP_MGMT_WAGE_SCALE,
  R_PROJECTS_JOBS,
  R_TOP_NAVIGATION
} from "../../../../utils/permissionReferenceConfig";

const initialState = {
  permissions: {},
  permissionStatus: STAUTES.IDLE,
  permissionLoaded: false,
  message: ""
};

export const getAllPermissions = forcedRefresh => {
  return async dispatch => {
    if (!forcedRefresh && localStorage.getItem("permissions")) {
      try {
        return dispatch(SET_PERMISSION(getPermissionFromLocalStorage()));
      } catch (error) {
        localStorage.removeItem("permissions");
        getAllPermissions(true);
      }
    }
    dispatch(SET_STATUS(STAUTES.LOADING));
    const res = await getApi(GET_ALL_PERMISSIONS);
    if (res.error) return dispatch(SET_MESSAGE(res.error));
    setPermissionToLocalStorage(res.data);
    dispatch(SET_PERMISSION(res.data));
    dispatch(SET_STATUS(STAUTES.IDLE));
  };
};

const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    SET_PERMISSION: (state, action) => {
      state.permissions = addReferenceToPermission(action.payload || []);
      state.permissionLoaded = true;
    },
    SET_STATUS: (state, action) => {
      state.permissionStatus = action.payload;
    },
    SET_MESSAGE: (state, action) => {
      state.message = action.payload;
      state.permissionStatus = STAUTES.ERROR;
    },
    REMOVE_PERMISSION: state => {
      state.permissions = {};
      state.permissionLoaded = false;
    }
  }
});

export const { SET_PERMISSION, REMOVE_PERMISSION, SET_STATUS, SET_MESSAGE, SET_PERMISSION_LOADED } = permissionSlice.actions;

export const allPermissionSelector = state => state.permissions;
export const dashboardPermissionSelector = state => state.permissions.permissions?.[R_DASHBOARD] || {};
export const topNavigationPermissionSelector = state => state.permissions.permissions?.[R_TOP_NAVIGATION] || {};
export const partnersPermissionSelector = state => state.permissions.permissions?.[R_PARTNERS] || {};
export const partnersProfilePermissionSelector = state =>
  state.permissions.permissions?.[R_PARTNERS]?.subModulePermissions?.[R_PARTNERS_PROFILE] || {};
export const partnersContactPermissionSelector = state =>
  state.permissions.permissions?.[R_PARTNERS]?.subModulePermissions?.[R_PARTNERS_CONTACTS] || {};
export const partnersDocumentPermissionSelector = state =>
  state.permissions.permissions?.[R_PARTNERS]?.subModulePermissions?.[R_PARTNERS_DOCUMENTS] || {};
export const partnersNotesPermissionSelector = state => state.permissions.permissions?.[R_PARTNERS]?.subModulePermissions?.[R_PARTNERS_NOTES] || {};
export const partnersSocialMediaPermissionSelector = state =>
  state.permissions.permissions?.[R_PARTNERS]?.subModulePermissions?.[R_PARTNERS_SOCIALMEDIA] || {};
export const partnersLocationPermissionSelector = state =>
  state.permissions.permissions?.[R_PARTNERS]?.subModulePermissions?.[R_PARTNERS_LOCATION] || {};
export const projectsPermissionSelector = state => state.permissions.permissions?.[R_PROJECTS] || {};
export const projectsJobsPermissionSelector = state => state.permissions.permissions?.[R_PROJECTS]?.subModulePermissions?.[R_PROJECTS_JOBS] || {};
export const rapPermissionSelector = state => state.permissions.permissions?.[R_RAP_MGMT] || {};
export const reportsPermissionSelector = state => state.permissions.permissions?.[R_REPORTS] || {};
export const settingsPermissionSelector = state => state.permissions.permissions?.[R_SETTINGS] || {};

// employees
export const employeesPermissionSelector = state => state.permissions.permissions?.[R_EMPLOYEES] || {};
export const employeesListPermissionSelector = state => state.permissions.permissions?.[R_EMPLOYEES]?.subModulePermissions?.[R_EMPLOYEES_LIST] || {};
export const employeesNotesPermissionSelector = state => state.permissions.permissions?.[R_EMPLOYEES]?.subModulePermissions?.[R_NOTES] || {};
export const employeesDocumentsPermissionSelector = state => state.permissions.permissions?.[R_EMPLOYEES]?.subModulePermissions?.[R_DOCUMENTS] || {};
export const employeesProfilePermissionSelector = state => state.permissions.permissions?.[R_EMPLOYEES]?.subModulePermissions?.[R_PROFILE] || {};
export const employeesSocialMediaPermissionSelector = state =>
  state.permissions.permissions?.[R_EMPLOYEES]?.subModulePermissions?.[R_SOCIAL_MEDIA] || {};
export const partnersListPermissionSelector = state => state.permissions.permissions?.[R_PARTNERS]?.subModulePermissions?.[R_PARTNERS_LIST] || {};
export const employeesTimeSheetPermissionSelector = state => state.permissions.permissions?.[R_EMPLOYEES]?.subModulePermissions?.[R_TIME_SHEET] || {};
export const rapMgmtPermissionSelector = state => state.permissions.permissions?.[R_RAP_MGMT]?.subModulePermissions?.[R_RAP_MGMT_LIST] || {};
export const rapMgmtApprenticePermissionSelector = state =>
  state.permissions.permissions?.[R_RAP_MGMT]?.subModulePermissions?.[R_RAP_MGMT_APPRENTICE] || {};
export const rapMgmtStatusPermissionSelector = state => state.permissions.permissions?.[R_RAP_MGMT]?.subModulePermissions?.[R_RAP_MGMT_STATUS] || {};
export const rapMgmtWageScalePermissionSelector = state =>
  state.permissions.permissions?.[R_RAP_MGMT]?.subModulePermissions?.[R_RAP_MGMT_WAGE_SCALE] || {};

//dynamic permission selector
export const subModPermissionSelector = (module, subModule) => state =>
  state.permissions.permissions?.[module]?.subModulePermissions?.[subModule] || {};

export default permissionSlice.reducer;
