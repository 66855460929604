import React from "react";
import { createBrowserRouter } from "react-router-dom";
import TypeSettingForm from "../Pages/Settings/TypeSetting/TypeSettingForm";
import TypeSettingList from "../Pages/Settings/TypeSetting/TypeSettingList";
import JobCategoryForm from "../Pages/Settings/JobCategory/JobCategoryForm";
import JobCategoryList from "../Pages/Settings/JobCategory/JobCategoryList";
import Login from "../Pages/Login/Login";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";
import Dashboard from "../Pages/Dashboard/Dashboard";
import PartnerList from "../Pages/Partners/PartnerList";
import PartnerDetail from "../Pages/Partners/PartnerDetail";
import JobTitleList from "../Pages/Settings/JobTitle/JobTitleList";
import JobTitleForm from "../Pages/Settings/JobTitle/JobTitleForm";
import EmployeeList from "../Pages/Employees/EmployeeList";
import EmployeeDetail from "../Pages/Employees/EmployeeDetail";
import ESignature from "../Pages/Employees/EmployeeDetail/ESignature";
import ProjectDetail from "../Pages/Projects/ProjectDetail";
import ProjectList from "../Pages/Projects/ProjectList";
import RapMgmtList from "../Pages/RapMgnt/RapMgmtList";
import RAPDetail from "../Pages/RapMgnt/RAPDetail";
import ProtectedRoute from "./ProtectedRoute";
import MyAccount from "../Pages/MyAccount";
import UserPermissions from "../Pages/UserPermissions/UserPermissions";
import UsersList from "../Pages/Settings/users/UsersList";
import UsersForm from "../Pages/Settings/users/UsersForm";
import Payroll from "../Pages/reports/Payroll";
import ValidateOtp from "../Pages/ValidateOtp/ValidateOtp";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";
import VerifyAccount from "../Pages/VerifyAccount";
import LeadSourceForm from "../Pages/Settings/LeadSubSource/LeadSourceForm";
import LeadSourceList from "../Pages/Settings/LeadSubSource/LeadSourceList";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />
  },
  {
    path: "/resetpassword-otp",
    element: <ValidateOtp />
  },
  {
    path: "/reset-password",
    element: <ResetPassword />
  },
  {
    path: "/dashboard",
    element: <ProtectedRoute Component={Dashboard} />
  },
  {
    path: "/partners",
    element: <ProtectedRoute Component={PartnerList} />
  },
  {
    path: "/partners/:id",
    element: <ProtectedRoute Component={PartnerDetail} />
  },
  {
    path: "/partners/:id/:tab",
    element: <ProtectedRoute Component={PartnerDetail} />
  },
  {
    path: "/settings/job-title/",
    element: <ProtectedRoute Component={JobTitleList} />
  },
  {
    path: "/settings/job-title/:id",
    element: <ProtectedRoute Component={JobTitleForm} />
  },
  {
    path: "/settings/lead-subsource-type/",
    element: <ProtectedRoute Component={LeadSourceList} />
  },
  {
    path: "/settings/lead-subsource-type/:id",
    element: <ProtectedRoute Component={LeadSourceForm} />
  },
  {
    path: "/settings/job-category/",
    element: <ProtectedRoute Component={JobCategoryList} />
  },
  {
    path: "/settings/job-category/:id",
    element: <ProtectedRoute Component={JobCategoryForm} />
  },
  {
    path: "/settings/users/",
    element: <ProtectedRoute Component={UsersList} />
  },
  {
    path: "/settings/users/:id",
    element: <ProtectedRoute Component={UsersForm} />
  },
  {
    path: "/settings/:settingType/",
    element: <ProtectedRoute Component={TypeSettingList} />
  },
  {
    path: "/settings/:settingType/:id",
    element: <ProtectedRoute Component={TypeSettingForm} />
  },
  {
    path: "/settings/user-permission",
    element: <ProtectedRoute Component={UserPermissions} />
  },
  {
    path: "/employees",
    element: <ProtectedRoute Component={EmployeeList} />
  },
  {
    path: "/employees/:id",
    element: <ProtectedRoute Component={EmployeeDetail} />
  },
  {
    path: "/employees/:id/:tab",
    element: <ProtectedRoute Component={EmployeeDetail} />
  },
  {
    path: "/employees/:id/:tab/add",
    element: <ProtectedRoute Component={ESignature} />
  },
  {
    path: "/projects",
    element: <ProtectedRoute Component={ProjectList} />
  },
  {
    path: "/projects/:id",
    element: <ProtectedRoute Component={ProjectDetail} />
  },
  {
    path: "/projects/:id/:tab",
    element: <ProtectedRoute Component={ProjectDetail} />
  },
  {
    path: "/rap",
    element: <ProtectedRoute Component={RapMgmtList} />
  },
  {
    path: "/rap/:id/:tab",
    element: <ProtectedRoute Component={RAPDetail} />
  },
  {
    path: "/myaccount",
    element: <ProtectedRoute Component={MyAccount} />
  },
  {
    path: "/reports/weekly-compliance-report",
    element: <ProtectedRoute Component={Payroll} />
  },
  {
    path: "/verifylink",
    element: <VerifyAccount />
  }
]);

export default router;
