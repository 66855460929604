import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleInitialize, loginSelector } from "../../store/features/auth/authSlice";
import Loading from "../Loading";
import { getAllPermissions } from "../../store/features/permissions/permissionsSlice";
import { connectSocket } from "../../../utils/webSocketConnection";
import PropTypes from "prop-types";

export default function AuthWrapper({ children }) {
  const dispatch = useDispatch();
  const { isMounted, isAuthenticated } = useSelector(loginSelector);

  useEffect(() => {
    dispatch(handleInitialize());
  }, [dispatch]);

  useEffect(() => {
    if (isMounted && isAuthenticated) {
      dispatch(getAllPermissions());
      connectSocket(dispatch);
    }
  }, [dispatch, isMounted, isAuthenticated]);

  if (!isMounted) return <Loading />;
  return children;
}

AuthWrapper.propTypes = {
  children: PropTypes.node 
};
