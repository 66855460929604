import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import DateField from "../../Components/Inputs/DateField";
import ProSelectField from "../../Components/Inputs/SelectField";
import FormSectionWrapper from "../../Components/ProForms/FormSectionWrapper";
import { fieldTruePermission, formatPayloadDate, prepareDefaultValues, prepareInitialConfig } from "../../../utils/formHelper";
import ProButton from "../../Components/ProButton";
import { useForm } from "react-hook-form";
import ReportsLayout from "../../Components/ProForms/ProFormsLayout/ReportsFormsLayout";
import { DEFAULT_SIZE } from "../../../utils/constant";
import { CircularProgress, Grid } from "@mui/material";
import OverAllSummaryGrid from "./ReportsDetails/OverAllSummaryGrid";
import SummaryGrid from "./ReportsDetails/SummaryGrid";
import WorkerDetailReport from "./ReportsDetails/WorkerDetailReport";
import dayjs from "dayjs";
import { GET_ALL_PROJECT, GET_ALL_WORKER_DETAIL_SUMMARY, GET_WORKER_DETAIL_REPORT } from "../../../utils/services/apiPath";
import { getApi } from "../../../utils/services";
import { errorToast } from "../../../utils/toastHelper";

const PayrollFormConfig = {
  weekEndingDate: {
    label: "Week Ending Date",
    defaultValue: null,
    perKey: "WeekEndingDate"
  },
  projectId: {
    label: "Select Project",
    defaultValue: "All"
  },
  employeeType: {
    label: "Select employeeType",
    defaultValue: "All"
  }
};

export default function Payroll() {
  const [formConfig] = useState(prepareInitialConfig(PayrollFormConfig, "edit"));
  const { handleSubmit, control, setValue, watch } = useForm({ defaultValues: prepareDefaultValues(PayrollFormConfig) });
  const defaultFormProps = { control };
  const [isSaving, setIsSaving] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [projectsList, setProjectsList] = useState([]);
  const [workerDetailSummary, setWorkerDetailSummary] = useState([]);
  const [workerDetailReports, setWorkerDetailReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const projectId = watch("projectId");
  const employeeType = watch("employeeType");
  const weekEndingDate = watch("weekEndingDate");

  const onSave = useCallback(async () => {
    setIsSaving(true);
    if (weekEndingDate && projectId) {
      const formattedDate = formatPayloadDate(weekEndingDate);
      setSelectedDate(formattedDate);
      try {
        const { data, error } = await getApi(`${GET_ALL_WORKER_DETAIL_SUMMARY}?weekEndingDate=${formattedDate}&projectId=${projectId}`);
        if (error) errorToast(error);
        const totalApprenticeHours = data.totalApprenticeHours ?? 0;
        const totalWorkingHours = data.totalWorkingHours ?? 0;
        const journeyToApprenticeRatio = totalWorkingHours ? ((totalApprenticeHours || 0) / totalWorkingHours) * 100 : 0;
        const summary = { ...data, journeyToApprenticeRatio };
        setWorkerDetailSummary(summary);
        setIsSaving(false);
      } catch (error) {
        console.log(error);
        setIsSaving(false);
      } finally {
        setLoading(false);
        setIsSaving(false);
      }
    }
  }, [weekEndingDate, projectId]);

  const disableDates = date => {
    return date.day() !== 0;
  };

  const fetchProjects = async () => {
    const { data } = await getApi(GET_ALL_PROJECT);
    const filteredData = data?.filter(project => project.projectId !== "All");
    setValue("projectId", filteredData[0]?.projectId);
    setProjectsList(filteredData?.map(item => ({ label: item.projectName, value: item.projectId })));
  };

  const getAllWorkerDetailReports = useCallback(async () => {
    setLoading1(true);
    const value = employeeType ?? "All";
    if (weekEndingDate && projectId && value) {
      const { data, error } = await getApi(
        `${GET_WORKER_DETAIL_REPORT}?weekEndingDate=${formatPayloadDate(weekEndingDate)}&projectId=${projectId}&employeeType=${value}`
      );
      if (error) errorToast(error);
      setWorkerDetailReports(data);
      setLoading1(false);
    }
  }, [employeeType, projectId, weekEndingDate]);

  useEffect(() => {
    getAllWorkerDetailReports();
  }, [getAllWorkerDetailReports]);

  useEffect(() => {
    onSave();
  }, [onSave]);

  useEffect(() => {
    const weekEndingDate = dayjs().day(0);
    setValue("weekEndingDate", weekEndingDate);
    setSelectedDate(formatPayloadDate(weekEndingDate));
    fetchProjects();
  }, []);

  return (
    <Layout>
      <ReportsLayout>
        <FormSectionWrapper>
          <DateField
            control={control}
            {...formConfig.weekEndingDate}
            size={DEFAULT_SIZE}
            shouldDisableDate={disableDates}
            permission={fieldTruePermission}
          />
          <ProSelectField
            {...defaultFormProps}
            value={projectsList[0]}
            {...formConfig.projectId}
            options={projectsList}
            permission={fieldTruePermission}
          />
          <ProButton isSaving={isSaving} variant="contained" sx={{ mt: 2, ml: 2 }} onClick={handleSubmit(onSave)}>
            {isSaving ? (
              <>
                <CircularProgress size={24} />
                <span style={{ marginLeft: 10 }}>Search</span>
              </>
            ) : (
              "Search"
            )}
          </ProButton>
        </FormSectionWrapper>
        <Grid item lg={12} md={12} xs={12}>
          <OverAllSummaryGrid workerDetailSummary={workerDetailSummary} loading={loading} />
        </Grid>
        <Grid item lg={12} md={12} xs={12} sx={{ mt: 2 }}>
          <SummaryGrid workerDetailSummary={workerDetailSummary} loading={loading} />
        </Grid>
        <Grid item lg={12} md={12} xs={12} sx={{ mt: 2 }}>
          <WorkerDetailReport
            workerDetailReports={workerDetailReports}
            selectedDate={selectedDate}
            projectId={projectId}
            employeeType={employeeType}
            defaultFormProps={defaultFormProps}
            formConfig={formConfig}
            loading={loading1}
          />
        </Grid>
      </ReportsLayout>
    </Layout>
  );
}
