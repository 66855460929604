import React, { useCallback, useEffect, useMemo, useState } from "react";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import ProTextInput from "../../../Components/Inputs/TextField";
import { useForm } from "react-hook-form";
import ProSelectField from "../../../Components/Inputs/SelectField";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import ProGrid from "../../../Components/ProGrid/v2";
import {
  ADD_PARTNERS_DOCUMENT,
  DELETE_PARTNERS_DOCUMENT,
  GET_ALL_DOCUMENTS_TYPES,
  GET_ALL_PARTNERS_DOCUMENTS,
  GET_PARTNER_DOCUMENT,
  UPDATE_PARTNERS_DOCUMENT
} from "../../../../utils/services/apiPath";
import { useParams } from "react-router";
import { changeMode, changeModeForField, convertFileToBase64Model, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { deleteApi, postApi } from "../../../../utils/services";
import usePageState from "../../../../utils/customHooks/usePageState";
import { partnersDocumentPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import useColumn from "../../../../utils/customHooks/useColumns";
import { defaultDropdownConfig } from "../../../../utils/dropdownHelper";
import { STAUTES } from "../../../../utils/constant";
import usePermission from "../../../../utils/customHooks/usePermission";
import ProFileUploadField from "../../../Components/Inputs/FileUploadField";
import { dateColumnDef } from "../../../Components/ProGrid/components/FormatCell";
import { partnerDocumentFormConfing } from "../PartnerData";

const requiredFormNames = ["Add Document", "Document List"];

export default function PartnerDocument() {
  let { id } = useParams();
  const [showGrid, setShowGrid] = useState(true);
  const [actionData, setActionData] = useState({});
  const [documentTypesData, setDocumentTypesData] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const { "Add Document": addDocumentPermission, "Document List": documentListPermission } = usePermission(
    partnersDocumentPermissionSelector,
    requiredFormNames
  );
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(partnerDocumentFormConfing));
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(partnerDocumentFormConfing) });
  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);

  const {
    currentPage,
    pageSize,
    data: documentsData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const columns = useColumn(
    [
      {
        field: "documentName",
        headerName: "Document Name",
        renderCell: params => <NameCell params={params} onClick={onDocumentClick} />
      },
      {
        field: "clientDocumentType",
        headerName: "Document Type"
      },
      {
        field: "createdByName",
        headerName: "Uploaded By"
      },
      dateColumnDef({
        field: "createdDate",
        headerName: "Uploaded Date"
      }),
      {
        field: "clientDocumentId",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell title={"Delete Partners Documents"} params={params} onDelete={onDelete} />
      }
    ],
    documentListPermission?.canDelete
  );

  const onEdit = () => {
    setFormConfig(changeMode("edit"));
  };

  const handleReadValueclick = name => {
    changeModeForField(name, "edit");
  };

  const resetForm = () => {
    setActionData({});
    setShowGrid(true);
    setFormConfig(changeMode());
  };

  const onCancel = () => {
    resetForm();
    setShowGrid(true);
  };

  const addButtonAction = () => {
    onEdit();
    Object.keys(formConfig).forEach(field => setValue(field, null));
    setShowGrid(false);
  };

  const onDocumentClick = params => {
    setShowGrid(false);
    setActionData({ ...params.row, documentFilePath: params.row.documentMediaModel.path });
    Object.keys(formConfig).forEach(field => setValue(field, params.row[field]));
  };

  const onSave = async data => {
    if (isSaving) return;
    setIsSaving(true);
    const base64ProfilePicture = await convertFileToBase64Model(data.documentFile);
    const payload = {
      ...data,
      clientId: id,
      documentMediaModel: base64ProfilePicture || null
    };
    const { clientDocumentId } = actionData;
    if (clientDocumentId) payload.clientDocumentId = clientDocumentId;
    const { error, data: partnerDocument } = await postApi(clientDocumentId ? UPDATE_PARTNERS_DOCUMENT : ADD_PARTNERS_DOCUMENT, payload);
    setIsSaving(false);
    if (error) return errorToast(error);
    getAllDocuments();
    getPartnerDocumentById(partnerDocument?.guidId);
    successToast(`Partners Documents ${clientDocumentId ? "updated" : "added"} successfully.`);
  };

  const getPartnerDocumentById = async id => {
    setFormConfig(changeMode("read"));
    const payload = {
      clientDocumentId: id
    };
    const { error, data: clientDocumentId } = await postApi(GET_PARTNER_DOCUMENT, payload);
    if (error) return errorToast(error);
    const data = {
      documentName: clientDocumentId?.documentName,
      clientDocumentTypeId: clientDocumentId?.clientDocumentTypeId,
      documentFile: clientDocumentId?.documentFile
    };
    setActionData(clientDocumentId);
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  };

  const onDelete = async params => {
    const { error } = await deleteApi(DELETE_PARTNERS_DOCUMENT, { data: [{ clientDocumentId: params }] });
    if (error) return errorToast(error);
    getAllDocuments();
    successToast("Partner Documents deleted successfully.");
  };

  const getAllDocuments = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: false,
      orderCol: "createdDate",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_PARTNERS_DOCUMENTS, payload, { params: { clientId: id } });
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const getAllDocumentType = async () => {
    const { data } = await postApi(GET_ALL_DOCUMENTS_TYPES, defaultDropdownConfig);
    setDocumentTypesData(data.map(item => ({ label: item.clientDocumentType, value: item.clientDocumentTypeId })));
  };

  useEffect(() => {
    getAllDocuments();
    getAllDocumentType();
  }, [getAllDocuments]);

  const defaultFormProps = { control, formValues: actionData, handleReadValueclick, isAddMode: !actionData.clientDocumentId };
  return (
    <TabGridAndFormLayout
      showGrid={showGrid}
      title={`${actionData.clientDocumentId ? "View" : "Add"} Partner Document`}
      backLabel="Go back to Partner Documents List"
      backAction={() => resetForm()}
      beingEdited={isEditModeOn}
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      hideEdit={true}
      canView={documentListPermission?.canView}
      isSaving={isSaving}
    >
      {showGrid && (
        <ProGrid
          title=" Partner Documents"
          columns={columns}
          loading={status === STAUTES.LOADING}
          rows={documentsData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
          addButtonLabel="+ Add Partner Document"
          addButtonAction={addButtonAction}
          hideAddButton={!documentListPermission?.canAdd}
          hideExport={!documentListPermission?.canExport}
          options={{
            getRowId: row => row.clientDocumentId,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
        />
      )}
      {!showGrid && (
        <>
          <ProTextInput
            {...defaultFormProps}
            {...formConfig.documentName}
            permission={{ ...addDocumentPermission.fieldPerObj[formConfig.documentName.perKey], canEdit: false }}
          />
          <ProSelectField
            {...defaultFormProps}
            {...formConfig.clientDocumentTypeId}
            options={documentTypesData}
            permission={{ ...addDocumentPermission.fieldPerObj[formConfig.clientDocumentTypeId.perKey], canEdit: false }}
          />
          <ProFileUploadField
            {...defaultFormProps}
            {...formConfig.documentFile}
            inputProps={{ accept: "file/*" }}
            permission={{ ...addDocumentPermission.fieldPerObj[formConfig.documentFile.perKey], canEdit: false }}
          />
        </>
      )}
    </TabGridAndFormLayout>
  );
}
