import React from "react";
import InputWrapper from "./InputWrapper";
import { TextField } from "@mui/material";
import ReadOnlyInput from "./ReadOnlyInput";
import { Controller } from "react-hook-form";
import { DEFAULT_SIZE } from "../../../utils/constant";
import PropTypes from "prop-types";
export default function ProTextInput({
  name,
  mode,
  label,
  rules,
  control,
  type = "text",
  fieldProps = {},
  size = DEFAULT_SIZE,
  startAdornment = null,
  isAddMode,
  disabled,
  ...rest
}) {
  const { canView = false, canAdd = false, canEdit = false } = rest.permission || {};
  if (!canView || (isAddMode && !canAdd)) return null;

  const editModeAllowed = isAddMode ? canAdd : canEdit;
  if (mode === "read" || (mode === "edit" && !editModeAllowed)) return <ReadOnlyInput type={type} size={size} name={name} label={label} {...rest} />;

  const handleKeyDown = e => {
    if (type === "number" && (e.key === "e" || e.key === "E")) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <InputWrapper size={size}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <TextField
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            label={label}
            disabled={disabled}
            variant="outlined"
            fullWidth
            type={type}
            placeholder={label}
            onKeyDown={handleKeyDown}
            InputProps={{ startAdornment }}
            InputLabelProps={{ shrink: true }}
            helperText={error?.message || null}
            error={!!error}
            {...fieldProps}
          />
        )}
      />
    </InputWrapper>
  );
}

ProTextInput.propTypes = {
  name: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["read", "edit"]).isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object.isRequired,
  startAdornment: PropTypes.node,
  control: PropTypes.object.isRequired,
  type: PropTypes.string,
  isAddMode: PropTypes.bool,
  fieldProps: PropTypes.object,
  permission: PropTypes.shape({
    canView: PropTypes.bool,
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool
  }),
  formValues: PropTypes.object,
  size: PropTypes.string,
  disabled: PropTypes.bool
};
