import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Tooltip, IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import ProGrid from "../../../Components/ProGrid/v2";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import ProSelectField from "../../../Components/Inputs/SelectField";
import ProTextInput from "../../../Components/Inputs/TextField";
import { STAUTES } from "../../../../utils/constant";
import usePageState from "../../../../utils/customHooks/usePageState";
import {
  GET_ALL_EMPLOYEE_DOCUMENTS,
  DELETE_EMPLOYEE_DOCUMENT,
  GET_ALL_EMPLOYEE_DOCUMENT_TYPE,
  ADD_EMPLOYEE_DOCUMENT,
  UPDATE_EMPLOYEE_DOCUMENT,
  GET_EMPLOYEE_DOCUMENTS_BY_ID
} from "../../../../utils/services/apiPath";
import { postApi, deleteApi } from "../../../../utils/services";
import {
  prepareDefaultValues,
  employeeDocumentsFormName,
  prepareInitialConfig,
  fieldTruePermission,
  convertFileToBase64Model,
  changeModeForField,
  changeMode
} from "../../../../utils/formHelper";
import { employeesDocumentsPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import usePermission from "../../../../utils/customHooks/usePermission";
import useColumn from "../../../../utils/customHooks/useColumns";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { defaultDropdownConfig } from "../../../../utils/dropdownHelper";
import FileUploadField from "../../../Components/Inputs/FileUploadField";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import { EmployeeDocumentsFormConfing } from "../EmployeeData";

export default function Documents() {
  let { id } = useParams();
  const [showGrid, setShowGrid] = useState(true);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(EmployeeDocumentsFormConfing));
  const [employeeDocumentData, setEmployeeDocumentData] = useState({});
  const [actionData, setActionData] = useState({});
  const [documentTypes, setDocumentTypes] = useState([]);
  const [isAddMode, setIsAddMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const {
    currentPage,
    pageSize,
    data: documentData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const { [employeeDocumentsFormName]: employees } = usePermission(employeesDocumentsPermissionSelector, employeeDocumentsFormName);

  const columns = useColumn([
    {
      field: "documentName",
      headerName: "Document Name",
      renderCell: params => <NameCell params={params} onClick={onDocumentClick} />
    },
    {
      field: "employeeDocumentType",
      headerName: "Document Type"
    },
    {
      field: "employeeDocumentId",
      headerName: "Actions",
      width: 160,
      renderCell: params => (
        <>
          <Tooltip title={"Download"} arrow>
            <IconButton onClick={() => handleDownload(params)} color="primary">
              <FileDownloadIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <DeleteCell params={params} onDelete={onDelete} />
        </>
      )
    }
  ]);

  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(EmployeeDocumentsFormConfing) });
  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);

  const onEdit = () => {
    setFormConfig(changeMode("edit"));
  };

  const handleReadValueclick = name => setFormConfig(changeModeForField(name, "edit"));

  const resetForm = () => {
    setActionData({});
    setShowGrid(true);
    setFormConfig(changeMode());
  };

  const onCancel = () => {
    if (actionData?.employeeDocumentId) {
      setFormConfig(changeMode("read"));
    } else {
      resetForm();
    }
  };

  const addButtonAction = () => {
    Object.keys(formConfig).forEach(field => setValue(field, null));
    onEdit();
    setShowGrid(false);
    setIsAddMode(true);
  };

  const onDocumentClick = params => {
    console.log(params.row);
    setShowGrid(false);
    setActionData({ ...params.row, documentMediaModel: params.row.documentMediaModel.path });
    getDocumentData(params.row);
  };

  const getAllDocuments = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "documentName",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_EMPLOYEE_DOCUMENTS, payload, { params: { employeeId: id } });
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const getAllDocumentTypes = async () => {
    const { data } = await postApi(GET_ALL_EMPLOYEE_DOCUMENT_TYPE, defaultDropdownConfig);
    setDocumentTypes(data.map(item => ({ label: item.employmentDocumentType, value: item.employmentDocumentTypeId })));
  };

  const getDocumentData = async updateData => {
    const data = {
      documentName: updateData?.documentName,
      employeeDocumentTypeId: updateData?.employeeDocumentTypeId,
      documentMediaModel: updateData?.documentMediaModel?.path
    };
    setEmployeeDocumentData(data);
    Object.keys(formConfig).forEach(field => setValue(field, field === "documentMediaModel" ? null : data[field]));
  };

  const handleDownload = params => {
    const file = params.row.documentMediaModel?.path;
    const link = document.createElement("a");
    link.href = file;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.click();
  };

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_EMPLOYEE_DOCUMENT, { data: [{ employeeDocumentId: id }] });
    if (error) return errorToast(error);
    getAllDocuments();
    successToast("Employee document deleted successfully.");
  };

  const onSave = async data => {
    if (isSaving) return; 
    setIsSaving(true);
    const base64ProfilePicture = await convertFileToBase64Model(data.documentMediaModel);
    const payload = {
      employeeDocumentTypeId: data?.employeeDocumentTypeId,
      employeeId: id,
      documentName: data?.documentName,
      documentMediaModel: base64ProfilePicture || null,
      employeeDocumentId: actionData?.employeeDocumentId
    };
    const { data: employeeDocument, error } = await postApi(actionData?.employeeDocumentId ? UPDATE_EMPLOYEE_DOCUMENT : ADD_EMPLOYEE_DOCUMENT, payload);
    setIsSaving(false); 
    if (error) return errorToast(error);
    getDocumentById(employeeDocument?.guidId || actionData?.employeeDocumentId);
    getAllDocuments();
    successToast(`Employee Document ${actionData?.employeeDocumentId ? "Updated" : "Added"} Successfully`);
    setFormConfig(changeMode("read"));
  };

  const getDocumentById = async id => {
    const payload = { employeeDocumentId: id };
    const { error, data: employeeDocumentById } = await postApi(GET_EMPLOYEE_DOCUMENTS_BY_ID, payload);
    if (error) return errorToast(error);
    const data = {
      documentName: employeeDocumentById?.documentName,
      employeeDocumentTypeId: employeeDocumentById?.employeeDocumentTypeId,
      documentMediaModel: employeeDocumentById?.documentMediaModel?.path
    };
    setActionData(employeeDocumentById);
    setIsAddMode(false);
    setEmployeeDocumentData(data);
    Object.keys(formConfig).forEach(field => setValue(field, field === "documentMediaModel" ? null : data[field]));
  };

  useEffect(() => {
    getAllDocuments();
    getAllDocumentTypes();
  }, [getAllDocuments]);

  const defaultFormProps = { control, formValues: employeeDocumentData, isAddMode, handleReadValueclick };

  return (
    <TabGridAndFormLayout
      showGrid={showGrid}
      title={"Document"}
      backAction={() => resetForm()}
      backLabel="Go back to Documents List"
      beingEdited={isEditModeOn}
      onEdit={onEdit}
      canView={true}
      hideEdit={true}
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      isSaving={isSaving}
    >
      {showGrid && (
        <ProGrid
          title="Documents"
          loading={status === STAUTES.LOADING}
          options={{
            getRowId: row => row.employeeDocumentId,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
          columns={columns}
          hideExport={!employees.canExport}
          addButtonLabel="+ Add Document"
          rows={documentData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
          addButtonAction={addButtonAction}
        />
      )}
      {!showGrid && (
        <>
          <ProTextInput
            size={4}
            {...defaultFormProps}
            {...formConfig.documentName}
            permission={fieldTruePermission}
            
          />
          <ProSelectField
            {...defaultFormProps}
            {...formConfig.employeeDocumentTypeId}
            options={documentTypes}
            permission={fieldTruePermission}
          />
          <FileUploadField
            {...defaultFormProps}
            {...formConfig.documentMediaModel}
            inputProps={{ accept: "file/*" }}
            permission={fieldTruePermission}
          />
        </>
      )}
    </TabGridAndFormLayout>
  );                                                                             
}
