import React, { useCallback, useEffect } from "react";
import "../../../Components/ProGrid/components/DeleteCell.scss";
import Layout from "../../../Components/Layout";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import ProGrid from "../../../Components/ProGrid/v2";
import { useNavigate } from "react-router-dom";
import usePageState from "../../../../utils/customHooks/usePageState";
import { deleteApi, postApi } from "../../../../utils/services";
import { DELETE_USER, GET_ALL_USERS, RESEND_VERIFYLINK_USER } from "../../../../utils/services/apiPath";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { STAUTES } from "../../../../utils/constant";
import { subModPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import usePermission from "../../../../utils/customHooks/usePermission";
import { R_SETTINGS, R_USERS } from "../../../../utils/permissionReferenceConfig";
import { listUserFormName } from "../../../../utils/formHelper";
import useColumn from "../../../../utils/customHooks/useColumns";
import { useSelector } from "react-redux";
import { userSelector } from "../../../store/features/auth/authSlice";
import EmailCell from "../../../Components/ProGrid/components/EmailCell";

const getRedirectUrl = params => {
  return `/settings/users/${params.id}`;
};

export default function UsersList() {
  const { clientGuid } = useSelector(userSelector);
  const navigation = useNavigate();
  const {
    currentPage,
    pageSize,
    data: usersData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const { [listUserFormName]: listPermission } = usePermission(subModPermissionSelector(R_SETTINGS, R_USERS), listUserFormName);
  const { canView, canAdd, canExport, canDelete } = listPermission;

  const columns = useColumn(
    [
      {
        field: "firstName",
        headerName: "First Name",
        renderCell: params => <NameCell params={params} getRedirectUrl={getRedirectUrl} />
      },
      {
        field: "lastName",
        headerName: "Last Name"
      },
      {
        field: "email",
        headerName: "Email"
      },
      {
        field: "phone",
        headerName: "Phone"
      },
      {
        field: "userName",
        headerName: "User Name"
      },
      {
        field: "roleTypeName",
        headerName: "Role"
      },
      {
        field: "isVerified",
        headerName: "Is Verified",
        width: 100,
        align: "center",
        renderCell: params => {
          const fillColor = params.row.isVerified ? "green" : "red";
          const title = params.row.isVerified ? "Email Verified" : "Email is Pending";
          return <EmailCell fill={fillColor} params={params} onSend={onSend} title={title} />;
        }
      },
      {
        field: "clientUserId",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell params={params} onDelete={onDelete} />
      }
    ],
    canDelete
  );

  const addButtonAction = () => {
    navigation("/settings/users/add");
  };

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_USER, { data: [{ clientUserId: id }] });
    if (error) return errorToast(error);
    getUsers();
    successToast("User deleted successfully.");
  };

  const getUsers = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "firstName",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(`${GET_ALL_USERS}/${clientGuid}`, payload);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const onSend = async id => {
    const { error } = await postApi(RESEND_VERIFYLINK_USER, {}, { params: { ClientUserId: id } });
    if (error) return errorToast(error);
    getUsers();
    successToast("Email sent successfully.");
  };

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <Layout canView={canView}>
      <ProGrid
        title="Users"
        columns={columns}
        loading={status === STAUTES.LOADING}
        rows={usersData}
        searchMode={!!searchString}
        searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
        addButtonLabel="+ Add User"
        addButtonAction={addButtonAction}
        hideAddButton={!canAdd}
        hideExport={!canExport}
        options={{
          getRowId: row => row.clientUserId,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          sortingMode: "server",
          onSortModelChange: setSortModel
        }}
      />
    </Layout>
  );
}
