import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import ProGrid from "../../../Components/ProGrid/v2";
import { STAUTES } from "../../../../utils/constant";
import { errorToast } from "../../../../utils/toastHelper";
import usePageState from "../../../../utils/customHooks/usePageState";
import { GET_ALL_TIMESHEETS } from "../../../../utils/services/apiPath";
import { postApi } from "../../../../utils/services";
import { employeeTimeSheetFormName } from "../../../../utils/formHelper";
import { employeesTimeSheetPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import usePermission from "../../../../utils/customHooks/usePermission";
import useColumn from "../../../../utils/customHooks/useColumns";

export default function EmployeeTimeSheets() {
  let { id } = useParams();
  const [showGrid] = useState(true);
  const {
    currentPage,
    pageSize,
    data: timeSheetData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const { [employeeTimeSheetFormName]: timeSheetPermission } = usePermission(employeesTimeSheetPermissionSelector, employeeTimeSheetFormName);

  const getAllTimeSheet = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: false,
      orderCol: "workedDate",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_TIMESHEETS, payload, { params: { employeeId: id } });
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    const dataWithIds = data?.map((item, index) => ({
      ...item,
      id: index + 1 + (currentPage * pageSize)
    }));
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: dataWithIds || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    getAllTimeSheet();
  }, [getAllTimeSheet]);

  const columns = useColumn([
    {
      field: "projectName",
      headerName: "Project Name"
    },
    {
      field: "jobTitle",
      headerName: "Position"
    },
    {
      field: "totalStandardHours",
      headerName: "Total Standard Hours"
    },
    {
      field: "totalOTHours",
      headerName: "Total OT Hours"
    },
    {
      field: "standardPayRate",
      headerName: "Standard Pay Rate"
    },
    {
      field: "otPayRate",
      headerName: "OT Pay Rate"
    },
    {
      field: "perDiem",
      headerName: "PerDiem"
    },
    {
      field: "workedDate",
      headerName: "Worked Date"
    }
  ]);

  return (
    <TabGridAndFormLayout showGrid={showGrid} title={"Timesheets"} canView={timeSheetPermission.canView}>
      {showGrid && (
        <ProGrid
          options={{
            getRowId: row => row?.id,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
          title="Timesheets"
          loading={status === STAUTES.LOADING}
          columns={columns}
          hideAddButton={true}
          hideExport={!timeSheetPermission.canExport}
          rows={timeSheetData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
        />
      )}
    </TabGridAndFormLayout>
  );
}
