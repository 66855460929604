import { createSlice } from "@reduxjs/toolkit";
import { STAUTES } from "../../../../utils/constant";
import { GET_ALL_PARTNERS } from "../../../../utils/services/apiPath";
import { postApi } from "../../../../utils/services";

const initialState = {
  data: [],
  status: STAUTES.IDLE
};

export const getAllPartners = payload => {
  return async dispatch => {
    dispatch(SET_STATUS(STAUTES.LOADING));
    try {
      const response = await postApi(GET_ALL_PARTNERS, { payload });
      dispatch(PARTNERS(response));
      dispatch(SET_STATUS(STAUTES.IDLE));
    } catch (error) {
      dispatch(SET_STATUS(STAUTES.ERROR));
    }
  };
};

export const partnersSlice = createSlice({
  name: "partners",
  initialState,
  reducers: {
    PARTNERS: (state, action) => {
      state.data = action.payload;
    },
    SET_STATUS: (state, action) => {
      state.status = action.payload;
    }
  }
});

export const { PARTNERS, SET_STATUS } = partnersSlice.actions;

export const partnersSelector = state => state.partners;

export default partnersSlice.reducer;
