import React from "react";
import { Grid, Box, Modal, Autocomplete, InputLabel, TextField, InputAdornment, IconButton, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { RenderContent } from "./RenderContent";
import ProButton from "../../ProButton";
import ReplyIcon from "@mui/icons-material/Reply";

export const Pwc = ({
  renderData,
  searchInput,
  jobSelection,
  jobTitleOptionsArray,
  open,
  handleClose,
  handleChatbotSubmit,
  resetPWC,
  additionalRateQuestion,
  setAnswerString,
  ansAdditionalRateQuestion,
  isQueValidate
}) => {
  const getJobTitleComma = jobTitleList => {
    let stringJob = "";
    jobTitleList?.map((job, index) => {
      if (index === 0) stringJob = job;
      else stringJob = `${stringJob}, ${job}`;
    });
    return stringJob;
  };
  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition sx={{ padding: "5px" }}>
      <Box sx={styles.container}>
        <Box style={styles.header}>
          <Box></Box>
        </Box>
        <Box
          sx={{
            p: 3,
            align: "center",
            display: "flex",
            flexDirection: "column",
            height: 550,
            overflow: "hidden",
            overflowY: "scroll"
          }}
        >
          <Box>
            <div className={searchInput?.values?.isExternalJobTitle ? "pwc_section_top_3" : "pwc_section_top_2"}>
              <div className="pwc-top" style={{ backgroundColor: "#E6F5F9" }}>
                <div className="pwc-top_right">
                  <h5>State</h5>
                  <h4>{searchInput.wsPayload.state}</h4>
                </div>
              </div>
              <div className="pwc-top" style={{ backgroundColor: "#E6F5F9" }}>
                <div className="pwc-top_right">
                  <h5>County</h5>
                  <h4>{searchInput.wsPayload.county}</h4>
                </div>
              </div>
              <div className="pwc-top" style={{ backgroundColor: "#E6F5F9" }}>
                <div className="pwc-top_right">
                  <h5>Category</h5>
                  <h4>{searchInput.constructionType}</h4>
                </div>
              </div>
              {searchInput?.values?.isExternalJobTitle && (
                <>
                  {searchInput.operatorPW && (
                    <div className="pwc-top" style={{ backgroundColor: "#E6F5F9" }}>
                      <div className="pwc-top_right">
                        <h5>Operator</h5>
                        <h4>{searchInput.operatorPW}</h4>
                      </div>
                    </div>
                  )}
                  {searchInput.responsibleUpkeep !== "" && (
                    <div className="pwc-top" style={{ backgroundColor: "#E6F5F9" }}>
                      <div className="pwc-top_right">
                        <h5>Responsible for Unkeep</h5>
                        <h4>{searchInput.responsibleUpkeep}</h4>
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className="pwc-top" style={{ backgroundColor: "#E6F5F9" }}>
                <div className="pwc-top_right">
                  <h5>{searchInput?.values?.isExternalJobTitle ? "External Job Title" : "Job Title"}</h5>
                  <h4>{searchInput.jobTitlePW}</h4>
                </div>
              </div>
            </div>

            <div
              className={
                searchInput?.values?.jobDescription && (searchInput?.values?.typeOfEquipment || searchInput?.values?.typeOfJob)
                  ? "pwc_section_top_2"
                  : "pwc_section_top_1"
              }
              style={{ marginTop: "5px" }}
            >
              {(searchInput?.values?.typeOfEquipment || searchInput?.values?.typeOfJob) && (
                <div className="pwc-top" style={{ backgroundColor: "#E6F5F9" }}>
                  <div className="pwc-top_right">
                    <h5>Job Family Details</h5>
                    <h4>
                      {getJobTitleComma(searchInput?.values?.isOperator ? searchInput?.values?.typeOfEquipment : searchInput?.values?.typeOfJob)}
                    </h4>
                  </div>
                </div>
              )}
              {searchInput?.values?.jobDescription && (
                <div className="pwc-top" style={{ backgroundColor: "#E6F5F9" }}>
                  <div className="pwc-top_right">
                    <h5>Job Description</h5>
                    <h4>{searchInput?.values?.jobDescription}</h4>
                  </div>
                </div>
              )}
            </div>
          </Box>
          {jobTitleOptionsArray?.length > 0 && (
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                {jobTitleOptionsArray?.map((field, index) => (
                  <Grid item xs={12} md={12} key={Number(index)}>
                    <Autocomplete
                      id={index}
                      name={index}
                      options={field?.jobTitleOptions || []}
                      getOptionLabel={option => option.label}
                      getOptionValue={option => option.value}
                      value={field.jobTitle}
                      readOnly={jobTitleOptionsArray?.length !== index + 1 || renderData.chatBotRates}
                      onChange={(event, newValue) => jobSelection(index, field, newValue)}
                      renderInput={params => (
                        <>
                          <InputLabel shrink sx={{ wordBreak: "break-word", whiteSpace: "normal", overflow: "visible", width: "100%" }}>
                            {`${index + 1}. ${field.jobTitleLabel}`}
                          </InputLabel>
                          <TextField {...params} variant="outlined" fullWidth />
                        </>
                      )}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
          {additionalRateQuestion?.length > 0 && (
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                {additionalRateQuestion?.map((question, index) => (
                  <Grid item xs={12} md={12} key={index + 1}>
                    <InputLabel shrink sx={{ wordBreak: "break-word", whiteSpace: "normal", overflow: "visible", width: "100%" }}>
                      {`${index + 1}. ${question.question}`}
                    </InputLabel>
                    <TextField
                      fullWidth
                      label="Answer"
                      id="fullWidth"
                      type="number"
                      disabled={additionalRateQuestion?.length !== index + 1 || renderData.chatBotRates}
                      onChange={event => {
                        setAnswerString(event.target.value);
                      }}
                      InputProps={{
                        endAdornment: additionalRateQuestion?.length - 1 === index && (
                          <InputAdornment position="end" className="icon-hor-vert">
                            <IconButton onClick={ansAdditionalRateQuestion} edge="end">
                              <ReplyIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    {isQueValidate && additionalRateQuestion?.length - 1 === index && (
                      <Typography sx={{ fontSize: "12px !important", color: "#d32f2f" }}>{"Please answer the question"}</Typography>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
          <RenderContent
            isWagesLoader={renderData.isWagesLoader}
            chatBotFringes={renderData.chatBotFringes}
            chatBotRates={renderData.chatBotRates}
            isNoStateOrCountyData={renderData.isNoStateOrCountyData}
            errorMsg={renderData.errorMsg}
            footNotes={renderData.footNotes}
            chatBotMatchScore={renderData.chatBotMatchScore}
          />
        </Box>
        <Box sx={{ textAlign: "center", pt: 2 }}>
          <ProButton variant="contained" disabled={!renderData.chatBotRates} sx={{ marginRight: "10px" }} onClick={handleChatbotSubmit}>
            Get Rates
          </ProButton>
          <ProButton className="cancel-btn" variant="outlined" color="secondary" sx={{ marginRight: "10px" }} onClick={handleClose}>
            Cancel
          </ProButton>
          <ProButton className="cancel-btn" variant="outlined" color="secondary" sx={{ marginRight: "10px" }} onClick={resetPWC}>
            Reset
          </ProButton>
        </Box>
      </Box>
    </Modal>
  );
};
Pwc.propTypes = {
  renderData: PropTypes.object,
  searchInput: PropTypes.object,
  jobSelection: PropTypes.func.isRequired,
  jobTitleOptionsArray: PropTypes.array,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleChatbotSubmit: PropTypes.func.isRequired,
  resetPWC: PropTypes.func.isRequired,
  additionalRateQuestion: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.string]).isRequired,
  setAnswerString: PropTypes.any.isRequired,
  isQueValidate: PropTypes.any.isRequired,
  ansAdditionalRateQuestion: PropTypes.any.isRequired
};

const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: ["100%", "75%", "50%", "50%"],
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "8px 8px 30px ",
    borderRadius: "10px"
  },
  header: {
    borderRadius: "10px",
    margin: "-6px",
    padding: "25px",
    background: "linear-gradient(140deg, #9bc7e6 0%, #4949b9 50%)",
    paddingTop: "10px",
    marginBottom: "2px"
  }
};
