import React from "react";
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import { PieChart } from "@mui/x-charts/PieChart";
import PropTypes from "prop-types";

const WorkforceHoursChart = ({ tradeHours, apprenticeHours, nonTradeHours, workingHours }) => {
  const data = [
    { label: "Craft/Trade Labor", value: ((tradeHours / workingHours) * 100).toFixed(2), color: "#FF033E" },
    { label: "Apprentice", value: ((apprenticeHours / workingHours) * 100).toFixed(2), color: "#FFC72C" },
    { label: "Non-Trade/Craft", value: ((nonTradeHours / workingHours) * 100).toFixed(2), color: "#018749" }
  ];
  const getArcLabel = params => {
    return params.value !== 0 ? `${params.value}%` : "";
  };

  const pieChartsParams = {
    series: [
      {
        innerRadius: 120,
        outerRadius: 0,
        data,
        arcLabel: getArcLabel,
        highlightScope: { faded: 'global', highlighted: 'item' },
        faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
      }
    ]
  };
  return (
    <Grid
      sx={{
        py: 2,
        display: "flex",
        alignItems: "center",
        height: "100%"
      }}
    >
      <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
        <PieChart
          {...pieChartsParams}
          alignItems="center"
          margin={{ top: 0, right: 10 }}
          width={400}
          height={272}
          slotProps={{
            legend: { hidden: true }
          }}
          series={pieChartsParams.series.map(serie => ({
            ...serie,
            valueFormatter: values => `${values.value}%`
          }))}
        />
      </Stack>
    </Grid>
  );
};

WorkforceHoursChart.propTypes = {
  tradeHours: PropTypes.number.isRequired,
  apprenticeHours: PropTypes.number.isRequired,
  nonTradeHours: PropTypes.number.isRequired,
  workingHours: PropTypes.number.isRequired
};

export default WorkforceHoursChart;
