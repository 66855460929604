import React, { useState } from "react";
import "./index.scss";
import { Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PropTypes from "prop-types";

export default function FormSectionWrapper({ title, children, collapsible = false, defaultClose = false }) {
  const [close, setClose] = useState(defaultClose);
  const actualClose = collapsible && close;
  return (
    <div className={`proFsw ${actualClose ? "close" : ""}`}>
    <button type="button" className="proFsw-title" onClick={() => setClose(!close)} style={{ border: "none" }}>
      {title}
    </button>
    {collapsible && (
      <button type="button" className="proFsw-close" onClick={() => setClose(!close)}>
        {actualClose ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </button>
    )}

    <div className="proFsw-content">
      <Grid container spacing={2}>
        {children}
      </Grid>
    </div>
  </div>
  );
}

FormSectionWrapper.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  collapsible: PropTypes.bool,
  defaultClose: PropTypes.bool
};

