import React, { useCallback, useEffect, useMemo, useState } from "react";
import { fieldTruePermission, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import usePageState from "../../../../utils/customHooks/usePageState";
import useColumn from "../../../../utils/customHooks/useColumns";
import { useParams } from "react-router-dom";
import ProSelectField from "../../../Components/Inputs/SelectField";
import ProTextInput from "../../../Components/Inputs/TextField";
import EmailField from "../../../Components/Inputs/EmailField";
import MobileField from "../../../Components/Inputs/MobileField";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import FormGridInsideSectionLayout from "../../../Components/ProForms/ProFormsLayout/FormGridInsideSectionLayout.jsx";
import {
  ADD_PROJECT_CONTACT_INFO,
  DELETE_PROJECT_CONTACT_INFO,
  DYNAMIC_DROPDOWN,
  GET_ALL_PROJECT_CONTACT_INFO,
  UPDATE_PROJECT_CONTACT_INFO
} from "../../../../utils/services/apiPath";
import { deleteApi, postApi } from "../../../../utils/services";
import { STAUTES } from "../../../../utils/constant";
import ProGrid from "../../../Components/ProGrid/v2";
import { errorToast, successToast } from "../../../../utils/toastHelper";

const projectContactFormConfing = {
  contactTypeId: {
    label: "Contact Type",
    // perKey: "contactType",
    rules: { required: "Required!" }
  },
  contactFirstName: {
    label: "First Name",
    // perKey: "FirstName",
    rules: { required: "Required!" }
  },
  contactLastName: {
    label: "Last Name",
    // perKey: "LastName",
    rules: { required: "Required!" }
  },
  contactEmail: {
    label: "Email",
    // perKey: "ContactEmail",
    rules: { required: "Required!" }
  },
  contactPhone: {
    label: "Contact Phone",
    // perKey: "ContactPhone",
    rules: {
      required: "Required!"
    }
  },
  contactTitle: {
    label: "Contact Title",
    // perKey: "ContactTitle",
    rules: { required: "Required!" }
  }
};

export default function ProjectContactInfo({ globalEditMode }) {
  let { id } = useParams();
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(projectContactFormConfing));
  const [contactTypesData, setContactTypesData] = useState([]);
  const [actionData, setActionData] = useState({});
  const [projectContactInfoData] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const {
    currentPage,
    pageSize,
    data: contactsData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
  } = usePageState();

  const onDelete = async projectInfoContactsId => {
    const { error } = await deleteApi(`${DELETE_PROJECT_CONTACT_INFO}?projectId=${id}`, {
      data: [{ projectInfoContactsId: projectInfoContactsId }]
    });
    if (error) return errorToast(error);
    getAllProjectContacts();

    successToast("Project Contact deleted successfully.");
  };

  useEffect(() => {
    if (globalEditMode) onEdit();
    else resetForm();
  }, [globalEditMode]);

  const columns = useColumn(
    [
      {
        field: "contactFullName",
        headerName: "Full Name",
        renderCell: params => <NameCell params={params} onClick={() => handleEditRow(params.row)} />
      },
      {
        field: "contactTitle",
        headerName: "Job Title"
      },

      {
        field: "contactType",
        headerName: "Contact Type"
      },
      {
        field: "contactPhone",
        headerName: "Contact Phone"
      },
      {
        field: "contactEmail",
        headerName: "Contact Email"
      },
      {
        field: "projectInfoContactsId",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell title={"Delete Project Info Contact"} params={params} onDelete={onDelete} />
      }
    ],
    true
  );

  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(projectContactFormConfing) });
  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);

  const handleReadValueclick = name => {
    setFormConfig(prev => ({ ...prev, [name]: { ...prev[name], mode: "edit" } }));
  };

  const defaultFormProps = { control, formValues: projectContactInfoData, handleReadValueclick };

  const handleAddUpdateRow = async data => {
    if (isSaving) return; 
    setIsSaving(true);
    const payload = {
      projectId: id,
      contactTypeId: data.contactTypeId,
      contactFirstName: data.contactFirstName,
      contactLastName: data.contactLastName,
      contactEmail: data?.contactEmail,
      contactPhone: data.contactPhone,
      contactTitle: data.contactTitle,
      projectInfoContactsId: actionData?.projectInfoContactsId || null
    };

    const { error } = await postApi(actionData?.projectInfoContactsId ? UPDATE_PROJECT_CONTACT_INFO : ADD_PROJECT_CONTACT_INFO, payload);
    setIsSaving(false); 
    if (error) return errorToast(error);
    getAllProjectContacts();
    resetForm();
    successToast(`Project Partner ${!actionData?.projectInfoContactsId ? "Added" : "Updated"} Successfully`);
  };

  const handleEditRow = async data => {
    setActionData(data);
    if (data) {
      setValue("contactTypeId", data.contactTypeId);
      setValue("contactFirstName", data.contactFirstName);
      setValue("contactLastName", data.contactLastName);
      setValue("contactEmail", data.contactEmail);
      setValue("contactPhone", data.contactPhone);
      setValue("contactTitle", data.contactTitle);
    }
    onEdit()
  };

  const onEdit = () => {
    setFormConfig(prev => Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: { ...prev[key], mode: "edit" } }), {}));
  };

  const resetForm = () => {
    setActionData({});
    setFormConfig(prev => Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: { ...prev[key], mode: "read" } }), {}));
    Object.keys(formConfig).forEach(field => setValue(field, null));
  };

  const cancelAddUpdateRow = () => {
    resetForm();
  };

  const getAllProjectContacts = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_PROJECT_CONTACT_INFO, payload, { params: { projectId: id } });
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const getAllContactType = async () => {
    const payload = ["contactType"];
    const data = await postApi(DYNAMIC_DROPDOWN, payload);
    setContactTypesData(data[0]?.typeDropDowns?.map(item => ({ label: item.label, value: item.intValue })));
  };

  useEffect(() => {
    getAllProjectContacts();
    getAllContactType();
  }, [getAllProjectContacts]);

  return (
    <div className="proFormGridInsideSectionLayout-wrapper">
      <FormGridInsideSectionLayout
        showGrid={true}
        beingEdited={isEditModeOn}
        onEdit={onEdit}
        onCancel={cancelAddUpdateRow}
        onSave={handleSubmit(handleAddUpdateRow)}
        hideEdit={true}
        isSaving={isSaving}
      >
        <Grid container spacing={2}>
          <ProSelectField {...defaultFormProps} {...formConfig.contactTypeId} options={contactTypesData} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.contactFirstName} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.contactLastName} permission={fieldTruePermission} />
          <EmailField {...defaultFormProps} {...formConfig.contactEmail} permission={fieldTruePermission} />
          <MobileField {...defaultFormProps} {...formConfig.contactPhone} permission={fieldTruePermission} />
          <ProTextInput size={4} {...defaultFormProps} {...formConfig.contactTitle} permission={fieldTruePermission} />
        </Grid>
      </FormGridInsideSectionLayout>
      <ProGrid
        loading={status === STAUTES.LOADING}
        options={{
          getRowId: row => row.projectInfoContactsId,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
        }}
        hideIfNoData={true}
        hideSearch={true}
        columns={columns}
        hideExport
        hideAddButton={true}
        rows={contactsData || []}
      />
    </div>
  );
}


ProjectContactInfo.propTypes = {
  globalEditMode: PropTypes.object
};