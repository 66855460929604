import React from "react";
import PropTypes from "prop-types";
import { TableRow, TableCell, Collapse, Box, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CollapsedGrid from "../../../Components/ProGrid/CollapsedGrid";

function ProjectCertifiedPayrollTableRow({
  row,
  expandedRowId,
  setExpandedRowId,
  permission,
  currencyCell,
  rowData,
  colValue,
  setColValue,
  setIsAssigned,
  status,
  handleStatus,
  activeDailySheet,
  handleDailyTimeSheet,
  handleDailyPayroll,
  handleDailyCertifiedPayroll,
  columns,
  searchString,
  setSearchString,
  rowCount,
  pageSize,
  setPagination,
  currentPage,
  setSortModel,
  tokenData
}) {
  let isRowExpanded = row.id === expandedRowId;

  const handleRowClick = val => {
    if (colValue === val) {
      setExpandedRowId(isRowExpanded ? null : row?.id);
      setColValue(val);
    } else {
      setColValue(val);
      setExpandedRowId(row.id);
    }
  };

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          {row.weekEndingDate}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.clientName}
        </TableCell>
        {permission && (
          <TableCell align="center">
            <div className="certified-payroll_cell">
              {row?.isTimesheetException === true && (
                <button
                  className="certified-payroll_cell_button"
                  onClick={() => {
                    handleRowClick(1);
                    handleDailyTimeSheet(row, false, 1);
                    setIsAssigned(false);
                    setSortModel({});
                  }}
                >
                  Exception
                </button>
              )}
              {row?.isTimesheetException && "/"}
              <button
                className="certified-payroll_cell_button"
                onClick={() => {
                  handleRowClick(1);
                  handleDailyTimeSheet(row, true, 1);
                  setIsAssigned(false);
                  setSortModel({});
                }}
              >
                {row.totalHours} Hours
              </button>
            </div>
          </TableCell>
        )}
        {permission && (
          <TableCell align="center">
            <div className="certified-payroll_cell">
              {row?.isPayrollSummaryException && (
                <button
                  className="certified-payroll_cell_button"
                  onClick={() => {
                    handleDailyPayroll(row, false, 1);
                    handleRowClick(2);
                    setIsAssigned(false);
                    setSortModel({});
                  }}
                >
                  Exception/
                </button>
              )}

              <button
                className="certified-payroll_cell_button"
                onClick={() => {
                  handleDailyPayroll(row, true, 1);
                  handleRowClick(2);
                  setIsAssigned(false);
                  setSortModel({});
                }}
              >
                {currencyCell(row?.totalAmount)}
              </button>
            </div>
          </TableCell>
        )}
        {permission && (
          <TableCell align="left">
            <div className="certified-payroll_cell">
              {row?.isCertifiedPayrollSigned && (
                <button
                  className="certified-payroll_cell_button"
                  onClick={() => {
                    handleDailyCertifiedPayroll(row, true, 1);
                    handleRowClick(3);
                    setIsAssigned(false);
                    setSortModel({});
                  }}
                >
                  Signed
                </button>
              )}
              {row?.isCertifiedPayrollSigned && row?.isCertifiedPayrollNotSigned && "/"}
              {row?.isCertifiedPayrollNotSigned && (
                <button
                  className="certified-payroll_cell_button button1"
                  onClick={() => {
                    handleDailyCertifiedPayroll(row, false, 1);
                    handleRowClick(3);
                    setIsAssigned(false);
                    setSortModel({});
                  }}
                >
                  Not Signed
                </button>
              )}
              {row?.isManual && row?.isCertifiedPayrollNotSigned && "/"}
              {row?.isManual && (
                <Box sx={{ display: "flex" }}>
                  <button
                    className="certified-payroll_cell_button button1"
                    onClick={() => {
                      handleDailyCertifiedPayroll(row, false, 1);
                      handleRowClick(3);
                      setIsAssigned(true);
                      setSortModel({});
                    }}
                  >
                    Uploaded
                  </button>
                  <Box sx={{ pl: 2 }}>
                    <Tooltip
                      placement="bottom"
                      title={`This Certified Payroll Report was generated outside of the ProScore Partner Portal by ${tokenData?.GlobalClientName} client and that ${tokenData?.GlobalClientName} Client is solely responsible for its contents and accuracy`}
                      arrow
                    >
                      <InfoIcon />
                    </Tooltip>
                  </Box>
                </Box>
              )}
            </div>
          </TableCell>
        )}
      </TableRow>
      {isRowExpanded && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
            <Collapse in={isRowExpanded} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                {rowData ? (
                  <CollapsedGrid
                    title={`${activeDailySheet.name} - ${activeDailySheet.date}`}
                    columns={columns}
                    loading={status}
                    hideAddButton
                    hideSearch={true}
                    rows={rowData}
                    searchMode={!!searchString}
                    searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
                    options={{
                      getRowId: row => row.id,
                      rowCount: rowCount,
                      paginationMode: "server",
                      paginationModel: { pageSize: pageSize, page: currentPage },
                      onPaginationModelChange: setPagination,
                      sortingMode: "server",
                      onSortModelChange: setSortModel
                    }}
                  />
                ) : (
                  <>{handleStatus()}</>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

ProjectCertifiedPayrollTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  expandedRowId: PropTypes.number,
  setExpandedRowId: PropTypes.func.isRequired,
  permission: PropTypes.bool.isRequired,
  currencyCell: PropTypes.func.isRequired,
  rowData: PropTypes.array,
  colValue: PropTypes.number,
  setColValue: PropTypes.func.isRequired,
  setIsAssigned: PropTypes.func.isRequired,
  status: PropTypes.bool.isRequired,
  handleStatus: PropTypes.func.isRequired,
  activeDailySheet: PropTypes.object.isRequired,
  handleDailyTimeSheet: PropTypes.func.isRequired,
  handleDailyPayroll: PropTypes.func.isRequired,
  handleDailyCertifiedPayroll: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  searchString: PropTypes.string.isRequired,
  setSearchString: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPagination: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  setSortModel: PropTypes.func.isRequired,
  tokenData: PropTypes.object.isRequired
};

export default ProjectCertifiedPayrollTableRow;
