export const EmployeesExcelData = [
  "Project Job Title",
  "Start Date",
  "End Date",
  "Employment Term Type",
  "PerDiem",
  "Wage Rate",
  "Fringe",
  "First Name",
  "Middle Name",
  "Last Name",
  "Cell Phone",
  "Office Phone",
  "Whatsapp Number",
  "Email",
  "Address",
  "Address2",
  "City",
  "State",
  "Zip",
  "Date Of Birth",
  "Last Four Digit SSN",
  "Apprentice Job",
  "Total Hourly Comp",
  "Internal Id"
];

const timeSheetMultiRowData = [
  "First Name",
  "Last Name",
  "Email",
  "Worked Date",
  "Total Standard Hours",
  "Total Over Time Hours",
  "Standard Pay Rate",
  "Over Time Pay Rate",
  "Per Diem",
  "Payroll Number",
  "Position",
  "Employee Internal Id",
  "Week Ending Date"
];

export const timeSheetMultiRowFields = timeSheetMultiRowData?.map(fieldName => ({
  label: fieldName,
  key: fieldName,
  fieldType: {
    type: "input"
  }
}));

export const timeSheetSingleRowData = [
  "First Name",
  "Last Name",
  "Email",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
  "Total Standard Hours",
  "Total Over Time Hours",
  "Standard Pay Rate",
  "Over Time Pay Rate",
  "Per Diem",
  "Payroll Number",
  "Position",
  "Employee Internal Id",
  "Week Ending Date"
];

export const timeSheetSingleRowFields = timeSheetSingleRowData?.map(fieldName => ({
  label: fieldName,
  key: fieldName,
  fieldType: {
    type: "input"
  }
}));

const payrollFieldsName = [
  "First Name",
  "Last Name",
  "Position",
  "Week Ending Date",
  "Regular Pay Rate",
  "Regular Hours",
  "Regular Total",
  "Over Time Pay Rate",
  "Over Time Hours",
  "Over Time Total",
  "Per Diem",
  "Total Hours",
  "Total Pay",
  "Gross Amount Earned",
  "FICA",
  "Other",
  "Total Deductions",
  "Net Wage Paid For Week",
  "Employee Internal Id",
  "STATE WH TAX",
  "FED WH TAX",
  "MCARE",
  "Other Deduction Explanation",
  "Exception",
  "Explanation"
];

export const payrollFields = payrollFieldsName?.map(fieldName => ({
  label: fieldName,
  key: fieldName,
  fieldType: {
    type: "input"
  }
}));

export const ProjectInfoConfig = {
  totalWorkforceHours: {
    label: "What % of Total Workforce Hours will be worked by Apprentices?*",
    perKey: "PercentageApprenticeWork",
    defaultValue: null
  },
  moreThanFourEmployees: {
    label: "As a Labor Provider will you be employing more than 4 employees on this Project?*",
    perKey: "MoreThanFourEmployees",
    defaultValue: null
  },
  typicalshiftdays: {
    label: "What will be the typical shift and days worked by the workforce employed on this project?",
    defaultValue: [],
    perKey: "TypicalShiftDay"
  },
  shiftStartTime: {
    label: "Start Time",
    defaultValue: null,
    perKey: "ShiftStartTime"
  },
  shiftEndTime: {
    label: "End Time",
    defaultValue: null,
    perKey: "ShiftEndTime"
  },
  employeeCount: {
    label: "How many employees will you be hiring for this project?",
    type: "number",
    perKey: "EmployeeCount",
    defaultValue: null
  },
  certifiedPayrollEmailAddress: {
    label: "Owner Certified Payroll Email Address",
    perKey: "OwnerCertifiedPayrollSignatoryEmail",
    defaultValue: null
  },
  daysofWeekBegin: {
    label: "On what day of the week does your pay period begin?",
    perKey: "PayPeriodBegin",
    defaultValue: null
  },
  daysofWeekEnd: {
    label: "On what day of the week does your pay period end?",
    perKey: "PayPeriodEnd",
    defaultValue: null
  },
  workforceStartDate: {
    label: "Workforce Start Date",
    defaultValue: null,
    perKey: "WorkforceStartDate",
    rules: {
      validate: (value, formValues) => {
        if (!value) return "true";
        if (value.isValid && !value.isValid()) return "Enter valid date";
        if (formValues.workForceEndDate && formValues.workForceEndDate < value) {
          return "Workforce Start Date should be less than Workforce End Date";
        }
      }
    }
  },
  workForceEndDate: {
    label: "Workforce End Date",
    defaultValue: null,
    perKey: "WorkforceEndDate",
    rules: {
      validate: (value, formValues) => {
        if (!value) return true;
        if (value.isValid && !value.isValid()) return "Enter valid date";
        if (formValues.workforceStartDate && formValues.workforceStartDate > value) {
          return "Workforce End Date should be greater than Workforce Start Date";
        }
      }
    }
  },
  useProscoreMobileApp: {
    label: "Will you be using the ProScore Mobile App for Timekeeping Management?",
    withInfoIcon: false,
    perKey: "UseProscoreMobileApp",
    defaultValue: false
  },
  timeKeepingSystem: {
    label: "What Time Keeping System will be capturing worker time on a daily basis?",
    perKey: "TimeKeepingSystem",
    defaultValue: null
  },
  provideTimeKeepingOn: {
    label: "Will you be providing timekeeping data on a daily or weekly basis?",
    perKey: "ProvideTimeKeepingOn",
    defaultValue: null
  },
  payrollOn: {
    label: "Are you running payroll on a weekly, biweekly, or monthly basis?",
    perKey: "PayrollOn",
    defaultValue: null
  },
  uploadDailyTimeSheet: {
    label: "Daily time sheet",
    perKey: "UploadDailyTimeSheet",
    defaultValue: null
  },

  isAllowMoreThanOneSiteLocation: {
    label: "Does the Timekeeping System allow the worker to enter more than one site location (Apprentice)?",
    perKey: "IsAllowMoreThanOneSiteLocation",
    defaultValue: false
  },
  isAllowWorkerToAddMoreThanOneOccupation: {
    withInfoIcon: true,
    label:
      "Does the Timekeeping System(if not using the ProScore mobile app) allow the worker to enter more than one occupation during work period (shift)?",
    infoMessage:
      "To ensure compliance under IRA framework the employer has to ensure the employee is getting paid the proper Prevailing Wage given the job or duties performed throughout the entire shift. For example: If an Electrician or Installer doesn't have any materials to install during the shift and they dig trenches or take out the trash, they would be classified as a different job title and a new rate potentially applies.",
    perKey: "IsAllowWorkerToAddMoreThanOneOccupation",
    defaultValue: false
  },
  isCertifiedPayrollAutoGenarate: {
    label: "Auto Generate Certified Payroll Reports",
    perKey: "AutoGenerateCertifiedPayrollReports",
    type: "checkbox",
    defaultValue: true
  },
  adaptiveElearning: {
    label: "Adaptive RTI",
    withInfoIcon: true,
    perKey: "IsAdaptive",
    infoMessage: "Scenario: Use a safety trailer during bad weather for continuous RTI. Benefit: Seamless training despite weather challenges.",
    defaultValue: false
  },
  flexibleElearning: {
    label: "Flexible RTI Options",
    withInfoIcon: true,
    perKey: "IsFlexible",
    defaultValue: false,
    infoMessage: "Scenario: Apprentices can choose bulk RTI during downtime. Advantage: Flexible scheduling, no rigid daily/weekly structures."
  },
  accelaratedElearning: {
    label: "Accelerated RTI",
    withInfoIcon: true,
    perKey: "IsAccelerated",
    defaultValue: false,
    infoMessage:
      "Scenario: Complete Year 1 curriculum in 3 weeks through intensive RTI. Outcome: Accelerated progress towards journeyworker certification."
  },
  courseContentDelivery: {
    label: "Course Content Delivery",
    perKey: "CourseContentDelivery",
    defaultValue: null
  },
  apprenticeHoursTracking: {
    label: "Apprentice Hours Tracking",
    perKey: "ApprenticeHoursTracking",
    defaultValue: null
  },
  adminAssignDailyApprenticeMentor: {
    label: "Admin will assign Daily Mentor:Apprentice",
    withInfoIcon: false,
    perKey: "adminAssignDailyApprenticeMentor",
    defaultValue: false
  },
  adminAssignDailyWPS: {
    label: "Admin will assign Daily WPS",
    withInfoIcon: false,
    perKey: "adminAssignDailyWPS",
    defaultValue: false
  },

  learningHoursPaid: {
    label: "Are RTI hours paid?",
    perKey: "LearningHoursPaid",
    defaultValue: null
  },
  relatedInstructionProvidedHours: {
    label: "Hours When Related Instruction Is Provided",
    perKey: "RelatedInstructionProvidedHours",
    defaultValue: null
  },
  preferredRTIDays: {
    label: "Preferred days and time for RTI/class time",
    perKey: "PreferredRTIDays",
    defaultValue: null
  },
  maxPaidHoursPerDay: {
    label: "Recommended Daily RTI (Minutes)",
    perKey: "MaxPaidHoursPerDay",
    defaultValue: null
  },
  preferredDaysEndTime: {
    label: "End Time",
    defaultValue: null,
    perKey: "EndTime"
  },
  preferredDayStartTime: {
    label: "Start Time",
    defaultValue: null,
    perKey: "StartTime"
  },
  rtiWeeklyExpectationHours: {
    label: "RTI Weekly Expectation Hours",
    perKey: "RTIWeeklyExpectationHours",
    defaultValue: null
  },
  rtiWeeklyExpectationMins: {
    label: "RTI Weekly Expectation Minutes",
    perKey: "RTIWeeklyExpectationMins",
    defaultValue: null
  }
};

const validateInput = value => {
  if (!value) {
    return "Project Name is required!";
  }

  if (typeof value === "string") {
    return;
  }
  return "Please select a valid option";
};

export const ProjectProfileConfig = {
  projectName: {
    perKey: "ProjectName",
    label: "Project Name",
    rules: { validate: validateInput }
  },
  address1: {
    label: "Address 1",
    perKey: "Address1"
  },
  address2: {
    label: "Address 2",
    perKey: "Address2"
  },
  city: {
    label: "City",
    perKey: "CIty"
  },
  state: {
    label: "State/Province",
    perKey: "State"
  },
  internalProjectId: {
    label: "Project ID No",
    perKey: "InternalProjectId"
  },
  zipcode: {
    label: "Zip",
    perKey: "ZipCode",
    defaultValue: null
  },
  primaryCounties: {
    label: "Primary County",
    perKey: "PrimaryCounties"
  },
  country: {
    label: "Country",
    perKey: "Country"
  },
  nercRegion: {
    label: "NERC Region",
    perKey: "NERCRegion"
  },
  isoTerritory: {
    label: "ISO Territory",
    perKey: "ISOTerritory"
  },
  lat: {
    label: "Latitude Coordinate (GPS Location)",
    perKey: "Lat"
  },
  long: {
    label: "Longitude Coordinate (GPS Location)",
    perKey: "Long"
  },
  projectOwner: {
    label: "Who is the Project Owner (Tax Payer/Incentive(s))?",
    defaultValue: null,
    perKey: "ProjectOwner"
  },
  projectDeveloper: {
    label: "Who is the  Project Developer (if different than Owner)?",
    defaultValue: null,
    perKey: "ProjectDeveloper"
  },
  projectEPC: {
    label: "Who is the  Project EPC (if different than Owner & Developer)?",
    defaultValue: null,
    perKey: "ProjectEPS"
  },
  workId: {
    label: "Work Category",
    perKey: "WorkType",
    defaultValue: null
  },
  mobileAppTimeKeepingManagement_QC: {
    label: "QR Code"
  },
  mobileAppTimeKeepingManagement_MO: {
    label: "Multiple Occupation",
    perKey: "ZipCode"
  },
  mobileAppTimeKeepingManagement_FC: {
    label: "Facial Recognisation",
    perKey: "ZipCode"
  },
  projectKickoff: {
    label: "Project Kickoff (Season)?",
    perKey: "KickoffSeason"
  },
  actualConstructionStartDate: {
    label: "Actual Construction Start Date",
    defaultValue: null,
    perKey: "ActualConstructionStartDate"
  },
  projectType: {
    label: "Project Type",
    perKey: "ProjectType"
  },
  projectStatus: {
    label: "Project Status",
    perKey: "ProjectStatus"
  },
  eiaId: {
    label: "EIA ID",
    type: "number",
    defaultValue: null,
    perKey: "EIAId"
  },
  dockets: {
    label: "Dockets",
    perKey: "Dockets"
  },
  batteryType: {
    label: "Battery Type",
    perKey: "BatteryType"
  },
  communitySolar: {
    label: "Community Solar?",
    defaultValue: null,
    perKey: "CommunitySolar"
  },
  coLocatedProject: {
    label: "Co Located Project?",
    perKey: "CoLocatedProject"
  },
  projectCapacityMW: {
    label: "Project Capacity MW",
    type: "number",
    defaultValue: null,
    perKey: "ProjectCapacityMW"
  },
  projectClassification: {
    label: "Project Classification",
    perKey: "ProjectClassification"
  },
  powerPurchaser: {
    label: "Power Purchaser",
    perKey: "PowerPurchaser"
  },
  powerPurchaseAgreementCapacityMW: {
    label: "Power Purchase Agreement Capacity MW",
    perKey: "PowerPurchaseAgreementCapacityMW"
  },
  powerPurchaseAgreementYears: {
    label: "Power Purchase Agreement Years",
    perKey: "PowerPurchaseAgreementYears"
  },
  supplier: {
    label: "Supplier",
    perKey: "Supplier"
  },

  unitType: {
    label: "Unit Type",
    perKey: "UnitType"
  },
  unitSupplied: {
    label: "Unit Supplied",
    perKey: "UnitSupplied"
  },
  firstPowerDate: {
    label: "First Power Date",
    defaultValue: null,
    perKey: "FirstPowerDate"
  },
  firstPowerYear: {
    label: "First Power Year",
    perKey: "FirstPowerYear",
    type: "number",
    defaultValue: null
  },
  projectLastUpdatedDate: {
    label: "Project Last Updated Date",
    perKey: "ProjectLastUpdatedDate",
    defaultValue: null
  },
  projectAcresSize: {
    label: "How many acres is the project?",
    perKey: "ProjectAcres"
  },
  constructionSiteCounties: {
    label: "How many counties fall within the construction site?",
    perKey: "CountiesInConstructionSite"
  },
  totalConstructionDays: {
    label: "Total Construction Days (Est.)?",
    perKey: "TotalConstructionDays",
    type: "number",
    defaultValue: null
  },
  targetConstructionStartDate: {
    label: "Target Construction Start Date",
    perKey: "TargetConstructionStartDate",
    defaultValue: null,
    rules: {
      validate: (value, formValues) => {
        if (!value) return true;
        if (value.isValid && !value.isValid()) return "Enter valid date";
        if (formValues.targetCODDate && formValues.targetCODDate < value) {
          return "Target Construction Start Date should be less than Target COD Date";
        }
      }
    }
  },
  projectCapacityAC: {
    label: "Project Capacity AC (MW)?",
    perKey: "ACCapacity",
    type: "number",
    defaultValue: null
  },
  projectCapacityDC: {
    label: "Project Capacity DC (MW)?",
    perKey: "DCCapacity",
    type: "number",
    defaultValue: null
  },
  targetCODDate: {
    label: "Target COD Date?",
    perKey: "TargetCODDate",
    defaultValue: null,
    rules: {
      validate: (value, formValues) => {
        if (!value) return true;
        if (value.isValid && !value.isValid()) return "Enter valid date";
        if (formValues.targetConstructionStartDate && formValues.targetConstructionStartDate > value) {
          return "Target COD Date should be greater than Target Construction Start Date";
        }
      }
    }
  },
  moduleSelected: {
    label: "Module Selected",
    perKey: "ModuleSelected"
  },
  moduleType: {
    label: "Module Type",
    perKey: "ModuleType"
  },
  modulQuantity: {
    label: "Module Quantity",
    perKey: "ModulQuantity",
    type: "number",
    defaultValue: null
  },
  stringSizes: {
    label: "String Sizes",
    perKey: "StringSizes"
  },
  structureSelected: {
    label: "Structure Selected",
    perKey: "StructureSelected"
  },
  structureManufacturer: {
    label: "Structure Manufacturer",
    perKey: "StructureManufacturer"
  },
  rowCount: {
    label: "Table or Row Count",
    perKey: "TableOrRowCount"
  },
  pileAmount: {
    label: "Pile Amount",
    perKey: "PileAmount"
  },
  pileType: {
    label: "Pile Type",
    perKey: "PileType"
  },
  pileEmbedmentDepth: {
    label: "Pile Embedment Depth",
    perKey: "PileEmbedmentDepth",
    type: "number",
    defaultValue: null
  },
  blowMinCountRange: {
    label: "Blow Min Count Range",
    perKey: "BlowMinCountRange",
    type: "number",
    defaultValue: null
  },
  blowMaxCountRange: {
    label: "Blow Max Count Range",
    perKey: "BlowMaxCountRange",
    type: "number",
    defaultValue: null
  },
  inverterSelected: {
    label: "Inverter Selected",
    perKey: "InverterSelected"
  },
  inverterType: {
    label: "Inverter Type",
    perKey: "InverterType"
  },
  inverterQuantity: {
    label: "Inverter Quantity",
    perKey: "InverterQuantity",
    type: "number",
    defaultValue: null
  },
  DCRatio: {
    label: "DC Ratio",
    perKey: "DCRatio",
    type: "number",
    defaultValue: null
  },
  ACRatio: {
    label: "AC Ratio",
    perKey: "ACRatio",
    type: "number",
    defaultValue: null
  },

  materialProcurement: {
    label: "Material Procurement",
    perKey: "MaterialProcurement"
  },

  interconnectingUtility: {
    label: "Interconnecting Utility",
    perKey: "InterconnectingUtility"
  },
  interconnectionApproval: {
    label: "Interconnection Approval",
    perKey: "InterconnectionApproval"
  },
  interconnectionTiming: {
    label: "Interconnection Timing",
    perKey: "InterconnectionTiming"
  },
  interconnectionVoltage: {
    label: "Interconnection Voltage",
    perKey: "InterconnectionVoltage",
    type: "number",
    defaultValue: null
  },
  interconnectPoint: {
    label: "Interconnect Point / Substation",
    perKey: "InterconnectPoint"
  },
  interconnectDistance: {
    label: "Interconnect Distance",
    perKey: "InterconnectDistance"
  },
  ROWRequired: {
    label: "ROW Required?",
    perKey: "IsRowRequired",
    defaultValue: null
  },
  interconnectQueueStatus: {
    label: "Status of Interconnect Queue",
    perKey: "InterconnectQueueStatus"
  },
  substationCongestion: {
    label: "Substation Congestion/ Capacity",
    perKey: "SubstationCongestion"
  },
  interconnectionUtilityProcess: {
    label: "Utility Interconnection Process",
    perKey: "InterconnectionUtilityProcess"
  },
  interconnectNetworkUpgrades: {
    label: "Existing Interconnect Network Upgrades",
    perKey: "InterconnectNetworkUpgrades"
  },
  dataConnectionAvailability: {
    label: "Data Connection Hardline Availability",
    perKey: "DataConnectionAvailability"
  },

  permittingAuthority: {
    label: "Permitting Authority",
    perKey: "PermittingAuthority"
  },
  heightLimit: {
    label: "Height Limit",
    type: "number",
    defaultValue: null,
    perKey: "HeightLimit"
  },
  projectDocumentType: {
    label: "Project Document Type",
    perKey: "ProjectDocumentType",
    defaultValue: null
  },
  projectDocumentFile: {
    label: "Document",
    type: "file",
    perKey: "ProjectDocumentPath"
  }
};

export const projectData = [
  {
    id: 1,
    stateName: "Wichita",
    countyName: "Kansas",
    cityName: "Wichita"
  },
  {
    id: 2,
    stateName: "Wichita",
    countyName: "Kansas",
    cityName: "Wichita"
  },
  {
    id: 3,
    stateName: "Wichita",
    countyName: "Kansas",
    cityName: "Wichita"
  },
  {
    id: 4,
    stateName: "Wichita",
    countyName: "Kansas",
    cityName: "Wichita"
  },
  {
    id: 5,
    stateName: "Wichita",
    countyName: "Kansas",
    cityName: "Wichita"
  }
];

export const ProjectSchedulingConfig = {
  deliverySchedule: {
    label: "What is your Equipment Delivery Schedule?",
    perKey: "EquipmentDeliverySchedule"
  },
  attachmentFile: {
    label: "File",
    perKey: "EquipmentDeliveryScheduleAttachmentPath",
    type: "file",
    defaultValue: null
  }
};

export const ProjectPartnerConfig = {
  client: {
    label: "Partner",
    perKey: "Partner",
    rules: { required: "Partner is required!" }
  },
  clientContact: {
    label: "Partner Contact",
    perKey: "PartnerContact",
    rules: { required: "Partner Contact is required!" }
  },
  contractType: {
    label: "Partner Type",
    perKey: "PartnerType",
    rules: { required: "Partner Type is required!" }
  }
};

export const profileFormNames = [
  "Project Name and Address",
  "Project Ownership(Partners)",
  "Project Partners",
  "Project Details",
  "Project Details(Required Info)",
  "Project Site : Module Info",
  "Project Site : Structure Info",
  "Project Site : Inverter Detail",
  "Project Site : Material & Equipment Scheduling Info",
  "Project Site : Interconnection Info",
  "Project Site : Permitting & Entitlement Detail"
];

export const projectJobFormConfing = {
  isExternalJobTitle: {
    label: "External Job Title",
    perKey: "isExternalJobTitle"
  },
  isOperator: {
    label: "Will this position operate power equipment?",
    perKey: "isOperator"
  },
  typeOfOperator: {
    label: "What Family of Jobs does this position fit within?",
    perKey: "typeOfOperator"
  },
  typeOfEquipment: {
    label: "What type of equipment will be utilized by this job position?",
    perKey: "typeOfEquipment"
  },
  typeOfJob: {
    label: "What type of job will be utilized by this job position?",
    perKey: "typeOfJob"
  },
  isResponsibleForUpkeep: {
    label: "Will this person be resposible for the upkeep power equipment?",
    perKey: "isResponsibleForUpkeep"
  },
  jobTitleId: {
    label: "Job Title",
    perKey: "JobTitle",
    rules: { required: "Job Title is required!" }
  },
  externalJobTitle: {
    label: "External Job Title",
    perKey: "ExternalJobTitle",
    rules: { required: "External Job Title is required!" }
  },
  numberOfPositions: {
    label: "Number Of Position",
    perKey: "NumberofPositions",
    rules: { required: "Number Of Position is required!" }
  },
  jobDescription: {
    label: "Job Description",
    perKey: "JobDescription",
    fieldProps: { multiline: true, rows: 3 }
  },
  payTypePosition: {
    label: "Pay Type",
    perKey: "PayType",
    withInfoIcon: true,
    rules: { required: "Pay Type is required!" },
    infoMessage:
      "Consider creating separate job entries for roles that involve both hourly and salary-based pay structures. This ensures accurate tracking and simplifies payroll management."
  },
  perDiem: {
    label: "Per Diem",
    perKey: "PerDiem"
  },
  IsCraftTradeLaborPosition: {
    label: "Is this position a Craft / Trade Labor Position?",
    perKey: "IsThisPositionACraft/TradeLaborPosition",
    defaultValues: true
  },
  IsRole: {
    label: "Is this an Administrative / Support / or Leadership role?",
    perKey: "IsThisAnAdministrative/Support/OrLeadershiprole"
  },
  marketRate: {
    label: "Market Rate",
    perKey: "MarketRate"
  },
  pwAiConfidenceMatchScore: {
    label: "PW AI % Confidence Score",
    perKey: "TitleMatch"
  },
  wageRate: {
    label: "PW Wage Rate",
    perKey: "PWWageRete"
  },
  fringe: {
    label: "PW Fringe",
    perKey: "PWFringe"
  },
  salaryAmount: {
    label: "Salary Amount",
    perKey: "SalaryAmount"
  },
  pwTotalHourlyComp: {
    label: "PW Total Hourly Comp",
    perKey: "PWTotalHourlyComp"
  },
  samFileName: {
    label: "PW File Name",
    perKey: "samFileName"
  },
  samFilePath: {
    label: "PW File Name Path",
    perKey: "samFilePath"
  },
  generalDecisionNumber: {
    label: "PW General Decision Number",
    perKey: "generalDecisionNumber"
  },
  actualWageRate: {
    label: "Actual Wage Rate",
    perKey: "WageRate"
  },
  actualFringe: {
    label: "Actual Fringe",
    perKey: "Fringe"
  },
  actualTotalHourlyComp: {
    label: "Actual Total Hourly Comp",
    perKey: "ActualTotalHourlyComp"
  },
  footnoteSickDays: {
    label: "PW Sick Leave",
    perKey: "footnoteSickDays",
    fieldProps: { multiline: true, rows: 5, disabled: true }
  },
  footnoteVacationDays: {
    label: "PW Vacation",
    perKey: "footnoteVacationDays",
    fieldProps: { multiline: true, rows: 5, disabled: true }
  },
  footnoteHolidays: {
    label: "PW Holiday",
    perKey: "footnoteHolidays",
    fieldProps: { multiline: true, rows: 5, disabled: true }
  },
  footnoteOthers: {
    label: "PW Other Footnotes",
    perKey: "footnoteOthers",
    fieldProps: { multiline: true, rows: 5, disabled: true }
  },
  isApprenticeable: {
    label: "Apprenticeship Eligible",
    perKey: "IsApprenticeable"
  },
  assignRAPInfoGuid: {
    label: "Rap",
    perKey: "Rap"
  },
  assignRAPWageScaleGuid: {
    label: "AWS",
    perKey: "AWS"
  },
  occupationTitle: {
    label: "Occupation Title",
    perKey: "OccupationTitle"
  },
  onetCode: {
    label: "ONET Code",
    perKey: "OnetCode"
  }
};

export const projectEmployeeFormConfing = {
  isApprentice: {
    label: "Apprentice",
    perKey: "Apprentice"
  },
  isMentor: {
    label: "Mentor"
    // perKey: "Mentor"
  },
  projectJobTitleId: {
    label: "Job Title",
    perKey: "JobTitle",
    rules: { required: "Job Title is required!" }
  },
  employeeId: {
    label: "Employee",
    perKey: "Employee",
    rules: { required: "Employee is required!" }
  },
  mentorId: {
    label: "Mentor",
    perKey: "Mentor"
  },
  startDate: {
    label: "Start Date",
    rules: { required: "Start Date is required!" },
    defaultValue: null,
    perKey: "StartDate"
  },
  endDate: {
    label: "End Date",
    defaultValue: null,
    perKey: "EndDate"
  },
  employmentTermTypeId: {
    label: "Empployee Term Type",
    perKey: "EmployeeTermType"
  },
  perDiem: {
    label: "PerDiem",
    perKey: "PerDiem"
  },
  rate: {
    label: "Hourly Wage Rate",
    perKey: "HourlyRate"
  },
  fringe: {
    label: "Fringe",
    perKey: "Fringe"
  },
  actualHourlyRate: {
    label: "Actual Hourly Rate",
    perKey: "ActualHourlyRate"
  },
  isActive: {
    label: "Is Active",
    type: "checkbox",
    defaultValue: false
  }
};
