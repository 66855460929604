import React, { useCallback, useEffect } from "react";
import Layout from "../../../Components/Layout";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import ProGrid from "../../../Components/ProGrid/v2";
import { useNavigate } from "react-router-dom";
import { STAUTES } from "../../../../utils/constant";
import { DELETE_LEAD_SUBSOURCE, GET_ALL_LEAD_SUBSOURCE } from "../../../../utils/services/apiPath";
import { deleteApi, postApi } from "../../../../utils/services";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import usePageState from "../../../../utils/customHooks/usePageState";
import useColumn from "../../../../utils/customHooks/useColumns";

const getRedirectUrl = params => {
  return `/settings/lead-subsource-type/${params.id}`;
};
const LeadSourceList = () => {
  const navigation = useNavigate();
  const {
    currentPage,
    pageSize,
    data: leadSubSourceType,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const columns = useColumn(
    [
      {
        field: "leadSubSourceType",
        headerName: "Lead SubSource Type",
        renderCell: params => <NameCell params={params} getRedirectUrl={getRedirectUrl} />
      },
      {
        field: "leadSourceType",
        headerName: "Lead Source Type"
      },
      {
        field: "id",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell params={params} onDelete={onDelete} />
      }
    ],
    true
  );

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_LEAD_SUBSOURCE, { data: [{ leadSubSourceTypeId: id }] });
    if (error) return errorToast(error);
    getleadSubSource();
    successToast("Lead Sub Source Type deleted successfully.");
  };

  const addButtonAction = () => {
    navigation("/settings/lead-subsource-type/add");
  };

  const getleadSubSource = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "leadSourceType",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_LEAD_SUBSOURCE, payload);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    getleadSubSource();
  }, [getleadSubSource]);

  return (
    <Layout canView={true}>
      <ProGrid
        title="Lead Sub Source Type"
        columns={columns}
        loading={status === STAUTES.LOADING}
        rows={leadSubSourceType}
        searchMode={!!searchString}
        searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
        addButtonLabel="+ Add Lead Sub Source Type"
        addButtonAction={addButtonAction}
        hideAddButton={false}
        hideExport={false}
        options={{
          getRowId: row => row.leadSubSourceTypeId,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          sortingMode: "server",
          onSortModelChange: setSortModel
        }}
      />
    </Layout>
  );
};

export default LeadSourceList;
