import React, { useEffect, useRef, useState } from "react";
import { DEFAULT_SIZE } from "../../../utils/constant";
import ReadOnlyInput from "./ReadOnlyInput";
import InputWrapper from "./InputWrapper";
import { Controller } from "react-hook-form";
import { Autocomplete, FormHelperText, TextField } from "@mui/material";
import ProTextInput from "./TextField";
import { FormControl } from "@mui/base";
import PropTypes from "prop-types";

export default function AutoCompleteWithInput({
  name,
  mode,
  label,
  rules,
  onBlur,
  control,
  options = [],
  isAddMode,
  handleSelect,
  renderTextOnly,
  size = DEFAULT_SIZE,
  fetchOptions,
  ...rest
}) {
  const [searchString, setSearchString] = useState("");
  const debounceTimeout = useRef(null);

  useEffect(() => {
    if (searchString.trim()) {
      if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
      debounceTimeout.current = setTimeout(() => {
        fetchOptions?.(searchString);
      }, 500);
    }

    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, [searchString, fetchOptions]);

  const { canView = false, canAdd = false, canEdit = false } = rest.permission || {};
  if (!canView || (isAddMode && !canAdd)) return null;
  const editModeAllowed = isAddMode ? canAdd : canEdit;
  if (mode === "read" || (mode === "edit" && !editModeAllowed)) return <ReadOnlyInput size={size} name={name} label={label} {...rest} />;
  if (renderTextOnly) return <ProTextInput name={name} label={label} rules={rules} control={control} size={size} {...rest} />;
  return (
    <InputWrapper size={size}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl className="proInputWithMessage" fullWidth>
            <Autocomplete
              freeSolo
              fullWidth
              disableClearable
              value={value ?? ""}
              options={options}
              onBlur={() => onBlur?.(searchString)}
              onChange={(...parmas) => {
                onChange(...parmas);
                handleSelect?.(...parmas);
              }}
              error={!!error}
              onInputChange={event => event && setSearchString(event.target.value || "")}
              renderInput={params => (
                <TextField
                  {...params}
                  label={label}
                  variant="outlined"
                  placeholder={label}
                  InputProps={{ ...params.InputProps, type: "search" }}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            <FormHelperText error={!!error}>{error?.message}</FormHelperText>
          </FormControl>
        )}
      />
    </InputWrapper>
  );
}

AutoCompleteWithInput.propTypes = {
  name: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["read", "edit"]).isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object.isRequired,
  onBlur: PropTypes.func,
  control: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  isAddMode: PropTypes.bool,
  handleSelect: PropTypes.func,
  renderTextOnly: PropTypes.bool,
  size: PropTypes.string,
  fetchOptions: PropTypes.func,
  permission: PropTypes.shape({
    canView: PropTypes.bool,
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool
  })
};
