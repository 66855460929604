import * as React from "react";
import "./ReportsDetails.scss";
import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, CircularProgress } from "@mui/material";
import { useTheme } from "../../../ContextStore/ThemeContext";
import PropTypes from 'prop-types';

const OverAllSummaryGrid = ({ workerDetailSummary, loading }) => {
  const { theme } = useTheme();
  const cellStyles = theme === "dark" ? { color: "#ffffff" } : { color: "#00050B" };
  const topDiv = {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start"
  };
  const relativeDiv = {
    position: "relative",
    display: "inline-block"
  };
  const spanStart = {
    color: "red",
    position: "absolute",
    top: "-0.2em",
    right: "-0.5em",
    fontSize: "1em"
  };

  return (
    <div className="report-details">
      {workerDetailSummary.length != 0 && (
        <Box>
          <Stack p={1} direction="row" justifyContent="space-between" flexWrap="wrap" alignItems="flex-start">
            <Typography fontWeight="700" fontSize={18} color={cellStyles}>
              Analytics
            </Typography>
          </Stack>
          <TableContainer sx={{ overflowY: "hidden" }}>
            {loading ? (
                <CircularProgress size="3rem" sx={{ display: "flex", margin: "0 auto", my: 5 }} />
            
            ) : (
              <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ position: "sticky", top: 0, zIndex: 1 }} className="table-head-row">
                    <TableCell align="center" sx={cellStyles}>
                      Actual Journey Hours
                    </TableCell>
                    <TableCell align="center" sx={cellStyles}>
                      Total Apprentice OTJ Hours
                    </TableCell>
                    <TableCell align="center" sx={cellStyles}>
                      Project Compliance Ratio (Actual)
                    </TableCell>
                    <TableCell align="center" sx={cellStyles}>
                      Projected Journey Hours
                    </TableCell>
                    <TableCell align="center" sx={cellStyles}>
                      Projected OTJ Hours
                    </TableCell>
                    <TableCell align="center" sx={cellStyles}>
                      Project Compliance Ratio (Projected)
                    </TableCell>
                    <TableCell align="center" sx={cellStyles}>
                      Upcoming RAP Schedule Wage Changes{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="table-row" sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell align="center" sx={cellStyles} component="th" scope="row">
                      {workerDetailSummary.actualJourneyHoursToDate}
                    </TableCell>
                    <TableCell align="center" sx={cellStyles} component="th" scope="row">
                      {workerDetailSummary.totalApprenticeOTJHoursToDate}
                    </TableCell>
                    <TableCell align="center" sx={cellStyles} component="th" scope="row">
                      {workerDetailSummary.projectComplianceRatioActual}
                    </TableCell>
                    <TableCell align="center" sx={cellStyles} component="th" scope="row">
                      {workerDetailSummary?.isCODDatePassed ? (
                        <div style={topDiv}>
                          <div style={relativeDiv}>
                            <span>N/A</span>
                            <span style={spanStart}>*</span>
                          </div>
                        </div>
                      ) : (
                        workerDetailSummary.projectedJourneyHoursByEndOfProject
                      )}
                    </TableCell>
                    <TableCell align="center" sx={cellStyles} component="th" scope="row">
                      {workerDetailSummary?.isCODDatePassed ? (
                        <div style={topDiv}>
                          <div style={relativeDiv}>
                            <span>N/A</span>
                            <span style={spanStart}>*</span>
                          </div>
                        </div>
                      ) : (
                        workerDetailSummary.projectedApprenticeOTJHoursByEndOfProject
                      )}
                    </TableCell>
                    <TableCell align="center" sx={cellStyles} component="th" scope="row">
                      {workerDetailSummary?.isCODDatePassed ? (
                        <div style={topDiv}>
                          <div style={relativeDiv}>
                            <span>N/A</span>
                            <span style={spanStart}>*</span>
                          </div>
                        </div>
                      ) : (
                        workerDetailSummary.projectComplianceRatioProjected
                      )}
                    </TableCell>
                    <TableCell align="center" sx={cellStyles} component="th" scope="row">
                      {workerDetailSummary.upcomingRAPScheduleWageChanges}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
          </TableContainer>
          {workerDetailSummary?.isCODDatePassed && (
            <Typography sx={{ color: "red", pt: 1 }}>
              * The project End date is not defined or has already passed, thus computation of projected values is not possible{" "}
            </Typography>
          )}
        </Box>
      )}
    </div>
  );
};

OverAllSummaryGrid.propTypes = {
  workerDetailSummary: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default OverAllSummaryGrid;
